<div class="card-blocked">
  <header>
    <app-header [step]="4"></app-header>
  </header>

  <div class="card-blocked__container">
    <div class="contents">
      <div class="contents__img">
        <img src="../../../../assets/images/sakura-sorry.svg" alt="oops">
      </div>
      <div class="contents__info">
        <h1>OOPS!</h1>
        <p>You have exceeded the maximum number of attempts for the card payment method.<br>
          Therefore, it is not possible to use credit/debit card payment at the moment.
          If you would like to unlock this payment method, please contact us by using the contact box below.<br>
           We will send a notification email once we unblock your payment.</p>
      </div>
    </div>
    <div class="request-box">
      <form (ngSubmit)="onSubmit()" [formGroup]="requestFG">
        <div class="request-box__form">
          <label for="text">REQUEST BOX</label>
          <textarea id="text" class="textarea"
          placeholder="Please write the problem that you are having with your card that might be causing the error."
          formControlName="request_box"
          ></textarea>
          <div class="alert" *ngIf="request_box.invalid && (request_box.touched || request_box.dirty)">
            <p *ngIf="request_box.hasError('required')" class="required">Required</p>
          </div>
        </div>
        <div class="request-box__submit-button">
          <button mat-button type="submit" class="button" >Submit</button>
        </div>
      </form>

    </div>
    <div class="link-step-2">
      <a class="link" (click)="goBackStep2()">
        Click here if you would like to change your payment method.
      </a>
    </div>
  </div>
</div>
