<div class="post__office">
  <form class="post__office__form" [formGroup]="postOfficeFG">
    <div class="form__item branch__name">
      <label class="label">post office branch name<app-required></app-required></label>
      <div class="form__name">
        <mat-form-field appearance="standard" class="form--field" #postOfficeBranchNameRef>
          <input matInput formControlName="post_office_branch_name" (focus)="postOfficeBranchNameChange($event)" required />
          <mat-error *ngIf="post_office_branch_name.hasError('required')">Required *</mat-error>
        </mat-form-field>
        <p class="hint__text"
          [ngClass]="{'pd-top': (post_office_branch_name.touched || post_office_branch_name.dirty) && post_office_branch_name.invalid}">
          Please check the operation hours of the post office brach.
          It is also possible to <a href="https://www.sakuramobile.jp/contact-us/" target="_blank"
            class="contact_us">contact our Support Team</a> for assistance.
        </p>
      </div>
    </div>

    <div class="form__item branch__address">
      <label class="label">post office branch address<app-required></app-required></label>
      <div class="form__name">
        <mat-form-field appearance="standard" class="form--field" #postOfficeAddressRef>
          <input matInput formControlName="post_office_branch_address" (focus)="postOfficeAddressChange($event)" required />
          <mat-error *ngIf="post_office_branch_address.hasError('required')">Required *</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form__item">
      <label class="label">preferred date of pick up<app-required></app-required></label>
      <div class="form__name">
        <mat-form-field appearance="standard" class="form--field" #postOfficePreferrDateRef (click)="picker1.open()">
          <input matInput placeholder="Select date" [matDatepicker]="picker1" [min]="minDate"
            formControlName="preffered_date_of_pick_up" required readonly/>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 (closed)="postOfficeDatePickerChange(picker1)"></mat-datepicker>
          <mat-error *ngIf="preffered_date_of_pick_up.hasError('required')">Required *</mat-error>
        </mat-form-field>
        <p class="hint__text"
         [ngClass]="{'pd-top': (preffered_date_of_pick_up.touched || preffered_date_of_pick_up.dirty) && preffered_date_of_pick_up.invalid}">
          We might not be able to meet your request delivery date if you live in top-end or bottom-end of Japan.
          We will send you another email to inform you the scheduled delivery date.
        </p>
      </div>
    </div>

    <div class="form__item">
      <label class="label">Estimated time of arrival</label>
      <div class="form__name">
        <mat-form-field appearance="standard">
          <mat-select disableRipple [placeholder]="'ETA to your pick up place'" formControlName="estimated_time_of_arrival"
          >
            <mat-option *ngFor="let time of estimateTimeArrival" [value]="time">{{time}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form__item recipient">
      <label class="label">RECIPIENT<app-required></app-required></label>
      <div class="form__name">
        <mat-form-field appearance="standard" class="form--field" #postOfficeRecipientRef>
          <input matInput formControlName="recipient" (focus)="postOfficeRecipientChange($event)" required />
          <mat-error *ngIf="recipient.hasError('required')">Required *</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form__item note">
      <label class="label"> note </label>
      <div class="form__name">
        <mat-form-field appearance="standard">
          <textarea matInput placeholder="Please use this box if you have any question regarding your pick up process."
            formControlName="note" maxlength="250"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
