<div class="modal">
  <div class="modal_container">
    <img src="../../../assets/images/Warning-icon.svg" alt="Warning-icon" class="images_warning"/>
    <h3 class="modal_label">Confirm your Full Name</h3>
      <p class="modal_label_title">Your Full Name should be exactly as shown on your official</p>
      <p class="modal_label_sab_title">ID documentation (including Middle Name if you have one).</p>
    <form class="modal_form_conatiner">
      <div class="modal_form" [formGroup]="form" [ngClass]="{'edit-mode': isEditMode}">
        <div class="modal_form_fullname">
        </div>
        <input class="modal_form_input" matInput [placeholder]=" data.fullName ? '' : 'First Middle Second'" 
        type="text" formControlName="fullName" required [readonly]="!isEditMode">
        <div class="modal_form_edit" *ngIf="!isEditMode" (click)="enableEdit()">
          <span><img src="../../../assets/images/pencil.svg" alt="pencil-icon" class="images_pencil"/>Edit</span>
        </div>
      </div>
    </form>

    <mat-error *ngIf="form.get('fullName')?.hasError('required')">Required*</mat-error>
    <button class="submit_button" type="submit" (click)="submitBasicInformation()">
      <span class="submit_button_text">
        CONFIRM
        <app-spinner class="submit_button_spinner" [loading]="loading$ |async"></app-spinner>
      </span>
    </button>
  </div>
</div>