import { AfterContentChecked, Component, ElementRef,
  EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Add_Remove_Css } from 'src/app/Utils/utils';


@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HotelComponent implements OnInit, AfterContentChecked {
  hotel_name: FormControl;
  hotel_address: FormControl;
  hotel_url: FormControl;
  hotel_check_in_date: FormControl;
  hotel_check_out_date: FormControl;
  preffered_date_of_pick_up: FormControl;
  contact_phone_number: FormControl;
  estimated_time_of_arrival: FormControl;
  recipient: FormControl;
  note: FormControl;
  hotelInformation: FormGroup;
  @Output() formValid = new EventEmitter<boolean>();
  @Input() minDate: Date;
  @ViewChild('hotelNameRef', {static: false, read: ElementRef}) hotelNameRef: ElementRef;
  @ViewChild('hotelAddressRef', {static: false, read: ElementRef}) hotelAddressRef: ElementRef;
  @ViewChild('checkInRef', {static: false, read: ElementRef}) checkInRef: ElementRef;
  @ViewChild('checkOutRef', {static: false, read: ElementRef}) checkOutRef: ElementRef;
  @ViewChild('recipientRef', {static: false, read: ElementRef}) recipientRef: ElementRef;
  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.createFormControl();
    this.createFormGroup();
  }
  hotelNameChange(event) {
    Add_Remove_Css('remove', this.hotelNameRef, this.renderer);
  }
  hotelAddressChange(event: any) {
    Add_Remove_Css('remove', this.hotelAddressRef, this.renderer);
  }
  checkInDatePickerCloseEvent(event: any) {
    Add_Remove_Css('remove', this.checkInRef, this.renderer);
  }
  checkOutDatePickerCloseEvent(event: any) {
    Add_Remove_Css('remove', this.checkOutRef, this.renderer);
  }
  recipientChange(event: any) {
    Add_Remove_Css('remove', this.recipientRef, this.renderer);
  }
  ngAfterContentChecked() {
    if (this.hotelInformation.valid) {
      this.formValid.emit(true);
    } else {
      this.formValid.emit(false);
    }
  }
  private createFormControl() {
    this.hotel_name = this.fb.control('', Validators.required);
    this.hotel_address = this.fb.control('', Validators.required);
    this.hotel_url = this.fb.control('');
    this.hotel_check_in_date = this.fb.control('', Validators.required);
    this.hotel_check_out_date = this.fb.control('', Validators.required);
    this.preffered_date_of_pick_up = this.fb.control('');
    this.recipient = this.fb.control('', Validators.required);
    this.note = this.fb.control('');
  }
  private createFormGroup() {
    this.hotelInformation = this.fb.group({
      hotel_name: this.hotel_name,
      hotel_address: this.hotel_address,
      hotel_url: this.hotel_url,
      hotel_check_in_date: this.hotel_check_in_date,
      hotel_check_out_date: this.hotel_check_out_date,
      preffered_date_of_pick_up: this.preffered_date_of_pick_up,
      recipient: this.recipient,
      note: this.note
    });
  }
}
