import { MatSnackBarConfig } from "@angular/material";
import { environment } from "../../environments/environment";

export declare type MySnackBarType = 
"success" |
"error" |
"form-fields-error" | 
"number-fields-error" | 
"required-fields-error" | 
"payment-fields-error";
export class Constants {
  public static SHOP_ID = "9100176413941";
  public static GMO_PAYMENT_GATEWAY =
    "https://static.mul-pay.jp/ext/js/token.js";
  public static API_VER = "/v1";
  public static API_ROOT = environment.apiRoot;
  public static GET_PLAN_INFORMATION =
    Constants.API_ROOT + Constants.API_VER + "/orders/plan_information";
  public static API_BASIC_INFORMATION =
    Constants.API_ROOT + Constants.API_VER + "/orders/new_order"; // POST
  public static API_GET_ACTIVE_PAYMENT_METHOD =
    Constants.API_ROOT + Constants.API_VER + "/payments/active_payment_method"; // GET
  public static API_GET_ORDER_INFORMATION =
    Constants.API_ROOT + Constants.API_VER + "/orders/order_information"; // GET
  public static API_UPLOAD_FILES =
    Constants.API_ROOT + Constants.API_VER + "/orders/upload_documents"; // POST
  public static API_COUPON_DISCOUNT =
    Constants.API_ROOT + Constants.API_VER + "/coupons/discount_information"; // GET
  public static API_COUPON_INFO =
    Constants.API_ROOT + Constants.API_VER + "/coupons"; // GET
  public static API_REFFERRAL_CODE =
    Constants.API_ROOT + Constants.API_VER + "/referral_codes"; // GET
  public static API_SUBMIT_ORDER =
    Constants.API_ROOT + Constants.API_VER + "/orders/submit"; // POST
  public static API_CREDIT_CARD_AVAILABLE =
    Constants.API_ROOT + Constants.API_VER + "/payments/card_availability"; // GET
  public static API_UNLOCK_CARD_REQUEST =
    Constants.API_ROOT + Constants.API_VER + "/payments/unlock_card_request"; // POST
  public static API_TOP_UP_TICKET_INFORMATION =
    Constants.API_ROOT + Constants.API_VER + "/top_up_ticket"; // GET
  public static defaultSnackBarConfig(type: MySnackBarType): MatSnackBarConfig {
    const myConfig = new MatSnackBarConfig();
    myConfig.horizontalPosition = "right";
    myConfig.verticalPosition = "top";
    switch (type) {
      case "success":
        myConfig.panelClass = "snackbar--success";
        break;
      case "error":
        myConfig.duration = 5000;
        myConfig.panelClass = "snackbar--error";

        break;
      case "form-fields-error":
        myConfig.horizontalPosition = "center";
        myConfig.duration = 10000;
        myConfig.panelClass = [
          "mat-snack-bar-err",
          "mat-simple-snackbar",
          "mat-simple-snackbar-action",
        ];
        break;

        case "required-fields-error":
          myConfig.horizontalPosition = "center";
          myConfig.duration = 10000;
          myConfig.panelClass = [
            "required-fields-error",
          ];
          break;
        case "payment-fields-error":
          myConfig.horizontalPosition = "center";
          myConfig.duration = 10000;
          myConfig.panelClass = [
            "payment-fields-error",
          ];
          break;
        case "number-fields-error":
          myConfig.horizontalPosition = "center";
          myConfig.duration = 10000;
          myConfig.panelClass = [
            "number-fields-error",
          ];
          break;
      default:
        return null;
    }
    return myConfig;
  }
}
