
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/myFormatDate';
import { LongOrderService } from 'src/app/services/long-order.service';
import { UploadDocumentComponent } from '../../shared/upload-document/upload-document.component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { SelectPayMethod } from 'src/app/types/select-paymethod';
import { Router } from '@angular/router';
import { Add_Remove_Css, ShowSnackBar } from 'src/app/Utils/utils';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-airport-content',
  templateUrl: './airport-content.component.html',
  styleUrls: ['./airport-content.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AirportContentComponent implements OnInit {
  moment = _rollupMoment || _moment;
  selectDate: Date;
  // isSubmitButton = false;
  otherAirportSelect: boolean;
  placeholder: string;
  // tslint:disable-next-line:variable-name
  other_airport: FormControl;
  // tslint:disable-next-line:variable-name
  airport_information: FormGroup;
  // tslint:disable-next-line:variable-name
  location_airport: FormControl;
  // tslint:disable-next-line:variable-name
  arriving_flight_number: FormControl;
  // tslint:disable-next-line:variable-name
  pick_up_date: FormControl;
  // tslint:disable-next-line:variable-name
  estimated_time_of_arrival: FormControl;
  note: FormControl;
  airportList = [
    'Haneda International Airport',
    'Narita International Airport 1',
    'Narita International Airport 2',
    'Kansai International Airport',
    'Chubu Centrair Internetional Airport (Post Office)',
    'New Chitose Internationl Airport (Post Office)',
    'Other: Please write your arriving airport',
  ];
  airportSubInformations = [
    {location: 'Haneda International Airport', opening_hours: 'Closed from 1 AM - 4 AM : The counter is open during all regular flight arrival times.'},
    {location: 'Narita International Airport 1', opening_hours: 'Open from 6:30 AM to one hour after the final international flight arrival : The counter is open during all regular flight arrival times.'},
    {location: 'Narita International Airport 2', opening_hours: 'Open from 6:30 AM to one hour after the final international flight arrival : The counter is open during all regular flight arrival times.'},
    {location: 'Kansai International Airport', opening_hours: 'Open from 6 AM to 11 PM : The counter is open during all regular flight arrival times.'},
    {location: 'Chubu Centrair Internetional Airport (Post Office)', opening_hours: 'Operating hours: 9:00 ~ 17:00 (Monday ~ Friday  *Weekdays only)'},
    {location: 'New Chitose Internationl Airport (Post Office)', opening_hours: 'Operating hours: 9:00 ~ 17:00 (Everyday)'},
    {location: 'Other: Please write your arriving airport', opening_hours: ''}
  ];
  estimateTimeArrival = [
    '~ 6:00',
    '6:00 ~ 7:00',
    '7:00 ~ 8:00',
    '8:00 ~ 9:00',
    '9:00 ~ 10:00',
    '10:00 ~ 11:00',
    '11:00 ~ 12:00',
    '12:00 ~ 13:00',
    '13:00 ~ 14:00',
    '14:00 ~ 15:00',
    '15:00 ~ 16:00',
    '16:00 ~ 17:00',
    '17:00 ~ 18:00',
    '18:00 ~ 19:00',
    '19:00 ~ 20:00',
    '20:00 ~ 21:00',
    '21:00 ~ 22:00',
    '22:00 ~ 23:00',
    '23:00 ~ 0:00',
  ];
  // loading$ = this.longorderService.loading$;
  @Input() dataJson: null | SelectPayMethod;
  @Input() arrivalDateTime: string;
  @Input() subscription_type: string;
  @Input() payment_type: string;
  @Input() pick_up_method: string;
  // private contractHash: string;
  private currentDate: Date;
  private currentHours: number;
  @ViewChild('airportPickupLocationFF', {static: false, read: ElementRef}) airportPickupLocationFF: ElementRef;
  @ViewChild('arrivingFlightNumberFF', {static: false, read: ElementRef}) arrivingFlightNumberFF: ElementRef;
  @ViewChild('pickupDateFF', {static: false, read: ElementRef}) pickupDateFF: ElementRef;
  @ViewChild(UploadDocumentComponent, { static: false })
  uploadComponent: UploadDocumentComponent;
  constructor(
    private longorderService: LongOrderService,
    private fb: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private snackbar: MatSnackBar
  ) { }

  public get availableAirports(): string[] {
    return this.airportList;
  }

  public get airportOpeningHours(): string {
    if (this.otherAirportSelect) {
      return '';
    }
    const found = this.airportSubInformations.filter(candidate => {return candidate.location == this.location_airport.value});
    if (found.length > 0) {
      return found[0].opening_hours;
    }
    return '';
  }

  public get hasOpeningHours(): boolean {
    return this.airportOpeningHours.length > 0;
  }

  ngOnInit() {
    console.log('dataJson', this.dataJson);
    console.log('Testing show case documents selected::', this.subscription_type, this.payment_type, this.pick_up_method);
    this.currentDate = new Date();
    this.currentHours = this.currentDate.getHours();
    this.placeholder = 'Select';
    this.createFormControl();
    this.createAirportFormGoup();
  }

  selectAirportChange(value: string) {
    // calculate current JST
    const dt_local = new Date();
    const dt_as_jst = new Date();
    // tslint:disable-next-line:max-line-length
    dt_as_jst.setTime(dt_local.getTime() + (dt_local.getTimezoneOffset() * 60 * 1000) + (9 * 60 * 60 * 1000) /* Offset for Japanese Standard Time */);
    console.log('JST=', dt_as_jst);

    this.selectDate = new Date(dt_as_jst);
    // this.airport_information.get('pick_up_date').enable();
    if (value === this.airportList[1] || value === this.airportList[2] || value === this.airportList[3]) { // if: Narita airport, Kansai airport
      if (this.currentHours < 16) {
        this.selectDate.setDate(this.selectDate.getDate() + 1);
      }
      if (this.currentHours >= 16) {
        this.selectDate.setDate(this.selectDate.getDate() + 2);
      }
    } else { // if: not Narita airport, Kansai airport
      if (this.currentHours < 12) {
        this.selectDate.setDate(this.selectDate.getDate() + 2);
      } else {
        this.selectDate.setDate(this.selectDate.getDate() + 3);
      }
    }

    if (value === 'Other: Please write your arriving airport') {
      this.placeholder = value;
      this.otherAirportSelect = true;
      this.airport_information.setControl('pick_up_location', this.other_airport);
    } else {
      this.otherAirportSelect = false;
      this.airport_information.setControl('pick_up_location', this.location_airport);
    }
    Add_Remove_Css('remove', this.airportPickupLocationFF, this.renderer);
  }

  arrivingFlightChange(event: any) {
    Add_Remove_Css('remove', this.arrivingFlightNumberFF, this.renderer);
  }
  checkDatePickerEvent(event: any) {
    if (this.pick_up_date.valid) {
      Add_Remove_Css('remove', this.pickupDateFF, this.renderer);
    }
  }
  clickabled(event: any) {
    console.log(event);
  }
  submitAirport() {
    if (this.airport_information.invalid || this.uploadComponent.selectDocument.invalid) {
      if (this.airport_information.invalid) {
        this.checkAirportFormField();
      }
      if (this.uploadComponent.selectDocument.invalid) {
        this.checkSelectDocument();
      } else {
        this.uploadComponent.checkUploadDocument();
      }
      return;
    } else {
      if (
        (this.uploadComponent.showUploadFront &&
          this.uploadComponent.processUploadFront === false) ||
          (this.uploadComponent.showUploadBack &&
            this.uploadComponent.processUploadBack === false) ||
        ( this.uploadComponent.isAddMoreDocument &&
          this.uploadComponent.processUploadAddMoreDoc === false) ||
        (this.uploadComponent.student_discount_checkbox &&
          this.uploadComponent.processUploadStdID === false)
      ) {
        this.uploadComponent.checkUploadDocument();
        return;
      }
    }
    const dateMoment: moment.Moment = this.pick_up_date.value;
    this.airport_information.patchValue({
      pick_up_date: dateMoment.format('LL')
    });
    this.longorderService.setAirportInformation = this.airport_information.getRawValue();
    this.router.navigate(['/step3']);
  }
  private checkAirportFormField() {
    if (this.location_airport.invalid) {
      this.location_airport.markAllAsTouched();
      Add_Remove_Css('add', this.airportPickupLocationFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.arriving_flight_number.invalid) {
      this.arriving_flight_number.markAllAsTouched();
      Add_Remove_Css('add', this.arrivingFlightNumberFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if ( this.pick_up_date.invalid) {
      this.pick_up_date.markAllAsTouched();
      Add_Remove_Css('add', this.pickupDateFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
  }
  private checkSelectDocument() {
    if (this.uploadComponent.selectDocument.invalid) {
      this.uploadComponent.selectDocument.markAllAsTouched();
      Add_Remove_Css('add', this.uploadComponent.selectDocumentFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
  }

  private createFormControl() {
    this.location_airport = this.fb.control('', Validators.required);
    this.other_airport = this.fb.control('', Validators.required),
    this.arriving_flight_number = this.fb.control('', Validators.required);
    this.pick_up_date = this.fb.control('', Validators.required); // { value: '', disabled: true }
    this.estimated_time_of_arrival = this.fb.control('');
    this.note = this.fb.control('');
  }
  private createAirportFormGoup() {
    this.airport_information = this.fb.group({
      pick_up_location: this.location_airport,
      arriving_flight_number: this.arriving_flight_number,
      pick_up_date: this.pick_up_date,
      estimated_time_of_arrival: this.estimated_time_of_arrival,
      note: this.note
    });
  }
}
