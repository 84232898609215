<div class="payment__information">
  <p class="payment__information__note">note:</p>
  <div class="payment__information__card">
    <div class="card accept__card">
      <p class="card__title">Valid cards:</p>
      <p class="card__info">
        We accept both Debit and Credit cards. Cards issued from your
        country can also be accepted.
      </p>
    </div>
  </div>
  <div class="payment__information__images">
    <!-- <img src="../../../assets/images/Asset 1@3x.png" alt="all type credit card accepted" class="images__card" /> -->
    <img src="../../../assets/images/1. American Exp.svg" alt="all type credit card accepted" class="images__card-SVG"/>
    <img src="../../../assets/images/2. Visa.svg" alt="all type credit card accepted" class="images__card-SVG"/>
    <img src="../../../assets/images/3. JCB.svg" alt="all type credit card accepted" class="images__card-SVG"/>
    <img src="../../../assets/images/4. Discover.svg" alt="all type credit card accepted" class="images__card-SVG" />
    <img src="../../../assets/images/5. Diners Club.svg" alt="all type credit card accepted" class="images__card-SVG" />
    <img src="../../../assets/images/6. Master.svg" alt="all type credit card accepted" class="images__card-SVG" />
  </div>
  <div class="payment__information__not__accept__card">
    <p class="card__title">The following cards will not be accepted:</p>
    <p class="card__info">
      We do not accept cash cards, prepaid debit cards such as Mijica
      Card, Line Pay Card,…
    </p>
  </div>
  <div class="payment__information__registeredcard">
    <h2 class="payment__information__registeredcard__title">registered card information</h2>
    <hr class="hr">
  </div>
  <div class="payment__information__card__form">
    <form class="form" [formGroup]="newCardInformation">
      <div class="upper__form">
        <div class="upper__form--card-number">
          <label class="label-card label-card-number">Card Number<app-required></app-required></label>
          <mat-form-field class="form-field " #cardNumberRef>
            <input matInput id="cc-number" type="text" name="card_number" formControlName="card_number" [value]="cardNumber" (focus)="cardNumberChange($event)"
              (keyup)="cardNumberChange($event)" maxlength="16" required autocomplete="cc-number"/>
            <mat-error *ngIf="
              card_number.hasError('pattern') &&
              !card_number.hasError('required')
            ">Number only</mat-error>
            <mat-error *ngIf="card_number.hasError('required')">Required*</mat-error>
            <mat-error *ngIf="
            card_number.hasError('minlength') &&
            !card_number.hasError('required') &&
            !card_number.hasError('pattern')
            ">Minimum 15 number</mat-error>
          </mat-form-field>
        </div>
        <div class="upper__form--card-holder-name">
          <label class="label-card label-card-name">Card Holder’s Name<app-required></app-required></label>
          <p class="label-small">Must match the one registered on the card</p>
          <mat-form-field class="form-field" #cardHolderNameRef>
            <input matInput class="input" id="cc-name" type="text" name="card_name" formControlName="card_holder_name" (focus)="cardHolderNameChange($event)"
              (blur)="makeCapital($event)" [value]="cardName" required autocomplete="cc-name"/>
            <mat-error *ngIf="newCardInformation.get('card_holder_name')?.hasError('required')">Required*</mat-error>
            <mat-error *ngIf="newCardInformation.get('card_holder_name')?.hasError('pattern') && !newCardInformation.get('card_holder_name')?.hasError('required')"
            >Alphabets only*</mat-error>
            <mat-hint class="card-hint">Please do not insert any special characters such as “-“,“.”,...</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="under__form">
        <div class="under__form--expire-date">
          <label class="label-card label-expire">Expiry Date<app-required></app-required></label>
          <mat-form-field class="field-expire time-input" floatLabel="never" hideRequiredMarker #expireCombined>
            <input matInput placeholder="MM/YY" id="expire-month-year" name="cc-exp" type="text" (focus)="expireCombinedChange($event)" formControlName="expire_combined"
              (blur)="onBlurExpireMonth($event)" maxlength="5" required autocomplete="cc-exp"/>
            <mat-error *ngIf="expire_combined.hasError('required')">Required*</mat-error>
          </mat-form-field>
        </div>
        <div class="under__form--cvv">
          <label class="label-card label-cvv">CVV<app-required></app-required></label>
          <mat-form-field class="field-cvv time-input" floatLabel="never" hideRequiredMarker #cvvRef>
            <input #cvvInput matInput id="cc-csc" name="cc-csc" placeholder="***" (focus)="cvvChange($event)" (blur)="hidePassword()"
              formControlName="card_cvv" maxlength="4" required autocomplete="cc-csc"/>
            <mat-error *ngIf="newCardInformation.get('card_cvv')?.hasError('pattern') && !newCardInformation.get('card_cvv')?.hasError('required')"
            >Number only</mat-error>
            <mat-error *ngIf="newCardInformation.get('card_cvv')?.hasError('required')"
            >Required*</mat-error>
            <mat-error *ngIf="newCardInformation.get('card_cvv')?.hasError('minlength') && 
            !newCardInformation.get('card_cvv')?.hasError('required') && 
            !newCardInformation.get('card_cvv')?.hasError('pattern')">Minimum 3 number</mat-error>
          </mat-form-field>
        </div>

      </div>
    </form>
    <div class="message">
      <label class="title">Note:</label>
      <p class="info">
        This newly registered card will be used to make future payments for all contracts that are under this email
        address.
      </p>
    </div>
  </div>
  <hr class="inline__hr">
</div>