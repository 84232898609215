<div class="finish">
  <header>
    <app-header [step]="4"></app-header>
  </header>
  <div *ngIf="done !== true" class="contents">
    <div class="contents__body">
      Thank you for completing the card authentication process. <br/>
      Your registered card is currently being updated in our system.<br/>
      <br/>
      Once the update is complete, this screen will automatically change to the confirmation page.<br/>
      If the screen does not update within 1 minute, please contact our customer support team using this <a href="https://www.sakuramobile.jp/contact-us?booking={{affi_contract_code}}&email={{email}}" target="_blank" class="contact_us" rel="noopener noreferrer" >link</a>.<br/>
    </div>
  </div>
  <div *ngIf="done === true" class="contents">
    <div class="contents__img">
      <img src="../../../../assets/images/sakura-done.svg" alt="you got email image" class="sakura__done">
    </div>
    <div class="contents__body">
      <h1>Your application is completed!</h1>
      <p>Thank you for choosing Sakura Mobile! </p>
      <p>You will receive an email within 24 hours with information on how to receive the item.</p>
      <p class="contact--us">If you have any questions,<a href="https://www.sakuramobile.jp/contact-us/?email={{email}}&booking={{affi_contract_code}}" target="_blank" class="contact_us" rel="noopener noreferrer"> contact us.</a></p>
      <!-- Survey -->
      <div class="contents__survey">
        <p>To improve our service for customers, we would like to respectfully ask for your cooperation in completing a short survey for our service.<br/>
          After 10 seconds, you will be automatically taken to the survey form. If you are not taken there automatically, please click the link below.</p>
        <p><a href="https://docs.google.com/forms/d/e/1FAIpQLScZDpYctpHxWn1Keri3jWPjdwYrDs-f2eJjYANX1aHV2zDW6Q/viewform?entry.583804134={{email}}&entry.2034490624={{affi_contract_code}}" target="_blank" class="contact_us">Take me to the Survey Form</a></p>
        <p class="redirect--homepage"><a href="https://www.sakuramobile.jp" target="_blank" class="contact_us">Take me to the Sakura Mobile Homepage </a></p>
      </div>
    </div>

  </div>
  <!-- Affiliate -->
  <img src="https://p.sakuramobile.jp/sale.php?profile=72198&idev_saleamt=20000&idev_ordernum={{this.affi_contract_code}}"
    style="height:0px; width:0px; border:0px;" />
</div>
