
import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
} from 'rxjs/operators';
import { LongOrderService } from 'src/app/services/long-order.service';
import { CouponDiscount } from 'src/app/types/coupon-discount';
import { OrderInformation } from 'src/app/types/order-information';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-registered-information',
  templateUrl: './registered-information.component.html',
  styleUrls: ['./registered-information.component.scss'],
})
export class RegisteredInformationComponent implements OnInit, OnDestroy {
  private sub = new SubSink();
  isStudentDiscount: boolean;
  isStudentDiscountDefault: boolean;
  studentDiscountPrice: number;
  totalCharge: number;
  initialVocieDeposit: number;
  quantity: number;
  subTotal: number;
  vat: number;
  total: number;
  subscriptionType: string;
  isCouponValid: boolean;
  isCouponInvalid: boolean;
  isCouponfiled: boolean;
  avaibledCouponCode: boolean;
  couponPrice: number;
  couponField: string;
  contractHash: string;
  flatArray: Array<any>;
  firstProductName: string;
  secondProductName: string;
  priceFirstProduct: number;
  priceSecondProduct: number;
  depositTotal: number;
  initialTotal: number;
  couponFC: FormControl;
  discountPrice: number;
  couponDiscount: CouponDiscount;
  orderInformation$: Observable<OrderInformation> = this.longOrderServices.orderInformation$
    .pipe(shareReplay());
  product$ = this.orderInformation$.pipe(
    map(res => res.data.subscription_plan)
  );
  paymentMethod$: Observable<string> = this.longOrderServices.paymentMEthod$; // CREDIT CARD || SMARTPIT
  pickupMethod$: Observable<string> = this.longOrderServices.pickupMethod$; // AIRPORT || OFFICE || DELIVERY
  ekycInformation$ = this.longOrderServices.ekycInformation$;
  airportInformation$ = this.longOrderServices.airportInformation$;
  officeInformation$ = this.longOrderServices.officeInformation$;
  deliveryInformation$ = this.longOrderServices.deliveryInformation$;

  @Output() titleCouponCode = new EventEmitter();
  @Output() pickupMethodEvent = new EventEmitter();
  @Output() pickupInformation = new EventEmitter();
  constructor(private longOrderServices: LongOrderService) { }
  ngOnInit() {
    this.isStudentDiscount = false;
    this.isStudentDiscountDefault = false;
    this.isCouponValid = false;
    this.isCouponfiled = false;
    this.isCouponInvalid = false;
    this.couponFC = new FormControl('');
    this.sub.sink = this.longOrderServices.subcriptionType$.subscribe(res => this.subscriptionType = res);
    this.sub.sink = this.longOrderServices.isStudentCheck$.subscribe(
      (res) => {
        this.isStudentDiscount = res;
      }
    );
    this.sub.sink = this.longOrderServices.isStudentCheck$.subscribe(
      (res) => {
        this.isStudentDiscountDefault = res;
      }
    );
    this.sub.sink = this.orderInformation$
      .pipe(
        map((res) => res.data.quantity))
      .subscribe((res) => {
        this.quantity = Number(res);
      });

    this.sub.sink = this.longOrderServices.contractHash$.subscribe(
      (value) => {
        this.contractHash = value;
      });

    this.sub.sink = this.couponFC.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((res) => {
        if (res) {
          this.isCouponfiled = true;
        } else {
          this.isCouponfiled = false;
          this.isCouponInvalid = false;
        }
        this.couponField = res;
      });
    this.sub.sink = this.pickupMethod$.subscribe(pickupType => {
      this.pickupMethodEvent.emit(pickupType);
      if (pickupType === 'AIRPORT') {
        this.pickupInformation.emit(this.airportInformation$);
      }
      if (pickupType === 'OFFICE') {
        this.pickupInformation.emit(this.officeInformation$);
      }
      if (pickupType === 'DELIVERY') {
        this.pickupInformation.emit(this.deliveryInformation$);
      }
      if (pickupType === 'EKYC') {
        this.pickupInformation.emit(this.ekycInformation$);
      }
    });
    // caculator
    this.totalCharge = 5000 * this.quantity;
    this.studentDiscountPrice = 1000 * this.quantity; // apply for all quantity
    this.initialVocieDeposit = 20000;
    if (!this.isStudentDiscount) {
      this.subTotal = this.totalCharge;
      this.vat = Math.floor(this.subTotal * 0.1);
      this.total = this.subTotal + this.vat;
    } else {
      this.subTotal =
        this.totalCharge - this.studentDiscountPrice;
      this.vat = Math.floor(this.subTotal * 0.1);
      this.total = this.subTotal + this.vat;
    }
    this.sub.sink = this.orderInformation$.pipe(
      map(order => order.data.products.flat())
    ).subscribe(res => {
      this.flatArray = res;
    });
    this.sub.sink = this.longOrderServices.couponCode$
      .pipe(filter(coupon => !!coupon))
      .subscribe(res => {
        this.avaibledCouponCode = true;
        this.couponFC.patchValue(res);
        this.couponField = res;
        this.applyCouponDiscount();
      });
  }
  depositTotalChangeEvent(valueChange: number) {
    this.depositTotal = valueChange;
    this.initialTotal = this.total + this.depositTotal;
  }
  applyCouponDiscount() {
    this.isCouponValid = false;
    this.isCouponInvalid = false;
    this.isStudentDiscount = this.isStudentDiscountDefault; // reset to default
    if (this.isStudentDiscount) {
      this.titleCouponCode.emit('#*@STUDENT ID#*@');
    }
    else {
      this.titleCouponCode.emit(null);
    }
    this.sub.sink = this.longOrderServices
      .GetCouponDiscount(this.couponField, this.contractHash)
      .subscribe(
        (res) => {
          if (res === null) {
            this.isCouponValid = false;
            this.isCouponInvalid = true;
            this.discountPrice = 0;
            this.couponDiscount = null; // clear coupon
            if (!this.isStudentDiscount) {
              this.subTotal = this.totalCharge - (this.discountPrice);
              this.vat = Math.floor(this.subTotal * 0.1);
              this.total = this.subTotal + this.vat;
              this.initialTotal = this.total + this.depositTotal;
            } else {
              this.subTotal =
                this.totalCharge -
                (this.studentDiscountPrice + this.discountPrice);
              this.vat = Math.floor(this.subTotal * 0.1);
              this.total = this.subTotal + this.vat;
              this.initialTotal = this.total + this.depositTotal;
            }
          } else {
            if (this.isStudentDiscount) {
              console.log('applyCouponDiscount : which is big?', res.data.price);
              if (Number(res.data.price) <= 1000 /* this.studentDiscountPrice */) {
                this.isCouponInvalid = false;
                return; // don't apply student discount
              }
              else {
                this.isStudentDiscount = false; // use coupon only
              }
            }
            this.isCouponValid = true;
            this.isCouponInvalid = false;
            this.couponDiscount = res;
            this.titleCouponCode.emit(res.data.coupon_code);
            this.discountPrice = Number(res.data.price) * this.quantity;
            if (!this.isStudentDiscount) {
              this.subTotal = this.totalCharge - (this.discountPrice);
              this.vat = Math.floor(this.subTotal * 0.1);
              this.total = this.subTotal + this.vat;
              this.initialTotal = this.total + this.depositTotal;
            } else {
              this.subTotal =
                this.totalCharge -
                (this.studentDiscountPrice + this.discountPrice);
              this.vat = Math.floor(this.subTotal * 0.1);
              this.total = this.subTotal + this.vat;
              this.initialTotal = this.total + this.depositTotal;
            }
          }
        }
      );
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
