import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChildren,
  QueryList,
  ViewChild,
  AfterViewChecked,
  OnDestroy,
  ElementRef,
  Renderer2,
  asNativeElements,
  Output,
  Inject,
} from '@angular/core';
import { LongOrderService } from 'src/app/services/long-order.service';
import { Observable, of } from 'rxjs';
import { filter, map, pluck, switchMap } from 'rxjs/operators';
import { Wifi } from 'src/app/types/wifi';
import { HomeWifi } from 'src/app/types/home-wifi';
import { VoiceDataSim } from 'src/app/types/voice-data';
import { ProductInformation } from 'src/app/types/product-information';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  AsyncValidatorFn,
} from '@angular/forms';
import { countryList } from 'src/app/country-list';
import { WifiVoiceDataSimPlanComponent } from './wifi-voice-data-sim-plan/wifi-voice-data-sim-plan.component';
import { HomeWifiPlanComponent } from './home-wifi-plan/home-wifi-plan.component';
import { VoiceDataSimPlanComponent } from './voice-data-sim-plan/voice-data-sim-plan.component';
import { PocketWifiPlanComponent } from './pocket-wifi-plan/pocket-wifi-plan.component';
import { CustomValicators } from 'src/app/validators/custom.validators';
import { InsuranceOptionComponent } from './insurance-option/insurance-option.component';
import { ChangeDetectorRef } from '@angular/core';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { Constants } from 'src/app/constants/constants';
import { ThrowStmt } from '@angular/compiler';
import { stringify } from 'querystring';
import { FullNameDialogComponent } from './full-name-dialog/full-name-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class BodyComponent implements OnInit, AfterViewChecked, OnDestroy {
  private sub = new SubSink();
  private EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  countryList: string[];
  public titleProducts: string[] = [
    'VOICE+DATA SIM & POCKET WIFI',
    'VOICE+DATA eSIM & POCKET WIFI',
    'VOICE + DATA SIM CARD',
    'VOICE + DATA eSIM',
    'DATA ONLY SIM CARD',
    'DATA ONLY eSIM',
    'POCKET WIFI',
    'HOME WIFI'
  ];
  paramsProducts: { [key: string]: string } = {
    'voicesim_wifi': 'VOICE+DATA SIM & POCKET WIFI',
    'voice_esim_wifi': 'VOICE+DATA eSIM & POCKET WIFI',
    'voicesim': 'VOICE + DATA SIM CARD',
    'voice_esim': 'VOICE + DATA eSIM',
    'datasim': 'DATA ONLY SIM CARD',
    'data_esim': 'DATA ONLY eSIM',
    'wifi': 'POCKET WIFI',
    'home_wifi': 'HOME WIFI'
  };
  airportList = [
    'Haneda International Airport',
    'Narita International Airport Terminal 1',
    'Narita International Airport Terminal 2',
    'Kansai International Airport',
    'Other',
  ];
  quantitys = [1];
  locations = ['IN JAPAN', 'OUTSIDE OF JAPAN'];
  isParamsHomeWifi: boolean;
  // Select product and qantity
  isSelectedProduct: boolean;
  isSelectQuantity: boolean;
  // Checkbox MNP
  showMNP: boolean;
  isCheckMNP: boolean;
  // Control select plan
  showSelectLocation: boolean;
  showBasicInformation: boolean;
  // Check is select expected date and airport
  isSelectDate: boolean;
  isSelectAirport: boolean;

  inJapan: boolean;
  outSideJapan: boolean;
  nameProduct: string;
  selectedQuantity: number = 0;
  maxQuantity: number = 5;
  isQuantityVisible: boolean = false;
  selectedProduct: string | null = null;
  isMnpRequestActive: boolean = false;
  eSIMInJapan: boolean;
  useEKYC: boolean;
  willPayCredit: boolean = false;
  willPayCash: boolean = false;
  hasEKYCResidenceWithJPAdress: boolean;
  hasEKYCResidenceNoJPAdress: boolean;
  hasEKYCPassport: boolean;
  hasEKYCJPDriveLicense: boolean;
  hasEKYCJPMyNumber: boolean;
  hasEKYCOtherFlag: boolean;

  otherAirport: boolean;

  selectedLocation: string;
  selectedAirport: string;
  params: string;

  productPlanInformation$: Observable<ProductInformation>;
  pocketWifi$: Observable<Wifi>;
  voiceDataSim$: Observable<VoiceDataSim>;
  homeWifi$: Observable<HomeWifi>;

  arrVoiceData: Observable<VoiceDataSim>[] = [];
  arrPocketWifi: Observable<Wifi>[] = [];
  arrWifiVoice: Observable<ProductInformation>[] = [];
  arrHomeWifi: Observable<HomeWifi>[] = [];
  isMinorCheck: boolean;
  private coupon_code: string;
  private referral_code: string;
  // Form Control
  firstName: FormControl;
  firstNameValue: string = '';
  // middleName: FormControl;
  // lastName: FormControl;
  // title: FormControl;
  user_name: FormControl;
  parent_email: FormControl;
  email: FormControl;
  // nationality: FormControl;
  product_type: FormControl;
  quantity: FormControl;
  current_location: FormControl;
  name: FormControl;
  kana: FormControl;
  sex: FormControl;
  birth_date: FormControl;
  current_phone_number: FormControl;
  mnp_reservation_number: FormControl;
  mnp_reservation_expire_date: FormControl;
  mnp_incoming_provider: FormControl;
  otherAirportFC: FormControl;
  expected_date_of_arrival: FormControl;
  arriving_airport: FormControl;
  products: FormArray;
  location: FormGroup;
  submitDataForm: FormGroup;
  mnpForm: FormGroup;
  fullNameFG: FormGroup;
  document_other_note: FormControl;
  form: FormGroup;

  homeWifiFG: FormGroup;
  homeWifiFormArray: FormArray;

  itemSpecifiedProcess: boolean = false;

  @ViewChild('emailFormField', { static: false, read: ElementRef }) emailFormField: ElementRef;
  @ViewChild('parentEmailFormField', { static: false, read: ElementRef }) parentEmailFormField: ElementRef;
  // @ViewChild('titleFormField', { static: false, read: ElementRef }) titleFormField: ElementRef;
  @ViewChild('firstNameFormField', { static: false, read: ElementRef }) firstNameFormField: ElementRef;
  // @ViewChild('nationalityFormField', { static: false, read: ElementRef }) nationalityFormField: ElementRef;
  @ViewChild('nameFormField', { static: false, read: ElementRef }) nameFormField: ElementRef;
  @ViewChild('kanaFormField', { static: false, read: ElementRef }) kanaFormField: ElementRef;
  @ViewChild('sexFormField', { static: false, read: ElementRef }) sexFormField: ElementRef;
  @ViewChild('birthDayFormField', { static: false, read: ElementRef }) birthDayFormField: ElementRef;
  @ViewChild('currentPhoneFormField', { static: false, read: ElementRef }) currentPhoneFormField: ElementRef;
  @ViewChild('reservationPhoneFormField', { static: false, read: ElementRef }) reservationPhoneFormField: ElementRef;
  @ViewChild('expiryDateFormField', { static: false, read: ElementRef }) expiryDateFormField: ElementRef;

  @ViewChildren(WifiVoiceDataSimPlanComponent)
  listVoiceWifiComponent: QueryList<WifiVoiceDataSimPlanComponent>;
  @ViewChildren(VoiceDataSimPlanComponent)
  listVoiceDataSimComponent: QueryList<VoiceDataSimPlanComponent>;
  @ViewChildren(PocketWifiPlanComponent)
  listPocketWifiComponent: QueryList<PocketWifiPlanComponent>;
  @ViewChildren(InsuranceOptionComponent)
  listInsuranceOptionComponent: QueryList<InsuranceOptionComponent>;
  @ViewChildren(HomeWifiPlanComponent)
  listHomeWifiComponent: QueryList<HomeWifiPlanComponent>;
  public providers = [
    'docomo',
    'Softbank',
    'au',
    'LINE MOBILE',
    'Rakuten Mobile',
    'UQ mobile',
    'Y! mobile',
    'IIJ mio',
    'BIGLOBE',
    'mobal',
    'JP Smart SIM',
    'GTN MOBILE',
    'Other',
  ];
  startDate: Date;
  myFilterDate: any;
  setBorder: any;
  loading$ = this.longOrderService.loading$;
  constructor(
    private longOrderService: LongOrderService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private render2: Renderer2,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.isParamsHomeWifi = false;
    this.showMNP = false;
    this.isMinorCheck = false;
    this.inJapan = false;
    this.outSideJapan = false;
    this.eSIMInJapan = false;
    this.useEKYC = false;
    this.otherAirport = false;
    this.itemSpecifiedProcess = false;
    this.couponCodeCheck();
    this.referralCodeCheck();
    this.createFormControl();
    this.createNewForm();
    this.countryList = countryList;
    this.startDate = new Date(1990, 0, 1);
    this.myFilterDate = (d: Date | null): boolean => {
      const day = d.getDate();
      const after7Date = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
      return d > after7Date;
    };
    this.activeRoute.queryParams.subscribe(params => {
      const item: string = params['item'];
      if (!(item in this.paramsProducts)) {
        this.itemSpecifiedProcess = true;
        return;
      }
      if (item === 'home_wifi') {
        this.titleProducts = ['HOME WIFI'];
        this.isParamsHomeWifi = true;
        // this.itemSpecifiedProcess = true;
      }
      this.getPlansInformation(this.paramsProducts[item]);
    });
    this.form = this.fb.group({
      firstName: [''],
    });
  }

  openFullNameDialog() {
    const currentFullName = this.firstName.value;
    const dialogRef = this.dialog.open(FullNameDialogComponent, {
      panelClass: 'modal_wrapper',
      data: { fullName: currentFullName }
    });

    dialogRef.componentInstance.formSubmitted.subscribe((formValue: any) => {
      console.log('Received form value from dialog:', formValue);
      this.firstName.patchValue(formValue.fullName);
    });

    dialogRef.componentInstance.formSubmitted.subscribe((formValue) => {
      console.log('Received form value from dialog:', formValue);
      this.submitBasicInformation(formValue);
    });
  }

  getPlansInformation(value: string) {
    console.log(`BodyComponent::getPlansInformation(${value})`);
    this.nameProduct = value;
    this.isMinorCheck = false;
    // control select product and quantity
    this.isSelectedProduct = true;
    // Reset quantity, location, basic info
    this.isSelectQuantity = false;
    this.showSelectLocation = false;
    this.showBasicInformation = false;
    // reset MNP check
    this.isCheckMNP = false;
    // reset quantity when re-select product
    this.selectedQuantity = 1;
    this.arrVoiceData.length = 0;
    this.arrPocketWifi.length = 0;
    this.arrWifiVoice.length = 0;
    this.arrHomeWifi.length = 0;
    this.selectedAirport = '';
    this.selectedLocation = '';
    this.setBorder = null;
    this.inJapan = false;
    this.outSideJapan = false;
    this.eSIMInJapan = false;
    this.useEKYC = false;
    this.isMnpRequestActive = false;
    // Reset form data when re-select product
    this.fullNameFG.reset();
    this.submitDataForm.reset();
    this.mnpForm.reset();
    // patch product_type value
    this.submitDataForm.get('product_type').patchValue(value);
    // get plans information
    this.params = this.convertTitleProduct(value);
    this.productPlanInformation$ = this.longOrderService.getPlansInformation(
      this.params
    );
    switch (this.params) {
      case 'wifi,voicesim':
        // this.isQuantityVisible = true;
        this.arrWifiVoice.push(this.productPlanInformation$);
        break;
      case 'datasim':
      case 'voicesim':
      case 'data_esim':
      case 'voice_esim':
        console.log('COUNTING UP? BodyComponent::getPlansInformation value=', value);
        // this.isQuantityVisible = true;
        this.voiceDataSim$ = this.productPlanInformation$.pipe(
          map((data) => {
            console.log(`BodyComponent::getPlansInformation(${value}) : case-voice/data : `, data);
            return data[0] as VoiceDataSim;
          })
        );
        this.arrVoiceData.push(this.voiceDataSim$);
        break;
      case 'wifi':
        // this.isQuantityVisible = true;
        this.pocketWifi$ = this.productPlanInformation$.pipe(
          map((data) => {
            console.log(`BodyComponent::getPlansInformation(${value}) : case-wifi : `, data);
            return data[0] as Wifi;
          })
        );
        this.arrPocketWifi.push(this.pocketWifi$);
        break;
      case 'home_wifi':
        this.isQuantityVisible = true;
        this.homeWifi$ = this.productPlanInformation$.pipe(
          map((data) => {
            console.log(`BodyComponent::getPlansInformation(${value}) : case-home-wifi : `, data);
            return data[0] as HomeWifi;
          })
        );
        this.arrHomeWifi.push(this.homeWifi$);
        break;
    }
  }
  mnpRequest(value: boolean) {
    this.isCheckMNP = value;
    this.isMnpRequestActive = value;
    this.setBorder = null;
    this.submitDataForm.get('quantity').patchValue(1);
    value
      ? this.submitDataForm.addControl('mnp', this.mnpForm)
      : this.submitDataForm.removeControl('mnp');
    if (value === true && this.params === 'wifi,voicesim') {
      this.selectedQuantity = 1;
      this.isSelectQuantity = true;
      this.arrWifiVoice.length = 1;
      this.setBorder = {
        'border-color': '#EFEFEF',
        color: '#A8A8A8',
      };
    }
    if (value === true && this.params === 'voicesim') {
      this.selectedQuantity = 1;
      this.isSelectQuantity = true;
      this.arrVoiceData.length = 1;
      this.setBorder = {
        'border-color': '#EFEFEF',
        color: '#A8A8A8',
      };
    }
  }

  selectQuantityOpen() {
    if (this.selectedQuantity === 1) {
      this.selectQuantity(this.selectedQuantity)
    }
  }
  increaseQuantity() {
    if (this.selectedQuantity < this.maxQuantity) {
      this.selectedQuantity += 1;
      this.selectQuantity(this.selectedQuantity)
    }
  }
  decreaseQuantity() {
    if (this.selectedQuantity > 1) {  
      this.selectedQuantity -= 1;
      this.selectQuantity(this.selectedQuantity)
    }
  }


  selectQuantity(value: number) {
    // Reset submit form ===> continute teting late
    if (this.submitDataForm.valid) {
      this.current_location.reset();
      this.selectedLocation = '';
      this.firstName.reset();
      // this.lastName.reset();
      // this.middleName.reset();
      this.email.reset();
      // this.nationality.reset();
    }
    // Reset all form data when re-select quantity
    this.selectedLocation = '';
    this.locationFG.reset();
    this.isSelectAirport = false;
    this.isSelectDate = false;
    this.inJapan = false;
    this.outSideJapan = false;
    this.eSIMInJapan = false;
    this.useEKYC = false;
    this.isMinorCheck = false;
    this.listVoiceWifiComponent.forEach(
      (component: WifiVoiceDataSimPlanComponent) => {
        component.isPlans = '';
        component.isPocketWifi = '';
        component.isPocketWifiOptional = '';
        component.voiceDataFG.reset();
        component.pocketWifiFG.reset();
        component.promosetFormArray.reset();
      }
    );
    this.listVoiceDataSimComponent.forEach(
      (component: VoiceDataSimPlanComponent) => {
        component.isPlans = '';
        component.isSelectVoiceSim = false;
        component.voiceDataSimFG.reset();
        component.voiceDataSimFormArray.reset();
      }
    );
    this.listPocketWifiComponent.forEach(
      (component: PocketWifiPlanComponent) => {
        component.isPocketWifi = '';
        component.isPocketWifiOptional = '';
        component.pocketWifiFG.reset();
      }
    );
    // this.listHomeWifiComponent.forEach(
    //   (component: HomeWifiPlanComponent) => {
    //     component.isPlans = '';
    //     component.isSelectHomeWifi = false;
    //     component.homeWifiFG.reset();
    //     component.homeWifiFormArray.reset();
    //   }
    // )
    if (
      (this.params === 'wifi,voicesim' || this.params === 'wifi') &&
      this.listInsuranceOptionComponent.first
    ) {
      this.listInsuranceOptionComponent.first.insuranceFG.reset();
      this.listInsuranceOptionComponent.first.isPocketWifiOptional = '';
      this.listInsuranceOptionComponent.first.selectInsurance = false;
    }
    // }
    this.isSelectQuantity = true;
    this.showSelectLocation = false;
    this.showBasicInformation = false;
    this.selectedQuantity = value;
    this.submitDataForm.get('quantity').patchValue(value);

    switch (this.params) {
      case 'wifi,voicesim':
      case 'wifi,voice_esim':
        this.arrWifiVoice = Array(value).fill(this.productPlanInformation$);
        break;
      case 'datasim':
      case 'voicesim':
      case 'data_esim':
      case 'voice_esim':
        this.arrVoiceData = Array(value).fill(this.voiceDataSim$);
        break;
      case 'wifi':
        this.arrPocketWifi = Array(value).fill(this.pocketWifi$);
        break;
      case 'home_wifi':
        this.arrHomeWifi = Array(value).fill(this.homeWifi$);
        if (this.homeWifiFG !== undefined) {
          this.homeWifiFG.reset();
        }
        if (this.homeWifiFormArray !== undefined) {
          this.homeWifiFormArray.reset();
        }
        if (this.productsFormArrsubmitDataForm !== undefined) {
          this.productsFormArrsubmitDataForm.clear();
        }

        for (let homeWifi$ of this.arrHomeWifi) {
          this.homeWifiFG = this.fb.group({
            category_type_id: [null, Validators.required],
            category_type: [null, Validators.required],
            product_type_id: [null, Validators.required],
            product_type: [null, Validators.required],
            options: this.fb.array([])
          });
          this.homeWifiFormArray = this.fb.array([this.homeWifiFG]);

          homeWifi$.subscribe((homeWifiPlan) => {
            const found = homeWifiPlan.plans.find(item => item.plan_title === 'HOME WIFI');
            this.homeWifiFG.patchValue({
              category_type_id: homeWifiPlan.category_id,
              category_type: homeWifiPlan.category_title,
              product_type_id: found.product_type_id,
              product_type: found.product_type,
              options: [],
            });
            this.productsFormArrsubmitDataForm.push(this.homeWifiFormArray);
          })
        }
        this.showBasicInformation = true;
        break;
    }
  }

  selectLocation(value: string) {
    this.selectedLocation = value;
    this.isSelectAirport = false;
    this.isSelectDate = false;
    this.willPayCredit = false;
    this.willPayCash = false;
    this.inJapan = value === 'IN JAPAN';
    if (this.inJapan) {
      if (this.params === 'voice_esim' || this.params === 'data_esim') {
        this.showBasicInformation = false;
        this.eSIMInJapan = true;
      }
      else {
        this.showBasicInformation = true;
      }
    }
    else {
      this.showBasicInformation = false;
    }
    value === 'OUTSIDE OF JAPAN'
      ? (this.outSideJapan = true)
      : (this.outSideJapan = false); // show or hide template calender and airport list
    if (this.outSideJapan === false) {
      this.locationFG.removeControl('expected_date_of_arrival');
      this.locationFG.removeControl('arriving_airport');
    } else {
      this.eSIMInJapan = false;
      this.useEKYC = false;
      this.otherAirport = false;
      this.selectedAirport = '';
      this.locationFG.addControl(
        'expected_date_of_arrival',
        this.expected_date_of_arrival
      );
      this.locationFG.addControl('arriving_airport', this.arriving_airport);
    }
    this.submitDataForm.get('location').patchValue({
      current_location: value,
    });
  }

  selectDate(value: Date) {
    this.isSelectDate = true;
    if (this.isSelectDate && this.isSelectAirport) {
      this.showBasicInformation = true;
    } else if (this.isSelectDate && this.otherAirportFC.valid) {
      this.showBasicInformation = true;
    } else {
      this.showBasicInformation = false;
    }
    const newDateFormat = this.formatDate(value);
    this.submitDataForm.get('location').patchValue({
      expected_date_of_arrival: newDateFormat,
    });
  }

  selectAirport(value: string) {
    this.selectedAirport = value;
    if (value === 'Other') {
      this.showBasicInformation = false;
      this.isSelectAirport = false;
      this.otherAirport = true;
      this.submitDataForm.get('location').get('arriving_airport').reset();
      this.sub.sink = this.otherAirportFC.valueChanges.subscribe((res) => {
        console.log(res);
        this.submitDataForm.get('location').patchValue({
          arriving_airport: res,
        });
        if (this.isSelectDate && this.otherAirportFC.valid) {
          this.showBasicInformation = true;
        } else {
          this.showBasicInformation = false;
        }
      });
    } else {
      this.isSelectAirport = true;
      this.otherAirport = false;
      this.otherAirportFC.reset();
      this.submitDataForm.get('location').patchValue({
        arriving_airport: value,
      });
      if (this.isSelectDate && this.isSelectAirport) {
        this.showBasicInformation = true;
      }
    }
  }

  ngAfterViewChecked() {
    switch (this.params) {
      case 'wifi,voicesim':
      case 'wifi,voice_esim':
        if (this.listVoiceWifiComponent.length >= 1) {
          const allSelectProduct = this.listVoiceWifiComponent.find(
            (component: WifiVoiceDataSimPlanComponent) =>
              component.selectVoiceSim === false ||
              component.selectPocketWifi === false
          );
          const allSelectInsurance = this.listInsuranceOptionComponent.find(
            (com: InsuranceOptionComponent) => com.selectInsurance === false
          );
          if (
            allSelectProduct === undefined &&
            allSelectInsurance === undefined
          ) {
            this.showSelectLocation = true; // show select plan
          }
        }
        break;
      case 'wifi':
        if (this.listPocketWifiComponent.length >= 1) {
          const allSelectProduct = this.listPocketWifiComponent.find(
            (component: PocketWifiPlanComponent) =>
              component.selectPocketWifi === false
          );
          const allSelectInsurance = this.listInsuranceOptionComponent.find(
            (com: InsuranceOptionComponent) => com.selectInsurance === false
          );
          if (
            allSelectProduct === undefined &&
            allSelectInsurance === undefined
          ) {
            this.showSelectLocation = true; // show select plan
          }
        }
        break;
      case 'home_wifi':
        // if (this.listHomeWifiComponent.length >= 1) {
        //   const allSelectProduct = this.listHomeWifiComponent.find(
        //     (component: HomeWifiPlanComponent) =>
        //       component.isSelectHomeWifi === false
        //   );
        //   if (allSelectProduct === undefined) {
        //     this.showBasicInformation = true;
        //   }
        // }
        break;
      case 'datasim':
      case 'voicesim':
      case 'data_esim':
      case 'voice_esim':
        if (this.listVoiceDataSimComponent.length >= 1) {
          const allSelectProduct = this.listVoiceDataSimComponent.find(
            (component: VoiceDataSimPlanComponent) =>
              component.isSelectVoiceSim === false
          );
          if (allSelectProduct === undefined) {
            this.showSelectLocation = true; // show select plan
          }
        }
        break;
    }
    this.cdr.detectChanges();
    if (!this.itemSpecifiedProcess) {
      // NOTE : Workarround
      setTimeout(() => {
        this.itemSpecifiedProcess = true;
        this.selectQuantity(this.selectedQuantity);
      }, 100);
    }
  }
  getReservationErr() {
    if (this.mnp_reservation_number.hasError('pattern')) {
      return 'Must be a number';
    } else if (this.mnp_reservation_number.hasError('minlength')) {
      return 'Minimum 10 number';
    } else {
      return 'Required*';
    }
  }
  // titleChange(event: any) {
  //   this.render2.removeClass(this.titleFormField.nativeElement, 'form-field-invalid');
  // }
  firstNameChange(event: any) {
    const input = event.target as HTMLInputElement;
    this.firstNameValue = input.value;
    this.render2.removeClass(this.firstNameFormField.nativeElement, 'form-field-invalid');
  }
  emailChange(event: any) {
    this.render2.removeClass(this.emailFormField.nativeElement, 'form-field-invalid');
  }
  documentOtherNoteChange(event: any) {
    this.document_other_note = event.target.value;
    console.log(`NOTE : ${this.document_other_note}`);
  }
  parentEmailChange(event: any) {
    this.render2.removeClass(this.parentEmailFormField.nativeElement, 'form-field-invalid');
  }
  // nationalityChange(event: any) {
  //   this.render2.removeClass(this.nationalityFormField.nativeElement, 'form-field-invalid');
  // }
  nameChange(event: any) {
    this.render2.removeClass(this.nameFormField.nativeElement, 'form-field-invalid');
  }
  kanaChange(event: any) {
    this.render2.removeClass(this.kanaFormField.nativeElement, 'form-field-invalid');
  }
  sexChange(event: any) {
    this.render2.removeClass(this.sexFormField.nativeElement, 'form-field-invalid');
  }
  birthDayChange(event: any) {
    this.render2.removeClass(this.birthDayFormField.nativeElement, 'form-field-invalid');
  }
  currentPhoneChange(event: any) {
    this.render2.removeClass(this.currentPhoneFormField.nativeElement, 'form-field-invalid');
  }
  reservationPhoneChange(event: any) {
    this.render2.removeClass(this.reservationPhoneFormField.nativeElement, 'form-field-invalid');
  }
  expiryDateChange(event: any) {
    this.render2.removeClass(this.expiryDateFormField.nativeElement, 'form-field-invalid');
  }
  minorCheckBox(check: boolean) {
    this.isMinorCheck = check;
    if (this.isMinorCheck) {
      this.submitDataForm.addControl('parent_email', this.parent_email);
    } else {
      this.submitDataForm.removeControl('parent_email');
    }
  }

  onPayCredit() {
    this.willPayCredit = !this.willPayCredit;
    this.willPayCash = !this.willPayCredit;
    this.showBasicInformation = true;
    this.updateUseEKYC();
    console.log(`CURRENT PAY METHOD : CREDIT ${this.willPayCredit} / CASH ${this.willPayCash}`);
  }

  onPayCash() {
    this.willPayCash = !this.willPayCash;
    this.willPayCredit = !this.willPayCash;
    this.showBasicInformation = true;
    this.updateUseEKYC();
    console.log(`CURRENT PAY METHOD : CREDIT ${this.willPayCredit} / CASH ${this.willPayCash}`);
  }

  // onWillPayCredit(check: boolean) {
  //   this.willPayCredit = check;
  //   this.updateUseEKYC();
  // }

  // onWillPayCash(check: boolean) {
  //   this.willPayCash = check;
  //   this.updateUseEKYC();
  // }

  onHasEKYCResidenceWithJPAdress(check: boolean) {
    this.hasEKYCResidenceWithJPAdress = check;
    this.updateUseEKYC();
  }
  onHasEKYCResidenceNoJPAdress(check: boolean) {
    this.hasEKYCResidenceNoJPAdress = check;
    this.updateUseEKYC();
  }
  onHasEKYCPassport(check: boolean) {
    this.hasEKYCPassport = check;
    this.updateUseEKYC();
  }
  onHasEKYCJPDriveLicense(check: boolean) {
    this.hasEKYCJPDriveLicense = check;
    this.updateUseEKYC();
  }
  onHasEKYCJPMyNumber(check: boolean) {
    this.hasEKYCJPMyNumber = check;
    this.updateUseEKYC();
  }
  onHasOtherFlag(check: boolean) {
    this.hasEKYCOtherFlag = check;
    this.updateUseEKYC();
  }
  updateUseEKYC(): void {
    if (this.params === 'voice_esim') {
      this.useEKYC =
        this.eSIMInJapan &&
        this.willPayCredit && (
          this.hasEKYCResidenceWithJPAdress ||
          this.hasEKYCJPDriveLicense ||
          this.hasEKYCJPMyNumber
        );
    }
    else if (this.params === 'data_esim') {
      this.useEKYC =
        this.eSIMInJapan &&
        this.willPayCredit
    }
  }

  submitBasicInformation(formValue: any) {
    // const userTitleControl = this.title;
    const userNameControl = this.firstName;
    // const userTitleValue = userTitleControl ? userTitleControl.value : null;
    const userNameValue = userNameControl ? userNameControl.value : null;
    switch (this.isCheckMNP) {
      case false:
        if (!userNameValue || this.email.invalid ||
          (this.isMinorCheck && this.parent_email.invalid)) {
          console.log('userNameControl', userNameValue)
          this.checkFormField();
          const screenWidth = window.innerWidth;
          const snackBarConfig = screenWidth <= 480
            ? Constants.defaultSnackBarConfig('required-fields-error')
            : Constants.defaultSnackBarConfig('form-fields-error');
          this.snackbar.open('Please fill in the required information', 'OK',
            // Constants.defaultSnackBarConfig('form-fields-error')
            snackBarConfig
          );
          return;
        }
        break;
      case true:
        const dateOfbirth = this.mnpForm.get('birth_date').value;
        const mnpExpireDate = this.mnpForm.get('mnp_reservation_expire_date').value;
        if (dateOfbirth instanceof Date) {
          this.mnpForm.patchValue({
            birth_date: this.formatDate(dateOfbirth),
          });
        }
        if (mnpExpireDate instanceof Date) {
          this.mnpForm.patchValue({
            mnp_reservation_expire_date: this.formatDate(mnpExpireDate),
          });
        }
        if (!userNameValue || this.email.invalid || this.mnpForm.invalid ||
          (this.isMinorCheck && this.parent_email.invalid)) {
          this.checkFormField();
          this.checkMNPFormField();
          const screenWidth = window.innerWidth;
          const snackBarConfig = screenWidth <= 480
            ? Constants.defaultSnackBarConfig('required-fields-error')
            : Constants.defaultSnackBarConfig('form-fields-error');
          this.snackbar.open('Please fill in the required information', 'OK',
            // Constants.defaultSnackBarConfig('form-fields-error')
            snackBarConfig
          );
          return;
        }
        break;
      default:
        break;
    }

    if (this.eSIMInJapan && !this.willPayCash && !this.willPayCredit) {
      document.getElementById('esim_express_selection').scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'nearest',
      });
      const screenWidth = window.innerWidth;
      const snackBarConfig = screenWidth <= 480
        ? Constants.defaultSnackBarConfig('payment-fields-error')
        : Constants.defaultSnackBarConfig('form-fields-error');
      this.snackbar.open('Please select your preferred payment method.', 'OK',
        // Constants.defaultSnackBarConfig('form-fields-error')
        snackBarConfig
      );
      return;
    }

    this.longOrderService.setLoadingEvent = true;
    if (this.fullNameFG.valid) {
      this.submitDataForm.patchValue({
        // title: this.fullNameFG.get('title').value,
        // tslint:disable-next-line:max-line-length
        user_name: `${this.firstName.value}`,
      });
      console.log('fullNameFG Valid:', this.fullNameFG.valid);
    }
    switch (this.params) {
      case 'wifi,voicesim':
      case 'wifi,voice_esim':
        this.listVoiceWifiComponent.forEach(
          (component: WifiVoiceDataSimPlanComponent) => {
            const insuranceFG = this.listInsuranceOptionComponent.first
              .insuranceFG;
            const options = component.pocketWifiFG.get('options') as FormArray;
            if (options.length > 0) {
              options.clear();
            }
            if (
              this.listInsuranceOptionComponent.first.isPocketWifiOptional !==
              this.listInsuranceOptionComponent.first.notEnsurance
            ) {
              options.push(insuranceFG);
            }
            this.productsFormArrsubmitDataForm.push(
              component.promosetFormArray
            );
          }
        );
        break;
      case 'wifi':
        this.listPocketWifiComponent.forEach(
          (component: PocketWifiPlanComponent) => {
            const insuranceFG = this.listInsuranceOptionComponent.first
              .insuranceFG;
            const options = component.pocketWifiFG.get('options') as FormArray;
            if (options.length > 0) {
              options.clear();
            }
            if (
              this.listInsuranceOptionComponent.first.isPocketWifiOptional !==
              this.listInsuranceOptionComponent.first.notEnsurance
            ) {
              options.push(insuranceFG);
            }
            this.productsFormArrsubmitDataForm.push(
              component.pocketWifiFormArray
            );
          }
        );
        break;
      case 'home_wifi':
        // this.listHomeWifiComponent.forEach(
        //   (component: HomeWifiPlanComponent) => {
        //     this.productsFormArrsubmitDataForm.push(
        //       component.homeWifiFormArray
        //     );
        //   }
        // );
        break;
      case 'datasim':
      case 'voicesim':
      case 'data_esim':
      case 'voice_esim':
        this.listVoiceDataSimComponent.forEach(
          (component: VoiceDataSimPlanComponent) => {
            this.productsFormArrsubmitDataForm.push(
              component.voiceDataSimFormArray
            );
          }
        );
        break;
      default:
        break;
    }
    // if (this.isCheckMNP) {
    //   const dateOfbirth = this.mnpForm.get('birth_date').value;
    //   const mnpExpireDate = this.mnpForm.get('mnp_reservation_expire_date')
    //     .value;
    //   if (dateOfbirth instanceof Date) {
    //     this.mnpForm.patchValue({
    //       birth_date: this.formatDate(dateOfbirth),
    //     });
    //   }
    //   if (mnpExpireDate instanceof Date) {
    //     this.mnpForm.patchValue({
    //       mnp_reservation_expire_date: this.formatDate(mnpExpireDate),
    //     });
    //   }
    // }
    if (this.referral_code) {
      this.submitDataForm.addControl(
        'referral_code',
        this.fb.control(this.referral_code)
      );
    }
    if (this.coupon_code) {
      this.submitDataForm.addControl('coupon_code', this.fb.control(this.coupon_code));
    }
    this.submitDataForm.patchValue({ email: this.email.value.toLowerCase() })
    if (this.isMinorCheck) {
      this.submitDataForm.patchValue({ parent_email: this.parent_email.value.toLowerCase() });
    }
    if (this.nameProduct === 'HOME WIFI') {
      this.submitDataForm.get('location').patchValue({ current_location: 'IN JAPAN' });
    }
    const data = this.submitDataForm.getRawValue();
    data.use_ekyc = this.useEKYC;
    if (data.use_ekyc) {
      data.ekyc_info = {
        in_japan: this.eSIMInJapan,
        residence_with_jp_address: this.hasEKYCResidenceWithJPAdress,
        residence_no_jp_address: this.hasEKYCResidenceNoJPAdress,
        passport: this.hasEKYCPassport,
        jp_driver_license: this.hasEKYCJPDriveLicense,
        jp_my_number: this.hasEKYCJPMyNumber,
        other: this.hasEKYCOtherFlag,
        other_description: this.document_other_note
      }
    }
    else {
      data.ekyc_info = null;
    }
    console.log('Submitted data: ', data);
    this.longOrderService.submmitBasicInformation(data).subscribe(
      (res) => {
        this.longOrderService.setLoadingEvent = false;
        this.router.navigate(['complete-step']);
      },
      (err: HttpErrorResponse) => {
        this.longOrderService.setLoadingEvent = false;
        this.productsFormArrsubmitDataForm.clear();
        let error = err.error;
        if (err.error.error) {
          error = err.error.error;
        }
        this.snackbar.open(error.message, 'OK',
          Constants.defaultSnackBarConfig('form-fields-error'));
      }
    );
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  stringify(obj) {
    console.log(`stringify`, obj);
    JSON.stringify(obj);
  }
  private checkFormField() {
    // const userTitleControl = this.title;
    const userNameControl = this.firstName;
    // const userTitleValue = userTitleControl ? userTitleControl.value : null;
    const userNameValue = userNameControl ? userNameControl.value : null;
    // if (!userTitleValue) {
    //   this.title.markAllAsTouched();
    //   this.render2.addClass(this.titleFormField.nativeElement, 'form-field-invalid');
    // }
    if (!userNameValue) {
      this.firstName.markAllAsTouched();
      this.render2.addClass(this.firstNameFormField.nativeElement, 'form-field-invalid');
    }
    if (this.email.invalid) {
      this.email.markAllAsTouched();
      this.render2.addClass(this.emailFormField.nativeElement, 'form-field-invalid');
    }
    if (this.isMinorCheck && this.parent_email.invalid) {
      this.parent_email.markAllAsTouched();
      this.render2.addClass(this.parentEmailFormField.nativeElement, 'form-field-invalid');
    }
    // if (this.nationality.invalid) {
    //   this.nationality.markAllAsTouched();
    //   this.render2.addClass(this.nationalityFormField.nativeElement, 'form-field-invalid');
    // }
    return;
  }
  private checkMNPFormField() {
    if (this.name.invalid) {
      this.name.markAllAsTouched();
      this.render2.addClass(this.nameFormField.nativeElement, 'form-field-invalid');
    }
    if (this.kana.invalid) {
      this.kana.markAllAsTouched();
      this.render2.addClass(this.kanaFormField.nativeElement, 'form-field-invalid');
    }
    if (this.sex.invalid) {
      this.sex.markAllAsTouched();
      this.render2.addClass(this.sexFormField.nativeElement, 'form-field-invalid');
    }
    if (this.birth_date.invalid) {
      this.birth_date.markAllAsTouched();
      this.render2.addClass(this.birthDayFormField.nativeElement, 'form-field-invalid');
    }
    if (this.current_phone_number.invalid) {
      this.current_phone_number.markAllAsTouched();
      this.render2.addClass(this.currentPhoneFormField.nativeElement, 'form-field-invalid');
    }
    if (this.mnp_reservation_number.invalid) {
      this.mnp_reservation_number.markAllAsTouched();
      this.render2.addClass(this.reservationPhoneFormField.nativeElement, 'form-field-invalid');
    }
    if (this.mnp_reservation_expire_date.invalid) {
      this.mnp_reservation_expire_date.markAllAsTouched();
      this.render2.addClass(this.expiryDateFormField.nativeElement, 'form-field-invalid');
    }
    return;
  }
  private formatDate(value: Date) {
    const year = value.getFullYear();
    let month = '' + (value.getMonth() + 1);
    let day = '' + value.getDate();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  private convertTitleProduct(value: string): string {
    console.log(`BodyComponent::convertTitleProduct : value=${value}`);
    if (value === 'VOICE + DATA SIM CARD') {
      return 'voicesim';
    } else if (value === 'DATA ONLY SIM CARD') {
      return 'datasim';
    } else if (value === 'POCKET WIFI') {
      return 'wifi';
    } else if (value === 'VOICE+DATA SIM & POCKET WIFI') {
      return 'wifi,voicesim';
    } else if (value === 'VOICE+DATA eSIM & POCKET WIFI') {
      return 'wifi,voice_esim';
    } else if (value === 'VOICE + DATA eSIM') {
      return 'voice_esim';
    } else if (value === 'DATA ONLY eSIM') {
      return 'data_esim';
    } else if (value === 'VOICE+DATA eSIM & POCKET WIFI') {
      return 'wifi,voice_esim';
    } else if (value === 'HOME WIFI') {
      return 'home_wifi';
    } else {
      console.error(`BodyComponent::convertTitleProduct : unknown value=${value}`);
      return "";
    }
  }
  private get locationFG() {
    return this.submitDataForm.get('location') as FormGroup;
  }
  private get productsFormArrsubmitDataForm() {
    return this.submitDataForm.get('products') as FormArray;
  }
  //.con validator
  conValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return of(control.value).pipe(
        map(val => {
          const conPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.con$/;
          if (typeof val === 'string' && conPattern.test(val)) {
            return { 'conInvalid': true };
          }
          return null;
        })
      );
    };
  }
  private createFormControl() {
    this.firstName = this.fb.control('', [Validators.required, this.twoSpacesValidator()]);
    // this.middleName = this.fb.control('');
    // this.lastName = this.fb.control('');
    // this.title = this.fb.control('', Validators.required);
    this.email = this.fb.control('',
      [Validators.required, Validators.pattern(this.EMAIL_PATTERN)], [this.conValidator()]);
    this.parent_email = this.fb.control('',
      [Validators.required, Validators.pattern(this.EMAIL_PATTERN)], [this.conValidator()]);
    // this.nationality = this.fb.control('', Validators.required);
    this.otherAirportFC = this.fb.control('', Validators.required);
    this.product_type = this.fb.control('', Validators.required);
    this.quantity = this.fb.control(0, Validators.required);
    this.current_location = this.fb.control('', Validators.required);
    this.name = this.fb.control('', Validators.required);
    this.kana = this.fb.control('', [
      Validators.required,
      CustomValicators.kanaTextValicator({ isKana: true }),
    ]);
    this.sex = this.fb.control(null, Validators.required);
    this.birth_date = this.fb.control(Date, Validators.required);
    this.current_phone_number = this.fb.control('',
      [
        Validators.required,
        Validators.minLength(11)
      ]
    );
    this.mnp_reservation_number = this.fb.control('', [
      Validators.required,
      Validators.pattern(/^[0-9]*$/),
      Validators.minLength(10)
    ]);
    this.mnp_reservation_expire_date = this.fb.control(
      Date,
      Validators.required
    );
    this.mnp_incoming_provider = this.fb.control('');
    this.expected_date_of_arrival = this.fb.control('', Validators.required);
    this.arriving_airport = this.fb.control('', Validators.required);
  }

  private twoSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      if (value.trim() === '') {
        return null;
      }
      const spaceCount = (value.match(/\s+/g) || []).length;
      // console.log('Value:', value);
      // console.log('Space Count1:', spaceCount);
      return spaceCount < 2 ? { 'twoSpaces': true } : null;
    };
  }

  private createNewForm() {
    this.fullNameFG = this.fb.group({
      // title: this.title,
      firstName: this.firstName
      // middleName: this.middleName,
      // lastName: this.lastName,
    });
    this.submitDataForm = this.fb.group({
      user_name: this.fullNameFG.get('user_name'),
      // title: [null, Validators.required],
      email: this.email,
      // nationality: this.nationality,
      product_type: this.product_type,
      quantity: this.quantity,
      products: this.fb.array([], Validators.required),
      location: this.fb.group({
        current_location: this.current_location,
      }),
    });
    this.fullNameFG.valueChanges.subscribe(values => {
      this.submitDataForm.patchValue({
        user_name: values.firstName,
        // title: values.title
      });
    });

    this.mnpForm = this.fb.group({
      name: this.name,
      kana: this.kana,
      sex: this.sex,
      birth_date: this.birth_date,
      current_phone_number: this.current_phone_number,
      mnp_reservation_number: this.mnp_reservation_number,
      mnp_reservation_expire_date: this.mnp_reservation_expire_date,
      mnp_incoming_provider: this.mnp_incoming_provider,
    });
  }
  private couponCodeCheck() {
    this.sub.sink = this.activeRoute.queryParams.pipe(
      filter(data => data.coupon_code),
      pluck('coupon_code'),
      switchMap(coupon_code => this.longOrderService.CouponCodeInformation(coupon_code))
    ).subscribe(res => {
      if (res.data) {
        this.snackbar.dismiss();
        this.snackbar.open(
          'Coupon code is applied',
          'OK',
          Constants.defaultSnackBarConfig('success')
        );
        this.coupon_code = res.data.coupon_code;
      }
    },
      (err: HttpErrorResponse) => {
        if (err.error.message.message === 'Invalid coupon code') {
          this.snackbar.dismiss();
          this.snackbar.open(
            'Coupon code is not correct',
            'OK',
            Constants.defaultSnackBarConfig('error')
          );
        } else {
          return;
        }
      }
    );
  }
  private referralCodeCheck() {
    this.sub.sink = this.activeRoute.queryParams
      .pipe(
        filter((code) => code.referral_code),
        pluck('referral_code'),
        switchMap((referralCode) =>
          this.longOrderService.RefferalCodeDiscount(referralCode)
        )
      )
      .subscribe(
        (res) => {
          if (res.data) {
            this.snackbar.dismiss();
            this.snackbar.open(
              'Referral code is applied.',
              'OK',
              Constants.defaultSnackBarConfig('success')
            );
            this.referral_code = res.data.referral_code;
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error.message.message === 'Invalid referral code') {
            this.snackbar.dismiss();
            this.snackbar.open(
              'Referral code is not correct.',
              'OK',
              Constants.defaultSnackBarConfig('error')
            );
          } else {
            return;
          }
        }
      );
  }
}
