<div class="payment-method">
  <header>
    <app-header [step]="2"></app-header>
  </header>
  <div class="container">
    <div *ngIf="!((is_esim_v || is_esim_d) && complete_ekyc)" class="container__select__method">
      <h1 class="container__select__method__title">Select your payment method</h1>
      <div class="select__button">
        <button class="credit__card" [class.choosed]="isCreditCard" (click)="creditCardEvent()"
          [disabled]="disableCreditCard || isResponsiveFinish  || creditCardAvailble">
          CREDIT CARD
        </button>
        <button class="cash__payment" [class.choosed]="isCashPayment"
          [disabled]="disableCashPayment || isResponsiveFinish" (click)="CashPaymentContent()">
          CASH PAYMENT
        </button>
      </div>
      <div class="message">
        <p class="title">
          For Cash payment, a deposit is required and it is used for your final two monthly payments.
          When the contract is terminated, the rest of the amount will be refunded to your Japanese bank accout or PayPal.
        </p>
        <ul>
          <li>Voice SIM : 20,000 JPY</li>
          <li>Data SIM / Home WiFi / Pocket WiFi : Amount equal to 2 months of payments.</li>
        </ul>
        <div class="message__deposit-description" *ngIf="isCashPayment">
          <mat-accordion>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="icon--help">help</mat-icon>
                  Why must I pay for the deposits?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="deposit-content">
                <h4 class="deposit-content__title">How our billing works:</h4>
                <p class="deposit-content__info">All of our plans are post-paid plans. Therefore, we will send you an
                  invoice one month after the usage month, at the end of month.<br>
                  Then, you will go to the convenience store between the 1st and 10th of the following month.<br>
                  To make it more simple, below is an example of the billing cycle for the Smart Pit Number payment
                  method (Cash Payment):
                </p>
                <table class="deposit-content__table">
                  <tr>
                    <td>Usage Month</td>
                    <td>Invoicing period</td>
                    <td>Payment period</td>
                  </tr>
                  <tr>
                    <td>May</td>
                    <td>End of June</td>
                    <td class="especial-td">Jul 1 -10</td>
                  </tr>
                  <tr>
                    <td>June</td>
                    <td>End of July</td>
                    <td class="especial-td">Aug 1 - 10</td>
                  </tr>
                  <tr>
                    <td>July</td>
                    <td>End of August</td>
                    <td class="especial-td">Sep 1 - 10</td>
                  </tr>
                </table>
                <p class="deposit-content__info info">So, according to the billing cycle, there will be two more bills
                  due after you cancel the contract.<br>
                  We ask for the deposit to cover these two bills after you cancel the contract.
                </p>
                <h4 class="deposit-content__title">The refund of the deposit:</h4>
                <p class="deposit-content__info">※ For Voice+data SIM: You will get a partial refund for the
                  deposit.<br>
                  The remaining of the deposit will be calculated and refunded to the provided bank account 1.5 months
                  after your contract is cancelled. Depending on the usage fees of the last 2 months, you might not
                  receive a refund from us.<br>
                  ※ For Pocket WiFi: You will not get a refund for the deposit since it will be used to pay for the last
                  2 months of use.</p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="container__sorry--screen" *ngIf="isSorryScreen">
      <div class="info">
        <div class="info__img">
          <img src="../../../../assets/images/sakura-sorry.svg" alt="sakura sorry" class="getemail">
        </div>
        <div class="contents__body">
          <h1>Sorry...</h1>
          <p>It is not possible to apply for the cash payment method if you are still outside of Japan.</p>
          <p>Please come back again after you have arrived in Japan.</p>
          <p>You can visit this page again using the same link sent to your email address.</p>
        </div>
      </div>
      <div class="document--info">
        <mat-icon class="icon--help">help</mat-icon>
        <p class="document--info__title">What are the required official documents?</p>
      </div>
    </div>

    <div class="container__pickup__location" *ngIf="!is_esim_d_credit && !((is_esim_v || is_esim_d) && complete_ekyc) && (isCreditCard || isCashPayment) && typeContract === 'home_wifi'">
      <h1 class="container__select__method__title">Pick up location</h1>
    </div>
    <div class="container__pickup__location" *ngIf="!is_esim_d_credit && !((is_esim_v || is_esim_d) && complete_ekyc) && (isCreditCard || isCashPayment) && typeContract !== 'home_wifi'">
      <h1 class="container__select__method__title">Select your pick up location</h1>
      <div *ngIf="!dataJson.airport" class="message">
        Shipping to airport counters usually takes up to 3 days. Therefore, the "Airport" pick up option is not
        available at the moment.
        Please choose either "Office" pick up or "Delivery" to continue with your order.
      </div>
      <div class="select__button">
        <button class=" airport" [class.choosed]="isAirportAll" [disabled]="!dataJson.airport"
          (click)="showAirportCom()">
          {{dataJson.airport_candidates.NRT_KIX && dataJson.airport_candidates.HND &&
          dataJson.airport_candidates.other ? ' Airport' :
          dataJson.airport_candidates.NRT_KIX && dataJson.airport_candidates.HND ?
          'Airport(NRT, HND, KIX)' : 'Airport(NRT, KIX)' }}
        </button>
        <button class="office" [class.choosed]="isOffice" [disabled]="!dataJson.office" (click)="showOfficeCom()">
          office
        </button>
        <button class=" delivery" [disabled]="!dataJson.delivery" [class.choosed]="isDelivery"
          (click)="showDeliveryCom()">
          free delivery
        </button>
      </div>
    </div>

    <ng-container *ngIf="isAirportAll">
      <app-airport-content [dataJson]="dataJson" [arrivalDateTime]="arrivalDateTime"
        [subscription_type]="subscription_type" [payment_type]="payment_type"
        [pick_up_method]="pick_up_method"></app-airport-content>
    </ng-container>

    <ng-container *ngIf="isOffice">
      <app-office-content [dataJson]="dataJson" [arrivalDateTime]="arrivalDateTime"
        [subscription_type]="subscription_type" [payment_type]="payment_type"
        [pick_up_method]="pick_up_method"></app-office-content>
    </ng-container>

    <ng-container *ngIf="isDelivery">
      <app-delivery-content [dataJson]="dataJson" [arrivalDateTime]="arrivalDateTime"
        [subscription_type]="subscription_type" [payment_type]="payment_type"
        [pick_up_method]="pick_up_method"></app-delivery-content>
    </ng-container>
    
    <ng-container *ngIf="use_ekyc && (is_esim_v || is_esim_d)">
      <app-ekyc-content [quantity]="quantity" [subscription_type]="subscription_type" [payment_type]="payment_type"
        [pick_up_method]="pick_up_method" [contract_code]="contract_code" [customer_full_name]="customer_full_name" [customer_email]="customer_email"></app-ekyc-content>
    </ng-container>

    <ng-container *ngIf="is_esim_d_credit">
      <app-upload-only-content [quantity]="quantity" [subscription_type]="subscription_type" [payment_type]="payment_type"
        [pick_up_method]="pick_up_method"></app-upload-only-content>
    </ng-container>

  </div>
</div>