import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Wifi } from 'src/app/types/wifi';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-pocket-wifi-plan',
  templateUrl: './pocket-wifi-plan.component.html',
  styleUrls: ['./pocket-wifi-plan.component.scss'],
})
export class PocketWifiPlanComponent implements OnInit {
  isPocketWifi: string;
  selectPocketWifi: boolean;
  isPocketWifiOptional: string;
  notEnsurance = `I DON'T NEED INSURANCE`;
  pocketWifiFG: FormGroup;
  pocketWifiFormArray: FormArray;
  @Input() pocketWifiPlan: Wifi;
  @Input() index = 0;
  @Input() quantity: number;
  // @Output() selectTitleWifiEvent = new EventEmitter<string>();
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.selectPocketWifi = false;
    this.createFormGroup();
  }
  selectPocketWifiPlan(value: string) {
    // this.selectTitleWifiEvent.emit(value);
    const found = this.pocketWifiPlan.plans.find(
      (item) => item.plan_title === value
    );
    if (!found.is_enable) {
      return; // don't do anything
    }
    this.isPocketWifi = value;
    this.selectPocketWifi = true;
    this.pocketWifiFG.patchValue({
      category_type_id: this.pocketWifiPlan.category_id,
      category_type: this.pocketWifiPlan.category_title,
      product_type_id: found.product_type_id,
      product_type: found.product_type,
    });
  }

  private createFormGroup() {
    this.pocketWifiFG = this.fb.group({
      category_type_id:  [null, Validators.required],
      category_type:  [null, Validators.required],
      product_type_id:  [null, Validators.required],
      product_type:  [null, Validators.required],
      options: this.fb.array([]),
    });
    this.pocketWifiFormArray = this.fb.array([this.pocketWifiFG]);
  }
}
