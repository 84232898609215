import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {
  CommonModule,
  DatePipe,
  DecimalPipe,
  TitleCasePipe,
} from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { BodyComponent } from "./components/basic-information/body.component";
import { CalendarComponent } from "./components/basic-information/calendar/calendar.component";
import { HttpClientModule } from "@angular/common/http";
import { VoiceDataSimPlanComponent } from "./components/basic-information/voice-data-sim-plan/voice-data-sim-plan.component";
import { PocketWifiPlanComponent } from "./components/basic-information/pocket-wifi-plan/pocket-wifi-plan.component";
import { WifiVoiceDataSimPlanComponent } from "./components/basic-information/wifi-voice-data-sim-plan/wifi-voice-data-sim-plan.component";
import { CompleteFirstStepComponent } from "./components/basic-information/complete-first-step/complete-first-step.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataVoiceSimComponent } from "./components/shared/data-voice-sim/data-voice-sim.component";
import { DataWifiComponent } from "./components/shared/data-wifi/data-wifi.component";
import { PaymentMethodComponent } from "./components/payment-method-document/payment-method/payment-method.component";
import { HeaderComponent } from "./components/shared/header/header.component";
import { EkycContentComponent } from "./components/payment-method-document/ekyc-content/ekyc-content.component";
import { AirportContentComponent } from "./components/payment-method-document/airport-content/airport-content.component";
import { OfficeContentComponent } from "./components/payment-method-document/office-content/office-content.component";
import { DeliveryContentComponent } from "./components/payment-method-document/delivery-content/delivery-content.component";
import { UploadDocumentComponent } from "./components/shared/upload-document/upload-document.component";
import { ResidenceComponent } from "./components/payment-method-document/delivery-content/residence/residence.component";
import { HotelComponent } from "./components/payment-method-document/delivery-content/hotel/hotel.component";
import { PostOfficeComponent } from "./components/payment-method-document/delivery-content/post-office/post-office.component";
import { OtherComponent } from "./components/payment-method-document/delivery-content/other/other.component";
import { TermsConditionsComponent } from "./components/terms-and-conditions/terms-conditions/terms-conditions.component";
import { ConfirmationAndPaymentComponent } from "./components/confirmation-and-payment/confirmation-and-payment.component";
// tslint:disable-next-line:max-line-length
import { RegisteredInformationComponent } from "./components/confirmation-and-payment/registered-information/registered-information.component";
import { RegisterNewCardComponent } from "./components/confirmation-and-payment/register-new-card/register-new-card.component";
import { FinishComponent } from "./components/confirmation-and-payment/finish/finish.component";
// tslint:disable-next-line:max-line-length
import { ProductOrderedComponent } from "./components/confirmation-and-payment/registered-information/product-ordered/product-ordered.component";
import { InsuranceOptionComponent } from "./components/basic-information/insurance-option/insurance-option.component";
import { DialogMessageComponent } from "./components/shared/dialog-message/dialog-message.component";
import { DepositComponent } from "./components/confirmation-and-payment/registered-information/deposit/deposit.component";
import { SpinnerComponent } from "./components/shared/spinner/spinner.component";
import { UploadSpinnerComponent } from "./components/shared/upload-spinner/upload-spinner.component";
import { CardBlockedComponent } from "./components/confirmation-and-payment/card-blocked/card-blocked.component";
import { RequiredComponent } from "./components/shared/required/required.component";
import { TopUpStep1Component } from "./components/top-up-campaign/top-up-step1/top-up-step1.component";
import { TopUpCampaignModule } from "./components/top-up-campaign/top-up-campaign.module";
import { HomeWifiPlanComponent } from './components/basic-information/home-wifi-plan/home-wifi-plan.component';
import { UploadOnlyContentComponent } from './components/payment-method-document/upload-only-content/upload-only-content.component';
import { FullNameDialogComponent } from "./components/basic-information/full-name-dialog/full-name-dialog.component";
import { MatDialogModule } from '@angular/material/dialog';
import { LongOrderService } from "./services/long-order.service";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BodyComponent,
    CalendarComponent,
    VoiceDataSimPlanComponent,
    PocketWifiPlanComponent,
    WifiVoiceDataSimPlanComponent,
    CompleteFirstStepComponent,
    DataVoiceSimComponent,
    DataWifiComponent,
    PaymentMethodComponent,
    EkycContentComponent,
    AirportContentComponent,
    OfficeContentComponent,
    DeliveryContentComponent,
    UploadDocumentComponent,
    ResidenceComponent,
    HotelComponent,
    PostOfficeComponent,
    OtherComponent,
    TermsConditionsComponent,
    ConfirmationAndPaymentComponent,
    RegisteredInformationComponent,
    RegisterNewCardComponent,
    FinishComponent,
    ProductOrderedComponent,
    InsuranceOptionComponent,
    DialogMessageComponent,
    DepositComponent,
    SpinnerComponent,
    UploadSpinnerComponent,
    CardBlockedComponent,
    RequiredComponent,
    HomeWifiPlanComponent,
    UploadOnlyContentComponent,
    FullNameDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    MatProgressSpinnerModule,
    OverlayModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    BrowserModule,
  ],
  providers: [
    TitleCasePipe,
    DecimalPipe,
    DatePipe,
    { provide: "windowObject", useValue: window },
    LongOrderService,
  ],
  entryComponents: [
    MatSpinner,
    DialogMessageComponent,
    FullNameDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
