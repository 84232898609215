import { AfterContentChecked, Component, OnInit, Output, ViewEncapsulation,
  EventEmitter, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Add_Remove_Css } from 'src/app/Utils/utils';

@Component({
  selector: 'app-post-office',
  templateUrl: './post-office.component.html',
  styleUrls: ['./post-office.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostOfficeComponent implements OnInit, AfterContentChecked {
  postOfficeFG: FormGroup;
  post_office_branch_name: FormControl;
  post_office_branch_address: FormControl;
  preffered_date_of_pick_up: FormControl;
  estimated_time_of_arrival: FormControl;
  recipient: FormControl;
  note: FormControl;
  estimateTimeArrival = ['~ 6:00', '6:00 ~ 7:00', '7:00 ~ 8:00', '8:00 ~ 9:00', '9:00 ~ 10:00', '10:00 ~ 11:00', '11:00 ~ 12:00',
    '12:00 ~ 13:00', '13:00 ~ 14:00', '14:00 ~ 15:00', '15:00 ~ 16:00', '16:00 ~ 17:00', '17:00 ~ 18:00', '18:00 ~ 19:00',
    '19:00 ~ 20:00', '20:00 ~ 21:00', '21:00 ~ 22:00', '22:00 ~ 23:00', '23:00 ~ 0:00'];
  @Input() minDate: Date;
  @Output() postOfficeFormValid = new EventEmitter<boolean>();
  @ViewChild('postOfficeBranchNameRef', {static: false, read: ElementRef}) postOfficeBranchNameRef: ElementRef;
  @ViewChild('postOfficeAddressRef', {static: false, read: ElementRef}) postOfficeAddressRef: ElementRef;
  @ViewChild('postOfficePreferrDateRef', {static: false, read: ElementRef}) postOfficePreferrDateRef: ElementRef;
  @ViewChild('postOfficeRecipientRef', {static: false, read: ElementRef}) postOfficeRecipientRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.createFormControl();
    this.postOfficeFormGroup();
  }
  postOfficeBranchNameChange(event: any) {
    Add_Remove_Css('remove', this.postOfficeBranchNameRef, this.renderer);
  }
  postOfficeAddressChange(event: any) {
    Add_Remove_Css('remove', this.postOfficeAddressRef, this.renderer);
  }
  postOfficeDatePickerChange(event: any) {
    Add_Remove_Css('remove', this.postOfficePreferrDateRef, this.renderer);
  }
  postOfficeRecipientChange(event: any) {
    Add_Remove_Css('remove', this.postOfficeRecipientRef, this.renderer);
  }
  ngAfterContentChecked() {
    if (this.postOfficeFG.valid) {
      this.postOfficeFormValid.emit(true);
    } else {
      this.postOfficeFormValid.emit(false);
    }
  }
  private createFormControl() {
    this.post_office_branch_name = this.fb.control('', Validators.required);
    this.post_office_branch_address = this.fb.control('', Validators.required);
    this.preffered_date_of_pick_up = this.fb.control('', Validators.required);
    this.estimated_time_of_arrival = this.fb.control('');
    this.recipient = this.fb.control('', Validators.required);
    this.note = this.fb.control('');
  }
  private postOfficeFormGroup() {
    this.postOfficeFG = this.fb.group({
      post_office_branch_name: this.post_office_branch_name,
      post_office_branch_address: this.post_office_branch_address,
      preffered_date_of_pick_up: this.preffered_date_of_pick_up,
      estimated_time_of_arrival: this.estimated_time_of_arrival,
      recipient: this.recipient,
      note: this.note
    });
  }
}
