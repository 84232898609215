<ng-container *ngIf="!isSmallScreen" [ngTemplateOutlet]="largeScreen"></ng-container>
<ng-container *ngIf="isSmallScreen" [ngTemplateOutlet]="smallScreen"></ng-container>



<ng-template #largeScreen>
  <ng-container *ngFor="let product of orderInformation.data.products">
    <table class="products__plan" *ngIf="typeProduct === 'voice_wifi' || typeProduct === 'voice_esim_wifi'">
      <tr>
        <td rowspan="2" class="title">plan</td>
        <td>{{product[0].category_type}}</td>
        <td class="plan--title">{{product[0].plan_title}}</td>
        <td class="end--td">¥{{product[0].monthly_fee |number}}/month + VAT</td>
      </tr>
      <tr>
        <td>{{product[1].category_type}}</td>
        <td class="plan--title">{{product[1].plan_title}}</td>
        <td class="end--td">¥{{product[1].monthly_fee |number}}/month + VAT</td>
      </tr>
      <tr *ngIf="product[1].options.length !== 0" class="option">
        <td rowspan="2" class="title">option</td>
        <td colspan="2">{{product[1].options.category_type}}</td>
        <td class="end--td">¥{{product[1].options.monthly_fee |number}}/month + VAT</td>
      </tr>
    </table>
    <table class="products__plan" *ngIf="typeProduct === 'wifi'">
      <tr>
        <td class="title">plan</td>
        <td>{{product[0].category_type}}</td>
        <td class="plan--title">{{product[0].plan_title}}</td>
        <td class="end--td">¥{{product[0].monthly_fee |number}}/month + VAT</td>
      </tr>
      <tr *ngIf="product[0].options.length !== 0" class="option">
        <td class="title ">option</td>
        <td colspan="2">{{product[0].options.category_type}}</td>
        <td class="end--td">¥{{product[0].options.monthly_fee |number}}/month + VAT</td>
      </tr>
    </table>
    <table class="products__plan" *ngIf="typeProduct === 'voice' || typeProduct === 'data' || typeProduct === 'home_wifi'">
      <tr>
        <td rowspan="2" class="title">plan</td>
        <td>{{product[0].category_type}}</td>
        <td class="plan--title">{{product[0].plan_title}}</td>
        <td class="end--td">¥{{product[0].monthly_fee |number}}/month + VAT</td>
      </tr>
    </table>
  </ng-container>
</ng-template>

<ng-template #smallScreen>
  <ng-container *ngFor="let product of orderInformation.data.products">
    <table class="products__plan" *ngIf="typeProduct === 'voice_wifi' || typeProduct === 'voice_esim_wifi'">
      <tr>
        <td colspan="2" class="title--mobile">{{product[0].category_type}}</td>
      </tr>
      <tr>
        <td class="td--mobiles">{{product[0].plan_title}}</td>
        <td class="td--mobiles">¥{{product[0].monthly_fee |number}}/month + VAT</td>
      </tr>
      <tr>
        <td colspan="2" class="title--mobile">{{product[1].category_type}}</td>
      </tr>
      <tr>
        <td class="td--mobiles">{{product[1].plan_title}}</td>
        <td class="td--mobiles">¥{{product[1].monthly_fee |number}}/month + VAT</td>
      </tr>
      <tr *ngIf="product[1].options.length !== 0">
        <td colspan="2" class="title--mobile">{{product[1].options.category_type}}</td>
      </tr>
      <tr *ngIf="product[1].options.length !== 0">
        <td class="td--mobiles"></td>
        <td colspan="2" class="td--mobiles">¥{{product[1].options.monthly_fee |number}}/month + VAT</td>
      </tr>
    </table>
    <table class="products__plan" *ngIf="typeProduct === 'wifi'">
      <tr>
        <td colspan="2" class="title--mobile">{{product[0].category_type}}</td>
      </tr>
      <tr>
        <td class="td--mobiles">{{product[0].plan_title}}</td>
        <td class="td--mobiles">¥{{product[0].monthly_fee |number}}/month + VAT</td>
      </tr>
      <tr *ngIf="product[0].options.length !== 0">
        <td colspan="2" class="title--mobile">{{product[0].options.category_type}}</td>
      </tr>
      <tr *ngIf="product[0].options.length !== 0">
        <td class="td--mobiles"></td>
        <td colspan="2" class="td--mobiles">¥{{product[0].options.monthly_fee |number}}/month + VAT</td>
      </tr>
    </table>
    <table class="products__plan" *ngIf="typeProduct === 'voice' || typeProduct === 'data' || typeProduct === 'home_wifi'">
      <tr>
        <td colspan="2" class="title--mobile">{{product[0].category_type}}</td>
      </tr>
      <tr>
        <td class="td--mobiles">{{product[0].plan_title}}</td>
        <td class="td--mobiles">¥{{product[0].monthly_fee |number}}/month + VAT</td>
      </tr>
    </table>
  </ng-container>
</ng-template>