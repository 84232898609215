<div class="header">
  <div class="header__container">
    <div class="header__container__nav">
      <div class="header__container__nav__logo" *ngIf="!isMobile">
        <img
          src="../../../assets/images/logo-sakura.svg"
          alt="sakura logo"
          class="logo__img"
        />
        <!-- <div class="header__container__nav__logo__mobile--stepper" *ngIf="isMobile">
          <div class="header__container__nav__logo__mobile--stepper--step1"></div>
          <div class="header__container__nav__logo__mobile--stepper--step2"></div>
          <div class="header__container__nav__logo__mobile--stepper--step3"></div>
          <div class="header__container__nav__logo__mobile--stepper--step4"></div>
        </div> -->
      </div>
      <div class="header__container__nav__mobile--logo" *ngIf="isMobile">
        <img
          src="../../../assets/images/logo-sakura.svg"
          alt="sakura logo"
          class="logo__img"
        />
        <div
          class="header__container__nav__mobile--logo__mobile--stepper"
          *ngIf="isMobile"
        >
          <div class="mobile--step1"></div>
          <div class="mobile--step2"></div>
          <div class="mobile--step3"></div>
          <div class="mobile--step4"></div>
        </div>
      </div>
      <div class="header__container__nav__mobile--title" *ngIf="isMobile">
        <h3 *ngIf="step1">Basic Information</h3>
        <h3 *ngIf="step2">Payment Method, Pick Up, and Documents</h3>
        <h3 *ngIf="step3">Terms of Agreement</h3>
        <h3 *ngIf="step4">Confirmation and Payment</h3>
      </div>
      <div class="header__container__nav__stepper" *ngIf="!isMobile">
        <div class="header__container__nav__stepper--step1"></div>
        <ng-container *ngIf="step1">
          <div class="header__container__nav__stepper--title">
            <span class="title">Basic Information</span>
          </div>
        </ng-container>
        <div class="header__container__nav__stepper--step2"></div>
        <ng-container *ngIf="step2">
          <div class="header__container__nav__stepper--title">
            <span class="title">Payment Method, Pick Up, and Documents</span>
          </div>
        </ng-container>
        <div class="header__container__nav__stepper--step3"></div>
        <ng-container *ngIf="step3">
          <div class="header__container__nav__stepper--title">
            <span class="title">Terms of Agreement</span>
          </div>
        </ng-container>
        <div class="header__container__nav__stepper--step4"></div>
        <ng-container *ngIf="step4">
          <div class="header__container__nav__stepper--title">
            <span class="title">Confirmation and Payment</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="header__title">
    <h1>Monthly Plans For Long-Term Users in Japan</h1>
    <h2>
      ※These are recurring monthly contract plans for people living in Japan who
      wish to use the service for an extended period.
    </h2>

    <h2>
      For travel purpose products, please click here:
      <a href="https://www.sakuramobile.jp/order-short-term-plans/">(link)</a>
    </h2>
  </div>
</div>
