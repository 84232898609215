<ng-container *ngIf="wifiOption">
  <div class="insurance">
    <h3 class="monthly--data-title">POCKET WIFI OPTIONAL</h3>
    <div class="data--monthly--insurance">
      <div
        class="insurance"
        *ngFor="let item of wifiOption[0].options[0].plans"
        (click)="selectPocketWifiOptionalPlan(item.plan_title)"
        [ngClass]="{
          select__product: isPocketWifiOptional === item.plan_title
        }"
      >
        <p class="insurance__amount--insurance">{{ item.plan_title }}</p>
        <p class="insurance__price">{{ item.monthly_fee | number }} JPY/MO</p>
        <p class="insurance__price__taxincl">({{ item.monthly_fee * 1.1 | number }} JPY/MO, tax incl.)</p>
      </div>
    </div>
    <div
      class="not--insurance"
      (click)="selectPocketWifiOptionalPlan(notEnsurance)"
      [ngClass]="{
        select__product: isPocketWifiOptional === notEnsurance
      }"
    >
      {{ notEnsurance }}
    </div>
  </div>
</ng-container>
