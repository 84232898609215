import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Constants } from 'src/app/constants/constants';
import { 
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/myFormatDate';
import { LongOrderService } from 'src/app/services/long-order.service';
import { UploadDocumentComponent } from '../../shared/upload-document/upload-document.component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { SelectPayMethod } from 'src/app/types/select-paymethod';
import { Router } from '@angular/router';
import { Add_Remove_Css, ShowSnackBar } from 'src/app/Utils/utils';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-upload-only-content',
  templateUrl: './upload-only-content.component.html',
  styleUrls: ['./upload-only-content.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UploadOnlyContentComponent implements OnInit {
  moment = _rollupMoment || _moment;
  selectDate: Date;
  // isSubmitButton = false;
  ekyc_information: FormGroup;
  pick_up_date: FormControl;
  device_eid_0: FormControl;
  device_eid_1: FormControl;
  device_eid_2: FormControl;
  device_eid_3: FormControl;
  device_eid_4: FormControl;
  note: FormControl;

  @Input() quantity: number;
  @Input() subscription_type: string;
  @Input() payment_type: string;
  @Input() pick_up_method: string;

  private currentDate: Date;
  private currentHours: number;
  @ViewChild('pickupDateFF', {static: false, read: ElementRef}) pickupDateFF: ElementRef;
  @ViewChild(UploadDocumentComponent, { static: false })
  uploadComponent: UploadDocumentComponent;

  constructor(
    private longorderService: LongOrderService,
    private fb: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log('Testing show case documents selected::', this.subscription_type, this.payment_type, this.pick_up_method);
    this.currentDate = new Date();
    this.currentHours = this.currentDate.getHours();
    this.createFormControl();
    this.createEkycFormGroup();
    this.selectEkycChange('');
  }
  selectEkycChange(value: string) {
    // calculate current JST
    const dt_local = new Date();
    const dt_as_jst = new Date();
    // tslint:disable-next-line:max-line-length
    dt_as_jst.setTime(dt_local.getTime() + (dt_local.getTimezoneOffset() * 60 * 1000) + (9 * 60 * 60 * 1000) /* Offset for Japanese Standard Time */);
    console.log('JST=', dt_as_jst);

    this.selectDate = new Date(dt_as_jst);
    // this.ekyc_information.get('pick_up_date').enable();
  }
  checkDatePickerEvent(event: any) {
    console.log('checkDatePickerEvent',this.pick_up_date);
    if (this.pick_up_date.valid) {
      Add_Remove_Css('remove', this.pickupDateFF, this.renderer);
    }
  }
  clickabled(event: any) {
    console.log(event);
  }

  deviceEIDChange(event: any) {
  }

  submitEkyc() {
    console.log('submit EKYC clicked');
    if (this.ekyc_information.invalid) {
      this.checkEkycFormField();
      return;
    } else {
      if (
        (this.uploadComponent.student_discount_checkbox &&
          this.uploadComponent.processUploadStdID === false)
      ) {
        this.uploadComponent.checkUploadDocument();
        return;
      }
    }
    if (this.subscription_type === 'Data only eSIM'){
      console.log('submit EKYC Data only :: 001');
      if (this.uploadComponent.selectDocument.invalid) {
        console.log('submit EKYC Data only :: 002');
        this.checkSelectDocument();
        return;
      }
      else {
        // console.log('submit EKYC Data only :: 002');
        if (
          (this.uploadComponent.showUploadFront &&
            this.uploadComponent.processUploadFront === false) ||
            (this.uploadComponent.showUploadBack &&
              this.uploadComponent.processUploadBack === false) ||
          ( this.uploadComponent.isAddMoreDocument &&
            this.uploadComponent.processUploadAddMoreDoc === false) ||
          (this.uploadComponent.student_discount_checkbox &&
            this.uploadComponent.processUploadStdID === false)
        ) {
          console.log('submit EKYC Data only :: 003');
          this.uploadComponent.checkUploadDocument();
          return;
        }
        console.log('submit EKYC Data only :: 004');
      }
    }
    const dateMoment: moment.Moment = this.pick_up_date.value;
    this.ekyc_information.patchValue({
      pick_up_date: dateMoment.format('LL')
    });
    this.longorderService.setEkycInformation = this.ekyc_information.getRawValue();
    this.router.navigate(['/step3']);
  }
  private isEIDInputValid(): boolean {
    return false;
  }

  private checkEkycFormField() {
    const screenWidth = window.innerWidth;
    const snackBarConfig = screenWidth <= 480 
    ? Constants.defaultSnackBarConfig('number-fields-error')
    : Constants.defaultSnackBarConfig('form-fields-error');

    if ( this.pick_up_date.invalid) {
      console.log(`Pick up date : ${this.pick_up_date}`);
      this.pick_up_date.markAllAsTouched();
      Add_Remove_Css('add', this.pickupDateFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (
      this.device_eid_0.invalid ||
      this.device_eid_1.invalid ||
      this.device_eid_2.invalid||
      this.device_eid_3.invalid||
      this.device_eid_4.invalid
    ) {
      this.snackbar.open('When specifying the EID, please ensure it is entered as a 32-digit number beginning with "89"', 'OK',
      snackBarConfig
        // Constants.defaultSnackBarConfig('number-fields-error')
      );
    }
  }

  private checkSelectDocument() {
    if (this.uploadComponent.selectDocument.invalid) {
      this.uploadComponent.selectDocument.markAllAsTouched();
      Add_Remove_Css('add', this.uploadComponent.selectDocumentFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
  }

  startsWith89Validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && !value.startsWith('89')) {
        return {'startsWith89': true };
      }
      return null;
    }
  }

  private createFormControl() {
    this.pick_up_date = this.fb.control('', Validators.required); // { value: '', disabled: true }
    console.log(`createFormControl : ${this.quantity}`);
    this.device_eid_0 = this.fb.control('', [Validators.minLength(32), Validators.maxLength(32), this.startsWith89Validator()]);
    this.device_eid_1 = this.fb.control('', [Validators.minLength(32), Validators.maxLength(32), this.startsWith89Validator()]);
    this.device_eid_2 = this.fb.control('', [Validators.minLength(32), Validators.maxLength(32), this.startsWith89Validator()]);
    this.device_eid_3 = this.fb.control('', [Validators.minLength(32), Validators.maxLength(32), this.startsWith89Validator()]);
    this.device_eid_4 = this.fb.control('', [Validators.minLength(32), Validators.maxLength(32), this.startsWith89Validator()]);
    this.note = this.fb.control('');
  }
  private createEkycFormGroup() {
    this.ekyc_information = this.fb.group({
      pick_up_date: this.pick_up_date,
      device_eid_0: this.device_eid_0,
      device_eid_1: this.device_eid_1,
      device_eid_2: this.device_eid_2,
      device_eid_3: this.device_eid_3,
      device_eid_4: this.device_eid_4,
      note: this.note
    });
  }
}
