import {
  Component,
  OnInit,
  Output,
  ViewEncapsulation,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { LongOrderService } from 'src/app/services/long-order.service';
import { OrderInformation } from 'src/app/types/order-information';
import { Add_Remove_Css, ShowSnackBar } from 'src/app/Utils/utils';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadDocumentComponent implements OnInit, OnDestroy {
  private subOrderInformation = new SubSink();
  private subContractHash = new SubSink();
  private contractHash: string;
  selectDocument: FormControl;
  requiredErrorUploadPP: boolean;
  requiredUploadFront: boolean;
  requiredUploadBack: boolean;
  requiredUploadStd: boolean;
  documentTypes = [
    'Residence card （在留カード）',
    'Passport（パスポート）',
    `Japanese Driver’s license （運転免許証）`,
    'Driving history certificate（運転経歴証明書）',
    'Personal number card（個人番号カード）',
    'Insurance card（健康保険者証・国民健康保険者証）',
    'Special permanent resident certificate（特別永住者証明書）',
    // 'Alien registration certificate',
    // 'Basic Resident Register Card',
    'Identification card (by the Ministry of Foreign Affairs of Japan) 外務省の発行する「身分証明票」',
  ];
  name_document: string;
  fileNameUpload: string;
  fileNameUploadSTID: string;
  student_discount_checkbox: boolean;
  processUploadStdID: boolean;
  // add more document
  fileNameUploadFile1: string;
  isAddMoreDocument: boolean;
  enableAddMoreDocument: boolean;
  processUploadAddMoreDoc: boolean;
  // check passport type document is
  showUploadFront: boolean;
  // upload front image
  titleUploadFront: string;
  fileNameUploadFront: string;
  processUploadFront = false;
  // upload back image
  titleUploadBack: string;
  fileNameUploadBack: string;
  processUploadBack = false;
  showUploadBack: boolean;
  orderInformation: OrderInformation;
  loading$ = this.longOderService.loading$;

  subcriptionType$: Observable<string> = this.longOderService.subcriptionType$;
  isDelivery$: Observable<boolean> = this.longOderService.isDelivery$;

  passport_pattern1 = false;
  passport_pattern2 = false;
  residence_card = false;
  driving_history_certificate = false;
  driver_license = false;
  special_pernament = false;
  insurance_card = false;
  personal_number_card = false;
  indentification_card = false;
  @Input() subscription_type: string;
  @Input() payment_type: string;
  @Input() pick_up_method: string;
  @Input() for_v_esim: boolean;

  @Output() typeDocumentChange = new EventEmitter();

  @ViewChild('selectDocumentFF', { static: false, read: ElementRef }) selectDocumentFF: ElementRef;
  @ViewChild('uploadFrontFF', { static: false, read: ElementRef }) uploadFrontFF: ElementRef;
  @ViewChild('uploadBackFF', { static: false, read: ElementRef }) uploadBackFF: ElementRef;
  @ViewChild('uploadStdFF', { static: false, read: ElementRef }) uploadStdFF: ElementRef;
  @ViewChild('uploadAdditionalFF', { static: false, read: ElementRef }) uploadAdditionalFF: ElementRef;
  @ViewChild('front', { static: false }) frontEl: ElementRef;
  @ViewChild('back', { static: false }) backEl: ElementRef;
  @ViewChild('studentId', { static: false }) studentIdEl: ElementRef;
  @ViewChild('addMoreFile', { static: false }) addMoreFileEl: ElementRef;

  constructor(
    private longOderService: LongOrderService,
    private snackbar: MatSnackBar,
    private renderer: Renderer2
  ) {
    this.for_v_esim = false;
  }

  ngOnInit() {
    console.log('Tesing show document description at upload component::', this.subscription_type, this.payment_type, this.pick_up_method);
    this.processUploadBack = false;
    this.processUploadFront = false;
    this.processUploadStdID = false;
    this.student_discount_checkbox = false;
    this.isAddMoreDocument = false;
    this.processUploadAddMoreDoc = false;
    this.enableAddMoreDocument = false;
    this.selectDocument = new FormControl('', Validators.required);
    this.subOrderInformation.sink = this.longOderService.orderInformation$.subscribe(
      (res) => (this.orderInformation = res)
    )
    this.subContractHash.sink = this.longOderService.contractHash$.subscribe(
      (res) => (this.contractHash = res)
    );
  }
  selectionChange(event: string) {
    this.name_document = event;
    this.processUploadBack = false;
    this.processUploadFront = false;
    this.processUploadAddMoreDoc = false;
    this.isAddMoreDocument = false;
    this.enableAddMoreDocument = false;
    this.fileNameUpload = '';
    this.titleUploadFront = 'FRONT';
    this.fileNameUploadFront = '';
    this.titleUploadBack = 'BACK';
    this.fileNameUploadBack = '';
    this.fileNameUploadFile1 = '';
    this.showUploadFront = true;
    this.showUploadBack = true;
    if (event === this.documentTypes[0]) {
      this.residence_card = true;
      this.passport_pattern1 = false;
      this.passport_pattern2 = false;
      this.driver_license = false;
      this.special_pernament = false;
      this.personal_number_card = false;
      this.insurance_card = false;
      this.indentification_card = false;
      this.driving_history_certificate = false;
    }
    if (event === this.documentTypes[1]) {
      this.titleUploadFront = 'PASSPORT';
      this.titleUploadBack = 'UTILITY BILL';
      this.residence_card = false;
      this.driver_license = false;
      this.insurance_card = false;
      this.special_pernament = false;
      this.driving_history_certificate = false;
      this.personal_number_card = false;
      this.indentification_card = false;
      this.passport_pattern1 = true;
      this.passport_pattern2 = false;

      this.showUploadBack = false;
      if (this.pick_up_method === 'DELIVERY') {
        if (this.subscription_type.toUpperCase().includes('VOICE')) {
          this.showUploadBack = true;
          this.passport_pattern1 = false;
          this.passport_pattern2 = true;
        }
        else if (this.payment_type !== 'CREDIT CARD') {
          this.showUploadBack = true;
          this.passport_pattern1 = false;
          this.passport_pattern2 = true;
        }
      }
    }
    if (event === this.documentTypes[2]) {
      this.driver_license = true;
      this.residence_card = false;
      this.passport_pattern1 = false;
      this.passport_pattern2 = false;
      this.special_pernament = false;
      this.personal_number_card = false;
      this.insurance_card = false;
      this.indentification_card = false;
      this.driving_history_certificate = false;
    }
    if (event === this.documentTypes[3]) {
      this.driving_history_certificate = true;
      this.driver_license = false;
      this.residence_card = false;
      this.passport_pattern1 = false;
      this.passport_pattern2 = false;
      this.special_pernament = false;
      this.insurance_card = false;
      this.personal_number_card = false;
      this.indentification_card = false;
    }
    if (event === this.documentTypes[4]) {
      this.personal_number_card = true;
      this.driving_history_certificate = false;
      this.driver_license = false;
      this.residence_card = false;
      this.insurance_card = false;
      this.passport_pattern1 = false;
      this.passport_pattern2 = false;
      this.special_pernament = false;
      this.indentification_card = false;
    }
    if (event === this.documentTypes[5]) {
      this.insurance_card = true;
      this.personal_number_card = false;
      this.driving_history_certificate = false;
      this.driver_license = false;
      this.residence_card = false;
      this.passport_pattern1 = false;
      this.passport_pattern2 = false;
      this.special_pernament = false;
      this.indentification_card = false;
    }
    if (event === this.documentTypes[6]) {
      this.special_pernament = true;
      this.driver_license = false;
      this.residence_card = false;
      this.insurance_card = false;
      this.passport_pattern1 = false;
      this.passport_pattern2 = false;
      this.driving_history_certificate = false;
      this.personal_number_card = false;
      this.indentification_card = false;
    }
    if (event === this.documentTypes[7]) {
      this.titleUploadFront = 'ID CARD';
      this.titleUploadBack = 'CERTIFICATE';
      this.indentification_card = true;
      this.special_pernament = false;
      this.driver_license = false;
      this.residence_card = false;
      this.insurance_card = false;
      this.passport_pattern1 = false;
      this.passport_pattern2 = false;
      this.driving_history_certificate = false;
      this.personal_number_card = false;
    }
    Add_Remove_Css('remove', this.selectDocumentFF, this.renderer);
  }
  checkUploadPP(event: any) {
    if (event.target.files.length === 0) {
      this.requiredErrorUploadPP = true;
      this.fileNameUpload = '';
    } else {
      this.requiredErrorUploadPP = false;
    }
  }
  checkUploadFront(event: any) {
    if (event.target.files.length === 0) {
      this.requiredUploadFront = true;
      this.fileNameUploadFront = '';
    } else {
      this.requiredUploadFront = false;
    }
  }
  checkUploadBack(event: any) {
    if (event.target.files.length === 0) {
      this.requiredUploadBack = true;
      this.fileNameUploadBack = '';
    } else {
      this.requiredUploadBack = false;
    }
  }
  checkUploadStd(event: any) {
    if (event.target.files.length === 0) {
      this.requiredUploadStd = true;
      this.fileNameUploadSTID = '';
    } else {
      this.requiredUploadStd = false;
    }
  }
  onChangeUploadStudentID(event) {
    if (event.target.files.length) {
      const fileData = event.target.files[0] as File;
      this.fileNameUploadSTID = fileData.name;
      Add_Remove_Css('remove', this.uploadStdFF, this.renderer);
      this.longOderService.setLoadingEvent = true;
      this.longOderService
        .uploadFile({
          contract_hash: this.contractHash,
          documents: [
            {
              data: fileData,
              file_number: 3,
            },
          ],
        })
        .subscribe(
          (res) => {
            this.longOderService.setLoadingEvent = false;
            this.processUploadStdID = true;
            this.requiredUploadStd = false;
          },
          (error) => {
            // show snackbar upload failed
            this.longOderService.setLoadingEvent = false;
            this.processUploadStdID = false;
            this.snackbar.open('Upload failed !', 'Try Again', {
              duration: 3000,
            });
          }
        );
    }
  }
  onChangeAddMoreDocument(event) {
    const fileData = event.target.files[0] as File;
    this.fileNameUploadFile1 = fileData.name;
    Add_Remove_Css('remove', this.uploadAdditionalFF, this.renderer);
    this.longOderService.setLoadingEvent = true;
    this.longOderService
      .uploadFile({
        contract_hash: this.contractHash,
        documents: [
          {
            data: fileData,
            file_number: 2,
          },
        ],
      })
      .subscribe(
        (res) => {
          this.longOderService.setLoadingEvent = false;
          this.processUploadAddMoreDoc = true;
        },
        (error) => {
          // show snackbar upload failed
          this.longOderService.setLoadingEvent = false;
          this.processUploadAddMoreDoc = false;
          this.snackbar.open('Upload failed !', 'Try Again', {
            duration: 3000,
          });
        }
      );
  }
  onChangeUploadFront(event) {
    if (event.target.files.length) {
      const fileData = event.target.files[0] as File;
      this.fileNameUploadFront = fileData.name;
      Add_Remove_Css('remove', this.uploadFrontFF, this.renderer);
      this.longOderService.setLoadingEvent = true;
      this.longOderService
        .uploadFile({
          contract_hash: this.contractHash,
          documents: [
            {
              data: fileData,
              file_number: 0,
            },
          ],
        })
        .subscribe(
          (res) => {
            this.longOderService.setLoadingEvent = false;
            this.processUploadFront = true;
            this.requiredUploadFront = false;
            if (!this.showUploadBack && this.processUploadFront) {
              this.enableAddMoreDocument = true;
            } else {
              this.enableAddMoreDocument = false;
            }
          },
          (error) => {
            this.longOderService.setLoadingEvent = false;
            this.processUploadFront = false;
            this.enableAddMoreDocument = false;
            this.snackbar.open('Upload failed !', 'Try Again', {
              duration: 3000,
            });
          }
        );
    }
  }
  onChangeUploadBack(event) {
    if (event.target.files.length) {
      const fileData = event.target.files[0] as File;
      this.fileNameUploadBack = fileData.name;
      Add_Remove_Css('remove', this.uploadBackFF, this.renderer);
      this.longOderService.setLoadingEvent = true;
      this.longOderService
        .uploadFile({
          contract_hash: this.contractHash,
          documents: [
            {
              data: fileData,
              file_number: 1,
            },
          ],
        })
        .subscribe(
          (res) => {
            this.longOderService.setLoadingEvent = false;
            this.processUploadBack = true;
            this.requiredUploadBack = false;
            this.showUploadBack && this.processUploadFront && this.processUploadBack ?
              this.enableAddMoreDocument = true : this.enableAddMoreDocument = false;
          },
          (error) => {
            this.longOderService.setLoadingEvent = false;
            this.processUploadBack = false;
            this.enableAddMoreDocument = false;
            this.snackbar.open('Upload failed !', 'Try Again', {
              duration: 3000,
            });
          }
        );
    }
  }
  addMoreFile() {
    if (
      (!this.showUploadBack && this.processUploadFront) ||
      (this.processUploadFront && this.processUploadBack)
    ) {
      this.isAddMoreDocument = !this.isAddMoreDocument;
      if (this.isAddMoreDocument === false) {
        this.processUploadAddMoreDoc = false;
        this.addMoreFileEl.nativeElement.value = '';
        this.fileNameUploadFile1 = '';
      }
    }
  }
  isStudentCheck(event: boolean) {
    if (event === false) {
      this.fileNameUploadSTID = '';
      this.processUploadStdID = false;
    }
    this.student_discount_checkbox = event;
    this.longOderService.checkStudentCheck = event;
  }

  private processUploadDocument() {
    if (this.showUploadFront && this.showUploadBack &&
      (this.processUploadFront === false || this.processUploadBack === false)
    ) {
      if (this.processUploadFront === false || this.processUploadBack === false) {
        if (this.processUploadFront === false) {
          Add_Remove_Css('add', this.uploadFrontFF, this.renderer);
          ShowSnackBar(this.snackbar);
        }
        if (this.processUploadBack === false) {
          Add_Remove_Css('add', this.uploadBackFF, this.renderer);
          ShowSnackBar(this.snackbar);
        }
      }
    }
    if (this.showUploadFront && this.processUploadFront === false) {
      Add_Remove_Css('add', this.uploadFrontFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.student_discount_checkbox && this.processUploadStdID === false) {
      Add_Remove_Css('add', this.uploadStdFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
    if (this.isAddMoreDocument && this.processUploadAddMoreDoc === false) {
      Add_Remove_Css('add', this.uploadAdditionalFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
  }

  public checkUploadDocument() {
    if (this.for_v_esim) {

    }
    else {
      this.processUploadDocument();
    }
  }
  ngOnDestroy() {
    this.subContractHash.unsubscribe();
  }
}
