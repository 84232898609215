<div class="complete-first-step">
  <app-header [step]="1"></app-header>
  <div class="contents">
    <div class="contents__img">
      <img src="../../../../assets/images/you-got-mail.svg" alt="you got email image" class="getemail">
    </div>
    <div class="contents__body">
      <h1>PLEASE CHECK YOUR EMAIL!</h1>
      <p>An email has been sent to your registered email address.</p>
      <p> Please check your email and click on the provided link to proceed.</p>
      <h3>※ If you do not receive the email, please check your spam box. </h3>
    </div>
  </div>
</div>
