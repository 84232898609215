import { SelectPayMethod } from './types/select-paymethod';

export const paymentMethodSelect: SelectPayMethod[] = [
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'CREDIT CARD',
    dt_offset: 1,
    has_voice: true,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: true },
    delivery: true,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'CREDIT CARD',
    dt_offset: 2,
    has_voice: true,
    airport: true,
    airport_candidates: { NRT_KIX: true, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: true },
    delivery: true,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'CREDIT CARD',
    dt_offset: 3,
    has_voice: true,
    airport: true,
    airport_candidates: { NRT_KIX: true, HND: true, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: true },
    delivery: true,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'CREDIT CARD',
    dt_offset: 1,
    has_voice: false,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: true },
    delivery: true,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'CREDIT CARD',
    dt_offset: 2,
    has_voice: false,
    airport: true,
    airport_candidates: { NRT_KIX: true, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: true },
    delivery: true,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'CREDIT CARD',
    dt_offset: 3,
    has_voice: false,
    airport: true,
    airport_candidates: { NRT_KIX: true, HND: true, other: true },
    office: true,
    office_candidates: { shinjuku: true, other: true },
    delivery: true,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'SMARTPIT',
    dt_offset: 1,
    has_voice: true,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: false },
    delivery: true,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'SMARTPIT',
    dt_offset: 2,
    has_voice: true,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: false,
    office_candidates: { shinjuku: false, other: false },
    delivery: false,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'SMARTPIT',
    dt_offset: 3,
    has_voice: true,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: false,
    office_candidates: { shinjuku: false, other: false },
    delivery: false,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'SMARTPIT',
    dt_offset: 1,
    has_voice: false,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: false },
    delivery: true,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'SMARTPIT',
    dt_offset: 2,
    has_voice: false,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: false,
    office_candidates: { shinjuku: false, other: false },
    delivery: false,
  },
  {
    order_from: 'OUTSIDE OF JAPAN',
    pay_method: 'SMARTPIT',
    dt_offset: 3,
    has_voice: false,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: false,
    office_candidates: { shinjuku: false, other: false },
    delivery: false,
  },
  {
    order_from: 'IN JAPAN',
    pay_method: 'CREDIT CARD',
    dt_offset: 4,
    has_voice: true,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: true },
    delivery: true,
  },
  {
    order_from: 'IN JAPAN',
    pay_method: 'CREDIT CARD',
    dt_offset: 4,
    has_voice: false,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: true },
    delivery: true,
  },
  {
    order_from: 'IN JAPAN',
    pay_method: 'SMARTPIT',
    dt_offset: 4,
    has_voice: true,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: false },
    delivery: true,
  },
  {
    order_from: 'IN JAPAN',
    pay_method: 'SMARTPIT',
    dt_offset: 4,
    has_voice: false,
    airport: false,
    airport_candidates: { NRT_KIX: false, HND: false, other: false },
    office: true,
    office_candidates: { shinjuku: true, other: false },
    delivery: true,
  },
];
