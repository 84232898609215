import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-complete-first-step',
  templateUrl: './complete-first-step.component.html',
  styleUrls: ['./complete-first-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompleteFirstStepComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
