import { Component, Renderer2, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FinishComponent implements OnInit {
  private queryParams: any;
  public affi_contract_code: string;
  public email: string;
  constructor(
    @Inject('windowObject') private window: Window,
    private _activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe(
      params => {
        this.queryParams = params;
        if (this.queryParams.contract_code) {
          // tslint:disable-next-line:no-string-literal
          this.affi_contract_code = this.queryParams['contract_code'];
          this.email = this.queryParams.email;
        }
      }
    );
    // automatically redirected to the survey page after 15 seconds
    if (this.email && this.affi_contract_code) {
      setTimeout(() => {
        // tslint:disable-next-line:max-line-length
        this.window.location.href = `https://docs.google.com/forms/d/e/1FAIpQLScZDpYctpHxWn1Keri3jWPjdwYrDs-f2eJjYANX1aHV2zDW6Q/viewform?entry.583804134=${this.email}&entry.2034490624=${this.affi_contract_code}`, '_blank';
      }, 10000);
    }

    // // zendesk-chat:開始コメントの挿入
    // const zenChatCommentStart = this.renderer.createComment('Start of Zendesk Widget script');
    // this.renderer.appendChild(this.document.head, zenChatCommentStart);

    // // zendesk-chat:スクリプトタグの挿入
    // const zenChatScript = this.renderer.createElement('script');
    // this.renderer.setAttribute(zenChatScript, 'id', 'ze-snippet');
    // this.renderer.setAttribute(zenChatScript, 'src', 'https://static.zdassets.com/ekr/snippet.js?key=9492ed50-4c57-49ec-9d3b-2d007050c1e6');
    // this.renderer.appendChild(this.document.head, zenChatScript);

    // // zendesk-chat:終了コメントの挿入
    // const czenChatommentEnd = this.renderer.createComment('End of Zendesk Widget script');
    // this.renderer.appendChild(this.document.head, czenChatommentEnd);
  }
}
