import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component,
  ElementRef,
  OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { HistoryService } from 'src/app/services/history.service';
import { LongOrderService } from 'src/app/services/long-order.service';
import { ShowSnackBar } from 'src/app/Utils/utils';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsConditionsComponent implements OnInit {

  // disableButton: boolean;
  isChecked1: boolean;
  isChecked2: boolean;
  isChecked3: boolean;
  isChecked4: boolean;
  isChecked5: boolean;
  subcriptionType$ = this.longOrderService.subcriptionType$; // voice || data || wifi || vocie_wifi
  paymentMethod$ = this.longOrderService.paymentMEthod$; // CREDIT CARD || SMARTPIT
  @ViewChild('checkbox1', {static: false}) checkbox1: HTMLInputElement;
  @ViewChild('checkbox2', {static: false}) checkbox2: HTMLInputElement;
  @ViewChild('checkbox3', {static: false}) checkbox3: HTMLInputElement;
  @ViewChild('checkbox4', {static: false}) checkbox4: HTMLInputElement;
  @ViewChild('checkbox5', {static: false}) checkbox5: HTMLInputElement;
  constructor(
    private longOrderService: LongOrderService,
    private router: Router,
    private snackbar: MatSnackBar,
    private readonly history: HistoryService
  ) {}

  ngOnInit() {
  }
  checkbox1Change(event: any) {
    console.log(event);
    this.isChecked1 = !event.checked;
  }
  checkbox2Change(event: any) {
    this.isChecked2 = !event.checked;
  }
  checkbox3Change(event: any) {
    this.isChecked3 = !event.checked;
  }
  checkbox4Change(event: any) {
    this.isChecked4 = !event.checked;
  }
  checkbox5Change(event: any) {
    this.isChecked5 = !event.checked;
  }
  submitNextStep() {
    this.checkbox1.checked === false ? this.isChecked1 = true : this.isChecked1 = false;
    this.checkbox2 && this.checkbox2.checked === false ? this.isChecked2 = true : this.isChecked2 = false;
    this.checkbox3.checked === false ? this.isChecked3 = true : this.isChecked3 = false;
    this.checkbox4 && this.checkbox4.checked === false ?  this.isChecked4 = true : this.isChecked4 = false;
    this.checkbox5 && this.checkbox5.checked === false ? this.isChecked5 = true : this.isChecked5 = false;
    if (!this.isChecked1 && !this.isChecked2 && !this.isChecked3 && !this.isChecked4 && !this.isChecked5) {
      this.router.navigate(['/step4']);
    } else {
      ShowSnackBar(this.snackbar);
      return;
    }
  }

}
