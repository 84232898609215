import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(
    private router: Router
  ) {
    // this.router.events.pipe(
    //   filter( (event: RouterEvent) => event instanceof NavigationEnd)
    // ).subscribe( (event: NavigationEnd) => {
    //   if (event.id === 1 && event.url === event.urlAfterRedirects) {
    //     const hash = localStorage.getItem('hash_string');
    //     this.router.navigate(['step2'], {queryParams: { order: hash}});
    //   }
    // });
  }
}
