import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/myFormatDate';
import { LongOrderService } from 'src/app/services/long-order.service';
import { UploadDocumentComponent } from '../../shared/upload-document/upload-document.component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { SelectPayMethod } from 'src/app/types/select-paymethod';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Add_Remove_Css, ShowSnackBar } from 'src/app/Utils/utils';
@Component({
  selector: 'app-office-content',
  templateUrl: './office-content.component.html',
  styleUrls: ['./office-content.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None
})
export class OfficeContentComponent implements OnInit {
  moment = _rollupMoment || _moment;
  officeList = [
    {
      key: 'shinjuku',
      title : 'SHINJUKU OFFICE (Near Shinjuku Station)', 
      pickup_notice: "Same-Day Pickup Available"
    },
    // {
    //   key: 'yokosuka',
    //   title : 'YOKOSUKA PICKUP COUNTER (Near Yokosuka Central Station)',
    //   pickup_notice: "3 Days After the Order Submission"
    // },
    {
      key: 'yokota',
      title : 'YOKOTA PICKUP COUNTER (Near Fussa Station)', 
      pickup_notice: "Same Day Pickup Available"
    },
    // {
    //   key: 'zama',
    //   title : 'ZAMA / ATSUGI PICKUP COUNTER (Near Odakyu Sagamihara Station)', 
    //   pickup_notice: ""
    // },
    {
      key: 'iwakuni',
      title : 'IWAKUNI PICKUP COUNTER (Near Marine Corps Air Station(MCAS) IWAKUNI)', 
      pickup_notice: "3-5 Days After the Order Submission"
    },
    {
      key: 'sasebo',
      title : 'SASEBO PICKUP COUNTER (Near from Back Gate)', 
      pickup_notice: "3-5 Days After the Order Submission"
    },
    // {
    //   key: 'okinawa',
    //   title : 'OKINAWA OFFICE (Near Camp Foster)', 
    //   pickup_notice: ""
    // },
    {
      key: 'shibuya',
      title : 'WANDER COMPASS SHIBUYA (Near Shibuya Station)', 
      pickup_notice: "3 Days After the Order Submission"
    },
    {
      key: 'afstokyo',
      title : 'AFS Tokyo Office (AFS Students Only)',
      pickup_notice: ""
    },
    // {
    //   key: 'mansfield',
    //   title : 'Mansfield Foundation Tokyo Office (Program Participants Only)', 
    //   pickup_notice: ""
    // }
  ];
  estimateTimeArrival = [
    {term: '9:00 ~ 10:00', shinjuku: false, shibuya: false, other: true},
    {term: '10:00 ~ 11:00', shinjuku: true, shibuya: true, other: true},
    {term: '11:00 ~ 12:00', shinjuku: true, shibuya: true, other: true},
    {term: '12:00 ~ 13:00', shinjuku: true, shibuya: true, other: true},
    {term: '13:00 ~ 14:00', shinjuku: true, shibuya: true, other: true},
    {term: '14:00 ~ 15:00', shinjuku: true, shibuya: true, other: true},
    {term: '15:00 ~ 16:00', shinjuku: true, shibuya: true, other: true},
    {term: '16:00 ~ 17:00', shinjuku: true, shibuya: true, other: true},
    {term: '17:00 ~ 18:00', shinjuku: false, shibuya: true, other: true},
    {term: '18:00 ~ 19:00', shinjuku: false, shibuya: true, other: true},
    {term: '19:00 ~ 20:00', shinjuku: false, shibuya: false, other: true},
  ];
  officeName: string;
  officeKey: string;
  officeNotice: string;
  // tslint:disable-next-line:variable-name
  office_information: FormGroup;
  // tslint:disable-next-line:variable-name
  pick_up_location: FormControl;
  // tslint:disable-next-line:variable-name
  pick_up_date: FormControl;
  // tslint:disable-next-line:variable-name
  estimated_time_of_arrival: FormControl;
  note: FormControl;
  minDate: Date;
  @Input() dataJson: null | SelectPayMethod;
  @Input() arrivalDateTime: string;
  @Input() subscription_type: string;
  @Input() payment_type: string;
  @Input() pick_up_method: string;
  @ViewChild('pickupLocationFF', {static: false, read: ElementRef}) pickupLocationFF: ElementRef;
  @ViewChild('pickupDateFF', {static: false, read: ElementRef}) pickupDateFF: ElementRef;
  @ViewChild('etaFF', {static: false, read: ElementRef}) etaFF: ElementRef;
  @ViewChild(UploadDocumentComponent, { static: false })
  uploadComponent: UploadDocumentComponent;
  loading$ = this.longorderService.loading$;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private snackbar: MatSnackBar,
    private longorderService: LongOrderService
  ) { }

  ngOnInit() {
    console.log(this.subscription_type, this.payment_type, this.pick_up_method);
    // calculate current JST
    // tslint:disable-next-line:variable-name
    const dt_local = new Date();
    // tslint:disable-next-line:variable-name
    const dt_as_jst = new Date();
    // tslint:disable-next-line:max-line-length
    dt_as_jst.setTime(dt_local.getTime() + (dt_local.getTimezoneOffset() * 60 * 1000) + (9 * 60 * 60 * 1000) /* Offset for Japanese Standard Time */);
    console.log('JST=', dt_as_jst);

    if (this.dataJson.dt_offset === 1 ||
      this.dataJson.dt_offset === 2 ||
      this.dataJson.dt_offset === 3) {
      this.minDate = new Date(dt_as_jst);
    }
    if (this.dataJson.dt_offset === 4) {
      this.minDate = new Date(dt_as_jst);
    }
    this.createFormControl();
    this.createFormGroup();
  }
  selectionChange(value: string) {
    const office = this.officeList.find(office => office.title === value);
    this.officeKey = office.key;
    this.officeName = office.title;
    this.officeNotice = office.pickup_notice;
    if (this.pick_up_location.valid) {
      this.renderer.removeClass(this.pickupLocationFF.nativeElement, 'form-field-invalid');
    }
  }
  checkDatePickerEvent(event: any) {
    if (this.pick_up_date.valid) {
      this.renderer.removeClass(this.pickupDateFF.nativeElement, 'form-field-invalid');
    }
  }

  isOfficeHours(time: any) {
    var key = this.officeKey;
    if (time[key] === undefined) {
      key = 'other';
    }
    if (time[key] === true) {
      return true;
    }
    return false;
  }

  etaSelectedChange(event) {
    if (this.estimated_time_of_arrival.valid) {
      this.renderer.removeClass(this.etaFF.nativeElement, 'form-field-invalid');
    }
  }

  isSmartPhone() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }

  formatOfficeTitle(office: any) {
    if (this.isSmartPhone()) {
      return office.title
    }
    else {
      if (office.pickup_notice.length > 0) {
        return `${office.title} : ${office.pickup_notice}`
      }
    }
    return office.title
  }

  submitOffice() {
    if (this.office_information.invalid || this.uploadComponent.selectDocument.invalid) {
      if (this.office_information.invalid) {
        this.checkOfficeFormField();
      }
      if (this.uploadComponent.selectDocument.invalid) {
        this.checkSelectDocument();
      } else {
        this.uploadComponent.checkUploadDocument();
      }
      return;
    } else {
        if (
          (this.uploadComponent.showUploadFront &&
            this.uploadComponent.processUploadFront === false) ||
            (this.uploadComponent.showUploadBack &&
              this.uploadComponent.processUploadBack === false) ||
          ( this.uploadComponent.isAddMoreDocument &&
            this.uploadComponent.processUploadAddMoreDoc === false) ||
          (this.uploadComponent.student_discount_checkbox &&
            this.uploadComponent.processUploadStdID === false)
        ) {
          this.uploadComponent.checkUploadDocument();
          return;
        }
    }
    const dateMoment: moment.Moment = this.pick_up_date.value;
    this.office_information.patchValue({
      pick_up_date: dateMoment.format('LL')
    });
    this.longorderService.setOfficeInformation = this.office_information.getRawValue();
    this.router.navigate(['/step3']);
  }

  private checkOfficeFormField() {
    if (this.pick_up_location.invalid) {
      this.pick_up_location.markAllAsTouched();
      this.renderer.addClass(this.pickupLocationFF.nativeElement, 'form-field-invalid');
      ShowSnackBar(this.snackbar);
    }
    if (this.pick_up_date.invalid) {
      this.pick_up_date.markAllAsTouched();
      this.renderer.addClass(this.pickupDateFF.nativeElement, 'form-field-invalid');
      ShowSnackBar(this.snackbar);
    }
    if (this.estimated_time_of_arrival.invalid) {
      this.estimated_time_of_arrival.markAllAsTouched();
      this.renderer.addClass(this.etaFF.nativeElement, 'form-field-invalid');
      ShowSnackBar(this.snackbar);
    }
  }
  private checkSelectDocument() {
    if (this.uploadComponent.selectDocument.invalid) {
      this.uploadComponent.selectDocument.markAllAsTouched();
      Add_Remove_Css('add', this.uploadComponent.selectDocumentFF, this.renderer);
      ShowSnackBar(this.snackbar);
    }
  }

  private createFormControl() {
    this.pick_up_location = this.fb.control('', Validators.required);
    this.pick_up_date = this.fb.control('', Validators.required);
    this.estimated_time_of_arrival = this.fb.control('', Validators.required);
    this.note = this.fb.control('');
  }
  private createFormGroup() {
    this.office_information = this.fb.group({
      pick_up_location: this.pick_up_location,
      pick_up_date: this.pick_up_date,
      estimated_time_of_arrival: this.estimated_time_of_arrival,
      note: this.note
    });
  }
}
