<div class="confirmation__payment__contents__registered__information">
  <div class="header__information">
    <h3 class="title">registered information</h3>
    <hr class="hr" />
  </div>
  <div class="personal__information" *ngIf="orderInformation$ | async as orderInformation">
    <div class="row">
      <p class="bold">name</p>
      <p class="nomal">
        {{
          (orderInformation.data.customer.title_of_person === null ? "" : orderInformation.data.customer.title_of_person) +
            " " +
            orderInformation.data.customer.full_name
        }}
      </p>
    </div>
    <div class="row">
      <p class="bold">email</p>
      <p class="nomal">{{ orderInformation.data.customer.email }}</p>
    </div>
    <div class="row" *ngIf="orderInformation.data.customer.nationality">
      <p class="bold">nationality</p>
      <p class="nomal">{{ orderInformation.data.customer.nationality }}</p>
    </div>
  </div>
  <hr class="inline__hr" />
  <div class="product__information" *ngIf="orderInformation$ | async as orderInformation">
    <div class="products__name">
      <p class="title">product</p>
      <p class="name">{{ orderInformation.data.subscription_plan }} (x{{ orderInformation.data.quantity }})</p>
    </div>
    <div class="products__info">
      <app-product-ordered [orderInformation]="orderInformation" [typeProduct]="orderInformation.data.subscription_type"></app-product-ordered>
    </div>

    <div class="product__information__note">
      <p class="info">
        ※ For Voice+Data SIM plan, there will be additional charges if you use SMS or make phone calls. You can get a discount by using our
        <span class="discount">discount prefix</span>.
      </p>
      <p class="info">※ For Data Only SIM plan, there will be additional charges if you use SMS.</p>
    </div>
  </div>
  <hr class="inline__hr" />
  <div class="payment__method">
    <div class="row">
      <p class="bold">payment method</p>
      <p class="nomal">{{ (paymentMethod$ | async | titlecase) === "Smartpit" ? "Cash Payment (Smart Pit)" : (paymentMethod$ | async | titlecase) }}</p>
    </div>
  </div>
  <hr class="inline__hr" />
  <ng-container
    *ngIf="(pickupMethod$ | async) === 'EKYC'"
    [ngTemplateOutlet]="ekyc_pickup"
    [ngTemplateOutletContext]="{ location_ekyc: (ekycInformation$ | async) }"
  ></ng-container>
  <ng-container
    *ngIf="(pickupMethod$ | async) === 'AIRPORT'"
    [ngTemplateOutlet]="airport_pickup"
    [ngTemplateOutletContext]="{ location_airport: (airportInformation$ | async) }"
  ></ng-container>
  <ng-container
    *ngIf="(pickupMethod$ | async) === 'OFFICE'"
    [ngTemplateOutlet]="office_pickup"
    [ngTemplateOutletContext]="{ location_office: (officeInformation$ | async) }"
  ></ng-container>
  <ng-container
    *ngIf="(pickupMethod$ | async) === 'DELIVERY'"
    [ngTemplateOutlet]="delivery_pickup"
    [ngTemplateOutletContext]="{ location_delivery: (deliveryInformation$ | async) }"
  ></ng-container>

  <div class="header__information">
    <h3 class="title initial__charge">initial charge</h3>
    <hr class="hr hr__initial" />
  </div>
  <div class="initial__charge__table" *ngIf="(paymentMethod$ | async) === 'CREDIT CARD'">
    <table class="table--credit-card">
      <tr>
        <th rowspan="2" class="deposit">product</th>
        <td colspan="2">{{ product$ | async }}</td>
      </tr>
      <tr>
        <td>Initial fee (x{{ quantity }})</td>
        <td class="price__amount">¥{{ totalCharge | number }}</td>
      </tr>
      <tr *ngIf="isStudentDiscount">
        <td class="image__td">
          <img src="../../../../assets/images/coupon-icon.svg" alt="coupon icon" class="coupon__icon" />
        </td>
        <td class="label__td">Student Discount applied (x{{ this.quantity }})</td>
        <td class="discount__td">-¥{{ studentDiscountPrice | number }}</td>
      </tr>
      <ng-container *ngIf="isCouponValid">
        <tr>
          <td class="image__td">
            <img src="../../../../assets/images/coupon-icon.svg" alt="coupon icon" class="coupon__icon" />
          </td>
          <td class="label__td">{{ couponDiscount.data.title | titlecase }} (x{{ this.quantity }})</td>
          <td class="discount__td">-¥{{ discountPrice | number }}</td>
        </tr>
      </ng-container>
    </table>
    <table>
      <tr>
        <td>Subtotal</td>
        <td class="price__amount">¥{{ subTotal | number }}</td>
      </tr>
      <tr>
        <td>VAT</td>
        <td class="price__amount">¥{{ vat | number }}</td>
      </tr>
      <tr>
        <th>TOTAL</th>
        <td class="price__amount">¥{{ total | number }}</td>
      </tr>
    </table>
  </div>
  <div class="initial__charge__table" *ngIf="(paymentMethod$ | async) === 'SMARTPIT'">
    <table class="table--smartpit">
      <tr>
        <th class="deposit">product</th>
        <td>{{ product$ | async }}</td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td>Initial fee (x{{ quantity }})</td>
        <td class="price__amount">¥{{ totalCharge | number }}</td>
      </tr>
      <tr *ngIf="isStudentDiscount">
        <td class="image__td">
          <img src="../../../../assets/images/coupon-icon.svg" alt="coupon icon" class="coupon__icon" />
        </td>
        <td class="label__td">Student Discount applied (x{{ this.quantity }})</td>
        <td class="discount__td">-¥{{ studentDiscountPrice | number }}</td>
      </tr>
      <ng-container *ngIf="isCouponValid">
        <tr>
          <td class="image__td">
            <img src="../../../../assets/images/coupon-icon.svg" alt="coupon icon" class="coupon__icon" />
          </td>
          <td class="label__td">{{ couponDiscount.data.title | titlecase }} (x{{ this.quantity }})</td>
          <td class="discount__td">-¥{{ discountPrice | number }}</td>
        </tr>
      </ng-container>
      <tr>
        <td class="tr--line"></td>
        <td colspan="2">
          <hr class="horizontal--line tr--line" />
        </td>
      </tr>
      <tr>
        <td></td>
        <td>Product Subtotal</td>
        <td class="price__amount">¥{{ subTotal | number }}</td>
      </tr>
      <tr>
        <td></td>
        <td>VAT</td>
        <td class="price__amount">¥{{ vat | number }}</td>
      </tr>
      <tr>
        <td></td>
        <th>PRODUCT TOTAL</th>
        <td class="price__amount">¥{{ total | number }}</td>
      </tr>
    </table>

    <app-deposit [flatArray]="flatArray" (depositTotalEvent)="depositTotalChangeEvent($event)"></app-deposit>

    <table>
      <tr>
        <th class="total">product total</th>
        <td class="price__amount total">¥{{ total | number }}</td>
      </tr>
      <tr>
        <th class="total">deposit total</th>
        <td class="price__amount total">¥{{ depositTotal | number }}</td>
      </tr>
      <tr>
        <th>initial total</th>
        <td class="price__amount">¥{{ initialTotal | number }}</td>
      </tr>
    </table>
  </div>
  <div class="coupon__code">
    <div class="form__coupon">
      <p class="form__coupon__title">enter coupon code</p>
      <div class="form__coupon__input">
        <input type="text" class="input" [formControl]="couponFC" [value]="couponFC.value" [readonly]="avaibledCouponCode" />
        <button class="nomarl__button" [class.apply]="couponField" [disabled]="!couponField || avaibledCouponCode" (click)="applyCouponDiscount()">
          Apply
        </button>
      </div>
    </div>
    <div class="invalid">
      <p *ngIf="isCouponInvalid" class="invalid__coupon">Invalid coupon code</p>
      <p *ngIf="!isCouponInvalid && isStudentDiscount" class="invalid__coupon">Student discount applied</p>
      <p *ngIf="isCouponValid && !isStudentDiscount" class="invalid__coupon">Coupon code applied</p>
    </div>
    <div class="note__coupon">
      <h3 class="title">Notes:</h3>
      <p class="info">※ This is a one-time charge at the beginning of the contract to activate your plan.</p>
      <p class="info">※ The initial fee will not be returned at the end of the contract.</p>
      <p class="info">※ This charge does not include the first month’s usage fee.</p>
      <p class="info">※ If two or more discounts are applicable, the greater discount will be applied to the order.</p>
      <p class="info">※ Monthly usage fees are invoiced at the end of the following month and payment is required after this.<br/>
        (e.g. January monthly fee is invoiced at the end fo February.)</p>
    </div>
  </div>
</div>

<ng-template #ekyc_pickup let-location_ekyc="location_ekyc">
  <div class="location__information">
    <div class="row">
      <p class="bold">pick up date/time</p>
      <p class="nomal time">{{ location_ekyc.pick_up_date }}</p>
    </div>
  </div>
</ng-template>

<ng-template #airport_pickup let-location_airport="location_airport">
  <div class="location__information">
    <div class="row">
      <p class="bold">pick up location</p>
      <p class="nomal airport">{{ location_airport.pick_up_location }}</p>
      <p class="nomal pickup"><span class="pickup__content">Flight no.</span> {{ location_airport.arriving_flight_number }}</p>
    </div>
    <div class="row">
      <p class="bold">pick up date/time</p>
      <p class="nomal time">{{ location_airport.pick_up_date }}</p>
      <p class="nomal pickup">
        <span class="pickup__content">ETA</span>:
        {{ location_airport.estimated_time_of_arrival ? location_airport.estimated_time_of_arrival : "Not specified" }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #office_pickup let-location_office="location_office">
  <div class="location__information">
    <div class="row">
      <p class="bold">pick up location</p>
      <p class="nomal office">{{ location_office.pick_up_location }}</p>
    </div>
    <div class="row">
      <p class="bold">pick up date/time</p>
      <p class="nomal time">{{ location_office.pick_up_date }}</p>
      <p class="nomal pickup">
        <span class="pickup__content">ETA</span>: {{ location_office.estimated_time_of_arrival ? location_office.estimated_time_of_arrival : "Not specified" }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #delivery_pickup let-location_delivery="location_delivery">
  <ng-container *ngIf="location_delivery.pick_up_location === 'The address written on the photo ID'">
    <div class="location__information">
      <div class="row">
        <p class="bold">pick up location</p>
        <p class="nomal office">{{ location_delivery.pick_up_location }}</p>
      </div>
      <div class="row">
        <div class="left">
          <p class="left__bold">preferred pick up date/time</p>
        </div>
        <div class="right">
          <div class="row">
            <p class="nomal time">{{ location_delivery.preffered_date_of_pick_up }}</p>
            <p class="nomal pickup">
              <span class="pickup__content">ETA</span>:
              {{ location_delivery.delivery_time_specify ? location_delivery.delivery_time_specify : "Do not specify (can be delivered any time)" }}
            </p>
          </div>
          <div class="row">
            <P class="small">
              Please note: this is not guaranteed date/time of delivery. The decided delivery date will be informed by email after the application screening is
              complete.
            </P>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="location_delivery.pick_up_location === 'Residence/Cooporate Offices/Friends house'">
    <div class="location__information">
      <div class="row">
        <p class="bold">pick up location</p>
        <p class="nomal">{{ location_delivery.pick_up_location | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">address</p>
        <p class="nomal">{{ location_delivery.residential_address | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">in care of</p>
        <p class="nomal">{{ location_delivery.in_care_of | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">recipient</p>
        <p class="nomal">{{ location_delivery.recipient | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">preferred pick up date</p>
        <p class="nomal">
          {{ location_delivery.preffered_date_of_pick_up ? location_delivery.preffered_date_of_pick_up : "Will be delivered as soon as possible" }}
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="location_delivery.pick_up_location === 'Hotel'">
    <div class="location__information">
      <div class="row">
        <p class="bold">pick up location</p>
        <p class="nomal">{{ location_delivery.pick_up_location | titlecase }}</p>
      </div>
      <div class="multi__col">
        <div class="col__left">
          <p class="title">hotel name</p>
          <p class="hotel__name">{{ location_delivery.hotel_name | titlecase }}</p>
        </div>

        <div class="col__right">
          <div class="check__in">
            <p class="check__in__title">check in:</p>
            <p class="check__in__date">{{ location_delivery.hotel_check_in_date }}</p>
          </div>
          <div class="check__out">
            <p class="check__out__title">check out:</p>
            <p class="check__out__date">{{ location_delivery.hotel_check_out_date }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <p class="bold">hotel address</p>
        <p class="nomal">{{ location_delivery.hotel_address | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">recipient</p>
        <p class="nomal">{{ location_delivery.recipient | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">preferred pick up date</p>
        <p class="nomal">
          {{ location_delivery.preffered_date_of_pick_up ? location_delivery.preffered_date_of_pick_up : "Will be delivered as soon as possible" }}
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="location_delivery.pick_up_location === 'Post-offices'">
    <div class="location__information">
      <div class="row">
        <p class="bold">pick up location</p>
        <p class="nomal">{{ location_delivery.pick_up_location | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">Post office Branch</p>
        <p class="nomal">{{ location_delivery.post_office_branch_name | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">Post office address</p>
        <p class="nomal">{{ location_delivery.post_office_branch_address | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">recipient</p>
        <p class="nomal">{{ location_delivery.recipient | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">preferred pick up date</p>
        <p class="nomal">
          {{ location_delivery.preffered_date_of_pick_up ? location_delivery.preffered_date_of_pick_up : "Will be delivered as soon as possible" }}
        </p>
      </div>
      <div class="row" *ngIf="location_delivery.estimated_time_of_arrival">
        <p class="bold">eta:</p>
        <p class="nomal">
          {{ location_delivery.estimated_time_of_arrival ? location_delivery.estimated_time_of_arrival : "Do not specify (can be delivered any time)" }}
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="location_delivery.pick_up_location === 'Other'">
    <div class="location__information">
      <div class="row">
        <p class="bold">pick up location</p>
        <p class="nomal">{{ location_delivery.pick_up_location | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">address</p>
        <p class="nomal">{{ location_delivery.shipping_address | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">recipient</p>
        <p class="nomal">{{ location_delivery.recipient | titlecase }}</p>
      </div>
      <div class="row">
        <p class="bold">preferred pick up date</p>
        <p class="nomal">
          {{ location_delivery.preffered_date_of_pick_up ? location_delivery.preffered_date_of_pick_up : "Will be delivered as soon as possible" }}
        </p>
      </div>
    </div>
  </ng-container>
</ng-template>
