import { AfterContentChecked, Component, OnInit, Output,
  ViewEncapsulation, EventEmitter, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Add_Remove_Css } from 'src/app/Utils/utils';


@Component({
  selector: 'app-residence',
  templateUrl: './residence.component.html',
  styleUrls: ['./residence.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResidenceComponent implements OnInit, AfterContentChecked {
  in_care_of: FormControl;
  residential_address: FormControl;
  preffered_date_of_pick_up: FormControl;
  recipient: FormControl;
  note: FormControl;
  contact_phone_number: FormControl;
  residentInformation: FormGroup;
  @Input() minDate: Date;
  @Output() residentFormValid = new EventEmitter<boolean>();
  @ViewChild('recipientRef', {static: false, read: ElementRef}) recipientRef: ElementRef;
  @ViewChild('addressRef', {static: false, read: ElementRef}) addressRef: ElementRef;
  constructor(private fb: FormBuilder, private renderer: Renderer2) { }

  ngOnInit() {
    this.creatFormControl();
    this.createFormGroup();
    this.residentInformation.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged()
    )
    .subscribe(res => console.log(res));
  }
  ngAfterContentChecked() {
    if (this.residentInformation.valid) {
      this.residentFormValid.emit(true);
    } else {
      this.residentFormValid.emit(false);
    }
  }
  recipientChange(event: any) {
   Add_Remove_Css('remove', this.recipientRef, this.renderer);
  }
  addressChange(event: any) {
    Add_Remove_Css('remove', this.addressRef, this.renderer);
  }
  private creatFormControl() {
    this.in_care_of = this.fb.control('');
    this.residential_address = this.fb.control('', Validators.required);
    this.preffered_date_of_pick_up = this.fb.control('');
    this.recipient = this.fb.control('', Validators.required);
    this.contact_phone_number = this.fb.control('');
    this.note = this.fb.control('');
  }
  private createFormGroup() {
    this.residentInformation = this.fb.group({
      in_care_of: this.in_care_of,
      residential_address: this.residential_address,
      preffered_date_of_pick_up: this.preffered_date_of_pick_up,
      recipient: this.recipient,
      contact_phone_number: this.contact_phone_number,
      note: this.note
    });
  }
}
