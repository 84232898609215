import {
  Component,
  OnInit,
  Input, ViewEncapsulation, Output, EventEmitter
} from '@angular/core';
import { ProductInformation } from 'src/app/types/product-information';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Wifi } from 'src/app/types/wifi';
import { VoiceDataSim } from 'src/app/types/voice-data';

@Component({
  selector: 'app-wifi-voice-data-sim-plan',
  templateUrl: './wifi-voice-data-sim-plan.component.html',
  styleUrls: ['./wifi-voice-data-sim-plan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WifiVoiceDataSimPlanComponent implements OnInit {
  isPlans = '';
  isPocketWifi = '';
  isPocketWifiOptional = '';
  selectVoiceSim: boolean;
  selectPocketWifi: boolean;
  notEnsurance = `I DON'T NEED INSURANCE`;
  voiceDataFG: FormGroup;
  pocketWifiFG: FormGroup;
  promosetFormArray: FormArray;
  isVoiceESim: boolean = false;
  @Input() item_types: string = '';
  @Input() wifiVoice: ProductInformation;
  @Input() index = 0;
  @Input() quantity: number;
  @Output() promosetFormEvent = new EventEmitter<FormArray>();
  // @Output() selectTitleWifiVoiceEvent = new EventEmitter<string>();
  constructor(private fb: FormBuilder) {}
  ngOnInit() {
    this.item_types;
    this.selectVoiceSim = false;
    this.selectPocketWifi = false;
    this.createForm();
  }
  selectVoiceDataSimPlan(value: string) {
    // this.selectTitleWifiVoiceEvent.emit(value);
    this.selectVoiceSim = true;
    this.isPlans = value;
    const voiceDataSim = this.wifiVoice[1] as VoiceDataSim;
    const found = voiceDataSim.plans.find((item) => item.plan_title === value);
    this.voiceDataFG.patchValue({
      category_type_id: voiceDataSim.category_id,
      category_type: voiceDataSim.category_title,
      product_type_id: found.product_type_id,
      product_type: found.product_type,
    });
    this.promosetFormEvent.emit(this.promosetFormArray);
  }

  selectPocketWifiPlan(value: string) {
    // this.selectTitleWifiVoiceEvent.emit(value);
    const pocketWifi = this.wifiVoice[0] as Wifi;
    const found = pocketWifi.plans.find((item) => item.plan_title === value);
    if (!found.is_enable) {
      return; // don't do anything
    }
    this.isPocketWifi = value;
    this.selectPocketWifi = true;
    this.pocketWifiFG.patchValue({
      category_type_id: pocketWifi.category_id,
      category_type: pocketWifi.category_title,
      product_type_id: found.product_type_id,
      product_type: found.product_type,
    });
    this.promosetFormEvent.emit(this.promosetFormArray);
  }

  private createForm() {
    this.voiceDataFG = this.fb.group({
      category_type_id: [null, Validators.required],
      category_type: [null, Validators.required],
      product_type_id: [null, Validators.required],
      product_type: [null, Validators.required],
      options: this.fb.array([]),
    });
    this.pocketWifiFG = this.fb.group({
      category_type_id: [null, Validators.required],
      category_type: [null, Validators.required],
      product_type_id: [null, Validators.required],
      product_type: [null, Validators.required],
      options: this.fb.array([]),
    });
    this.promosetFormArray = this.fb.array([
      this.voiceDataFG,
      this.pocketWifiFG,
    ]);
  }
}
