<ng-container *ngIf="pocketWifiPlan">
  <h3 class="monthly--data-title">
    SELECT YOUR MONTHLY POCKET WIFI PLAN (Set {{index + 1}})
  </h3>
  <div class="data--monthly--pocket-wifi">
    <div class="wifi" *ngFor="let item of pocketWifiPlan.plans" (click)="selectPocketWifiPlan(item.plan_title)"
      [ngClass]="{'select__product': isPocketWifi === item.plan_title, 'no_stock': !item.is_enable}">
      <p class="wifi__amount--wifi">{{item.plan_title}}</p>
      <p class="wifi__price">{{item.monthly_fee | number}} JPY/MO</p>
      <p *ngIf="!item.is_enable" class="wifi__price__taxincl">Currently Out Of Stock</p>
      <p *ngIf="item.is_enable" class="wifi__price__taxincl">({{ item.monthly_fee * 1.1 | number }} JPY/MO, tax incl.)
      </p>
    </div>
  </div>
  <p class="data--monthly__des">
    ※ The first month’s fee is prorated. You may use the data amount that is listed on your contract.<br />
    ※ The Pocket WIFI plans use the Softbank network. <br/>
    ※ If you would like a large capacity Pocket WIFI device, please contact us using the <a class="link" href="https://www.sakuramobile.jp/contact-us/" target="_blank">Contact Us</a> form. 
  </p>
  <hr class="separator-line" *ngIf="quantity > 1">
</ng-container>
