
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogMessageComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public errorData: any
    ) { }

  ngOnInit() {
  }
  public getMessage(data: any): string {
    console.log("DialogMessageComponent : getMessage", data);
    if (data.error) {
      if (data.error.message) {
        return data.error.message;
      }
    }
    else if (data.message) {
      if (data.message.message) {
        return data.message.message;
      }
      else if (data.message.error) {
        if (data.message.error.message) {
          return data.message.error.message;
        }
        else {
          return data.message.error;
        }
      }
      else if (data.message) {
        return data.message;
      }
    }
    return "No error message was assigned.";
  }
}
