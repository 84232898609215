import { OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { Component, Input, EventEmitter, OnInit, AfterContentChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductInformation } from 'src/app/types/product-information';
import { Wifi } from 'src/app/types/wifi';

@Component({
  selector: 'app-insurance-option',
  templateUrl: './insurance-option.component.html',
  styleUrls: ['./insurance-option.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsuranceOptionComponent implements OnInit, OnChanges {
  isPocketWifiOptional = '';
  selectInsurance: boolean;
  notEnsurance = `I DON'T NEED INSURANCE`;
  insuranceFG: FormGroup;
  @Input() wifiOption: ProductInformation | Wifi;
  // @Output() insuranceFormEvent = new EventEmitter<FormGroup>();
  constructor(private fb: FormBuilder) { }
  ngOnChanges() {
  }
  ngOnInit() {
    this.selectInsurance = false;
    this.insuranceFG = this.fb.group({
      category_type_id: null,
      category_type:  null,
      product_type_id:  null,
      product_type:  null,
    });
    console.log('wifiOption', this.wifiOption);
  }
  selectPocketWifiOptionalPlan(value: string) {
    this.selectInsurance = true;
    this.isPocketWifiOptional = value;
    if (value !== this.notEnsurance) {
      const pocketWifi = this.wifiOption[0] as Wifi;
      const insurance = pocketWifi.options[0].plans.find(
        (item) => item.plan_title === value
      );
      this.insuranceFG.patchValue({
        category_type_id: pocketWifi.options[0].category_id,
        category_type: pocketWifi.options[0].category_title,
        product_type_id: insurance.product_type_id,
        product_type: insurance.product_type,
      });
  }
}
}

