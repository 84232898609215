import { ElementRef, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Constants } from '../constants/constants';


export declare type Action = 'remove' | 'add';

export function Add_Remove_Css(action: Action, el: ElementRef, renderer: Renderer2) {
    if (action === 'remove') {
      renderer.removeClass(el.nativeElement, 'form-field-invalid');
    } else if (action === 'add') {
      renderer.addClass(el.nativeElement, 'form-field-invalid');
    }
}
export function set_Background_Snackbar_NewCard(action: Action, el: ElementRef, renderer: Renderer2) {
  if (action === 'remove') {
    renderer.removeClass(el.nativeElement, 'form-field-invalid-newcard');
  } else if (action === 'add') {
    renderer.addClass(el.nativeElement, 'form-field-invalid-newcard');
  }
}

export  function ShowSnackBar(snackbar: MatSnackBar) {
    const screenWidth = window.innerWidth;
    const snackBarConfig = screenWidth <= 480 
    ? Constants.defaultSnackBarConfig('required-fields-error')
    : Constants.defaultSnackBarConfig('form-fields-error');
      snackbar.open('Please fill in the required information', 'OK',
      snackBarConfig
      // Constants.defaultSnackBarConfig('form-fields-error')
    );
}
