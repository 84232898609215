import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { LongOrderService } from 'src/app/services/long-order.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-full-name-dialog',
  templateUrl: './full-name-dialog.component.html',
  styleUrls: ['./full-name-dialog.component.scss'],
})

export class FullNameDialogComponent implements OnInit {
  loading$ = this.longOrderService.loading$;
  form: FormGroup;
  isEditMode = false;
  errorVisible = false;
  firstNameValue: string;
  loading: boolean = false;

  @Output() formSubmitted = new EventEmitter<string>();

  constructor(
    private longOrderService: LongOrderService,
    private dialogRef: MatDialogRef<FullNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      fullName: [{ value: this.data.fullName, disabled: true }, Validators.required],
    });
  }

  ngOnInit() {
    this.form.patchValue({ fullName: this.data.fullName });
  }

  enableEdit() {
    this.isEditMode = true;
    const fullNameControl = this.form.get('fullName');
    if (fullNameControl) {
      fullNameControl.enable();
      fullNameControl.markAsTouched();
      this.errorVisible = fullNameControl.hasError('required')
    }

    const inputElement = document.querySelector('.modal_form_input') as HTMLInputElement;
    if (inputElement) {
      inputElement.focus();
    }
  }

  submitBasicInformation() {
    console.log('form submitted', this.form.value);
    if (this.form.valid || this.form.value) {
      this.formSubmitted.emit(this.form.value);
      this.longOrderService.setLoadingEvent = true;
      setTimeout(() => {
        this.longOrderService.setLoadingEvent = false;
        this.dialogRef.close();
      }, 2000);
    } else {
      this.longOrderService.setLoadingEvent = false;
      if (this.isEditMode) {
        console.log('Form is invalid');
      }
      this.formSubmitted.emit(this.form.value);
      this.form.markAllAsTouched();
      this.dialogRef.close(); 
    }
  }
}
