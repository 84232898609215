<div class="step1">
  <header>
    <app-header [step]="1"></app-header>
  </header>

  <div class="body">
    <div class="body__title">
      <h1 *ngIf="isParamsHomeWifi === false" >SELECT YOUR PRODUCT</h1>
    </div>
    <div class="body__products">
      <div class="body__products--product" *ngFor="let title of titleProducts" (click)="getPlansInformation(title); selectQuantityOpen();"
        [ngClass]="{ select__product: nameProduct === title }">
        <ng-container *ngIf="title === 'VOICE+DATA SIM & POCKET WIFI'">
          <img src="../../../assets/images/promoset.svg" alt="coupon" />
        </ng-container>
        <ng-container *ngIf="title === 'VOICE+DATA eSIM & POCKET WIFI'">
          <img src="../../../assets/images/promoset_ew.svg" alt="coupon" />
        </ng-container>
        <ng-container *ngIf="title === 'VOICE + DATA SIM CARD'">
          <img class="img--product" src="../../../assets/images/voicesim.svg" alt="coupon"/>
        </ng-container>
        <ng-container *ngIf="title === 'DATA ONLY SIM CARD'">
          <img class="img--product" src="../../../assets/images/dataonly.svg" alt="coupon" />
        </ng-container>
        <ng-container *ngIf="title === 'POCKET WIFI'">
          <img class="img--product" src="../../../assets/images/pocketwifi.svg" alt="coupon" />
        </ng-container>
        <ng-container *ngIf="title === 'VOICE + DATA eSIM'">
          <img class="img--product" src="../../../assets/images/voiceesim.png" style="width: 50px; height: auto;" alt="coupon" />
        </ng-container>
        <ng-container *ngIf="title === 'DATA ONLY eSIM'">
          <img class="img--product" src="../../../assets/images/dataesim.png" style="width: 50px; height: auto;" alt="coupon" />
        </ng-container>
        <ng-container *ngIf="title === 'HOME WIFI'">
          <img class="img--product home-wifi-icon" src="../../../assets/images/homewifi.svg" alt="coupon" />
        </ng-container>
        <div class="product--title">
          <ng-container *ngIf="title === 'VOICE+DATA SIM & POCKET WIFI'">
            <img src="../../../assets/images/campaign-speaker.svg" alt="promoset" />
            <span class="promo--set">Promo Set (SIM)</span>
            <span class="pocket--wifi">(Pocket WiFi activation fee will be waived)</span>
          </ng-container>
          <ng-container *ngIf="title === 'VOICE+DATA eSIM & POCKET WIFI'">
            <img src="../../../assets/images/campaign-speaker.svg" alt="promoset" />
            <span class="promo--set">Promo Set (eSIM)</span>
            <span class="pocket--wifi">(Pocket WiFi activation fee will be waived)</span>
          </ng-container>
          <p class="name--product" [ngStyle]="{
              'padding-top':
                title !== 'VOICE+DATA SIM & POCKET WIFI' ? '1rem' : '.5rem'
            }">
            {{ title }}
          </p>
        </div>
      </div>
      <!---Move MNP checkbox when beginning--->
      <div class="body__products__checkbox"
        *ngIf="nameProduct === 'VOICE+DATA SIM & POCKET WIFI' ||
        nameProduct === 'VOICE + DATA SIM CARD'
        ">
        <mat-checkbox [checked]="isCheckMNP" (change)="mnpRequest($event.checked)">
          I have a Japanese phone number (start
          with 070, 080, or 090) and I would
          like to keep using it with Sakura Mobile service.</mat-checkbox>
      </div>
      <!---Move quantity when beginning--->
      <div class="body__products__quantity">
        <h3 class="body__products__quantity__title">QUANTITY</h3>
        <div class="body__products__quantity__number" [class.disabled]="isCheckMNP">
          <div class="body__products__quantity__number--choosed" *ngFor="let item of quantitys"
          [ngStyle]="setBorder">
            <button class="minus" [ngClass]="{ 'minus-active': selectedQuantity >= 2 }" 
              (click)="decreaseQuantity()">-</button>
            <span>{{ this.selectedQuantity }}</span>
            <button class="plus" [ngClass]="{ 'plus-active': isMnpRequestActive || selectedQuantity === maxQuantity }"
              (click)="increaseQuantity()">+</button>
          </div>
        </div>
      </div>
      <div *ngIf="nameProduct === 'HOME WIFI'" class="body__products__des">
        <p>If the signal coverage is poor in your area, we will contact you within 24 hours to provide alternative services such as our Fiber Internet.</p>
      </div>
    </div>
    <ng-container *ngIf="isSelectedProduct && isSelectQuantity && this.nameProduct && params !== 'home_wifi'">
      <section class="body__show">
        <div class="body__select__plan">
          <div class="body__select__plan__title">
            <h1>SELECT YOUR PLAN</h1>
          </div>
          <p class="body__select__plan__des" *ngIf="params !== 'wifi'; else wifiFee">
            ※The prices below are your basic monthly fees, there can be an
            additional charge depending on your call and SMS usage.
          </p>
          <ng-template #wifiFee>
            <p class="body__select__plan__des">
              ※ The prices below are your basic monthly fees.
            </p>
          </ng-template>
          <div class="body__select__plan__monthly-data" >
            <ng-container *ngFor="let wifiVoice$ of arrWifiVoice; index as i ">
              <app-wifi-voice-data-sim-plan
                [item_types]="params"
                [wifiVoice]="wifiVoice$ | async" [index]="i"
                [quantity]="selectedQuantity">
              </app-wifi-voice-data-sim-plan>
            </ng-container>
            <ng-container *ngFor="let voiceDataSim$ of arrVoiceData ; index as i">
              <app-voice-data-sim-plan [voiceDataPlan]="voiceDataSim$ | async" [index]="i"
              [quantity]="selectedQuantity">
              </app-voice-data-sim-plan>
            </ng-container>
            <!-- <ng-container *ngFor="let homeWifi$ of arrHomeWifi ; index as i">
              <app-home-wifi-plan [homeWifiPlan]="homeWifi$ | async" [index]="i"
              [quantity]="selectedQuantity">
              </app-home-wifi-plan>
            </ng-container> -->
            <ng-container *ngFor="let pocketWifi$ of arrPocketWifi ; index as i">
              <app-pocket-wifi-plan [pocketWifiPlan]="pocketWifi$ | async" [index]="i"
              [quantity]="selectedQuantity">

              </app-pocket-wifi-plan>
            </ng-container>

            <ng-container *ngIf="((productPlanInformation$ | async) != null )&& (params === 'wifi,voicesim' || params === 'wifi,voice_esim' || params === 'wifi')">
              <app-insurance-option
              [wifiOption]="productPlanInformation$ | async"></app-insurance-option>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="params === 'wifi,voicesim' || params === 'wifi,voice_esim' || params === 'wifi'">
          <div class="body__pay-for-surance">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon class="help-icon">help</mat-icon>
                  </mat-panel-title>
                  <mat-panel-description>
                    Why should I pay for insurance?
                  </mat-panel-description>
                  <div class="drop-down">
                    <mat-icon class="drop-down-icon">arrow_drop_down</mat-icon>
                  </div>
                </mat-expansion-panel-header>
                <div class="body__expansion--body">
                  <p class="expansion--title">
                    The compensations if you lose or break the device will be as
                    below.
                  </p>
                  <div class="expansion--info">
                    <table>
                      <tr>
                        <th>Item</th>
                        <th>Compensation</th>
                      </tr>
                      <tr>
                        <td>Pocket WiFi</td>
                        <td>¥30,000</td>
                      </tr>
                      <tr>
                        <td>Pocket WiFi (screen only)</td>
                        <td>¥10,000</td>
                      </tr>
                      <tr>
                        <td>Pocket WiFi (back cover only)</td>
                        <td>¥2,000</td>
                      </tr>
                      <tr>
                        <td>AC adapter</td>
                        <td>¥2,000</td>
                      </tr>
                      <tr>
                        <td>USB cable</td>
                        <td>¥1,000</td>
                      </tr>
                      <tr>
                        <td>Portable case</td>
                        <td>¥1,000</td>
                      </tr>
                    </table>
                    <div class="arrow--icons">
                      <mat-icon>play_arrow</mat-icon>
                      <mat-icon>play_arrow</mat-icon>
                    </div>
                    <table>
                      <tr>
                        <th>Insurance</th>
                        <th>Charge</th>
                      </tr>
                      <tr>
                        <td>Without insurance</td>
                        <td>¥34,000</td>
                      </tr>
                      <tr>
                        <td>Insurance (Cover 70%)</td>
                        <td>¥10,200</td>
                      </tr>
                      <tr>
                        <td>Premium Insurance (Cover 100%)</td>
                        <td>0</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="body__insurance--notes">
            <h4 class="notes--title">Notes:</h4>
            <p class="notes--info">
              ※You can only apply for insurance when you sign up for our monthly plan.
            </p>
            <p class="notes--info">
              ※The insurance can only be applied once during the contract period.
            </p>
            <p class="notes--info">※The insurance is a monthly payment.</p>
            <p class="notes--info">※This insurance will only apply when you lose or do some damage to the device. <br />
              Therefore, you still need to return the device back to us when the contract ends.</p>
          </div>
        </ng-container>
      </section>
    </ng-container>

    <section *ngIf="showSelectLocation">
      <div class="body__select__location">
        <div class="body__select__location--title">
          <h1>SELECT YOUR CURRENT LOCATION</h1>
        </div>
        <div class="body__select__location--locations">
          <div class="location" *ngFor="let location of locations" (click)="selectLocation(location)"
            [ngClass]="{ select__product: selectedLocation === location }">
            <p class="location--title">{{ location }}</p>
          </div>
        </div>
        <div id="esim_express_selection" *ngIf="eSIMInJapan">
          <div *ngIf="params==='voice_esim'">
            <div class="section_esim">
              <div class="title">
                <label class="title">SELECT YOUR PAYMENT METHOD <div class="required">required</div></label>
              </div>
              <div class="pay_methods">
                <div class="pay_method" [ngClass]="{ select__product: willPayCredit }" (click)="onPayCredit()">
                  <p class="title">CREDIT CARD/DEBIT CARD</p>
                </div>
                <div class="pay_method" [ngClass]="{ select__product: willPayCash }" (click)="onPayCash()">
                  <p class="title">CASH</p>
                </div>
              </div>
              <ng-container *ngIf="willPayCredit || willPayCash">
                <div class="title">
                  <label class="title">Please list all the documentation that you currently have.</label>
                </div>
                <mat-checkbox class="esim_checkbox" (change)="onHasEKYCResidenceNoJPAdress($event.checked)">
                  Residence Card
                </mat-checkbox><br/>
                <mat-checkbox class="esim_checkbox" (change)="onHasEKYCResidenceWithJPAdress($event.checked)">
                  Residence Card (Japanese Address written on it)
                </mat-checkbox><br/>
                <mat-checkbox class="esim_checkbox" (change)="onHasEKYCPassport($event.checked)">
                  Passport
                </mat-checkbox><br/>
                <mat-checkbox class="esim_checkbox" (change)="onHasEKYCJPDriveLicense($event.checked)">
                  Japanese Drivers License
                </mat-checkbox><br/>
                <mat-checkbox class="esim_checkbox" (change)="onHasEKYCJPMyNumber($event.checked)">
                  Japan [My Number Card]
                </mat-checkbox><br/>
                <mat-checkbox class="esim_checkbox" (change)="onHasOtherFlag($event.checked)">
                  Others - Please list the items below.
                </mat-checkbox><br/>
                <mat-form-field class="mat--form--field" appearance="standard">
                  <input class="document_other_note" matInput placeholder="Please list the items here" id="document_other_note" (change)="documentOtherNoteChange($event)"/>
                </mat-form-field>
                <p class="esim_other_note">
                  Ex: Japanese Certificate of Residence, Japanese Health Insurance Card, "Identification Card" issued by the Ministry of Foreign Affairs
                </p>
              </ng-container>
            </div>
            <br/>
          </div>
          <div *ngIf="params==='data_esim'">
            <div class="section_esim">
              <div class="title">
                <label class="title">SELECT YOUR PAYMENT METHOD <app-required></app-required></label>
              </div>
              <div class="pay_methods">
                <div class="pay_method" [ngClass]="{ select__product: willPayCredit }" (click)="onPayCredit()">
                  <p class="title">CREDIT CARD/DEBIT CARD</p>
                </div>
                <div class="pay_method" [ngClass]="{ select__product: willPayCash }" (click)="onPayCash()">
                  <p class="title">CASH</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="body__select__location--location-airport" *ngIf="outSideJapan">
          <div class="calendar--select">
            <h1 class="title">EXPECTED DATE OF ARRIVAL</h1>
            <app-calendar (selectDate)="selectDate($event)"></app-calendar>
          </div>
          <div class="airport--list">
            <h1 class="title">ARRIVING AIRPORT</h1>
            <div class="airport--name" *ngFor="let airport of airportList" (click)="selectAirport(airport)"
              [ngClass]="{ select__product: selectedAirport === airport }">
              {{ airport }}
            </div>
            <ng-container *ngIf="otherAirport">
              <div class="airport--other">
                <mat-form-field >
                  <p class="airport__label">Other</p>
                  <input class="form_input" [formControl]="otherAirportFC" matInput required/>
                  <mat-error *ngIf="otherAirportFC.hasError('required')">Required *</mat-error>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="showBasicInformation">
      <div class="body__select__location__informations">
        <div class="body__select__location__informations--title">
        <h1>BASIC INFORMATION</h1>
      </div>
        <form class="form" [formGroup]="fullNameFG">
          <div class="form__name">
            <label for="name">Name <app-required></app-required></label>
            <div class="form__name__notes">
              <mat-icon class="material-icons-outlined start-icon">star</mat-icon>
              <p>Please enter your name exactly as shown on your official ID documentation.</p>
            </div>

            <div class="form__name__fullname">
              <mat-form-field class="mat--form--field" appearance="standard" #firstNameFormField>
                <input class="field--name" matInput [placeholder]="'E.g: John Robert Smith'" name="full_name" id="fName" formControlName="firstName"
                (focus)="firstNameChange($event)" (change)="firstNameChange($event)" type="text" required autocomplete="name"/>
                <mat-error class="error--title" *ngIf="fullNameFG.get('firstName').hasError('required')">Required*</mat-error>
                <mat-error class="warning--title" *ngIf="firstName.hasError('twoSpaces')">
                  <i class="fa-solid fa-triangle-exclamation fa-sm warning--icon" style="color: #ed95bc;"></i> Please include your middle name(s) if you have one.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>

  <form class="form" [formGroup]="submitDataForm">
    <div class="end__form">
      <div class="form__image">
        <div class="form__email">
          <div class="form__minor">
            <div class="form__minor-magin" [ngClass]="{'form__minor-invalid': (firstName.touched || firstName.dirty) && firstName.invalid}">
              <mat-checkbox class="minor__checkbox" (change)="minorCheckBox($event.checked)">
                I am a minor (under 18 years old).
              </mat-checkbox>
            </div>
          </div>
          <ng-container *ngIf="isMinorCheck">
          <div class="form__email">
            <label for="email">Parent’s email address<app-required></app-required></label>
            <mat-form-field appearance="standard" class="email--form--filed" #parentEmailFormField>
              <input matInput name="email" id="email" class="email" (change)="parentEmailChange($event)" (focus)="parentEmailChange($event)"
              type="email" formControlName="parent_email" maxlength="73" required autocomplete="email"/>
              <mat-error class="error--title" *ngIf="parent_email.hasError('required')">Required*</mat-error>
              <mat-error class="error--title" *ngIf="parent_email.hasError('pattern')">Invalid email address</mat-error>
              <mat-error class="error--title" *ngIf="parent_email.hasError('conInvalid')">'.con' is not a valid email extension. Did you mean '.com'?</mat-error>
            </mat-form-field>
          </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>

        <form class="form" [formGroup]="submitDataForm">
          <div class="end__form">
            <div class="form__image">
              <div class="form__email">
                <label for="email">Email address <app-required></app-required></label>
                <mat-form-field appearance="standard" class="email--form--filed" #emailFormField>
                  <input matInput class="email" (change)="emailChange($event)" (focus)="emailChange($event)"
                  type="email" name="email" id="email" formControlName="email" maxlength="73" required autocomplete="email"/>
                  <mat-error class="error--title" *ngIf="email.hasError('required')">Required*</mat-error>
                  <mat-error class="error--title" *ngIf="email.hasError('pattern')">Invalid email address</mat-error>
                  <mat-error class="error--title" *ngIf="email.hasError('conInvalid')">'.con' is not a valid email extension. Did you mean '.com'?</mat-error>
                </mat-form-field>
                <p class="notes--info" [ngClass]="{invalid : (email.touched || email.dirty) && email.invalid}">
                  Please check the note column (※) if you are going to sign up using an old email address that has been
                  registered to Sakura Mobile.
                </p>
              </div>

            </div>
            <div class="image">
              <img src="../../../assets/images/sakura-first-step.svg" alt="step 1" />
            </div>
          </div>
        </form>
      </div>
      <div class="body__select__mnp__information" *ngIf="isCheckMNP">
        <div class="mnp__title">
          <h3 class="mnp__title--h3">
            MNP information to keep your current phone number
          </h3>
        </div>
        <form class="form" [formGroup]="mnpForm">
          <div class="form__form__field">
            <p class="form__label">Name on your previous contract (MNP)<app-required></app-required></p>
            <mat-form-field appearance="standard" #nameFormField>
              <input matInput placeholder="E.g: John Smith" formControlName="name"
              (change)="nameChange($event)"
              (focus)="nameChange($event)"
              required 
              />
              <mat-error *ngIf="
                  this.name.hasError('required')
                ">Required*</mat-error>
              <mat-hint class="form__hint">Must be the same name as the new contractor</mat-hint>
            </mat-form-field>
          </div>

          <div class="form__form__field">
            <p class="form__label">Kana (Phonics)<app-required></app-required></p>
            <mat-form-field appearance="standard" #kanaFormField>
              <input matInput placeholder="E.g: ジョーン スミス" formControlName="kana"
              (change)="kanaChange($event)"
              (focus)="kanaChange($event)"
              required 
              />
              <mat-error *ngIf="this.kana.hasError('required')">Required*</mat-error>
              <mat-error *ngIf="this.kana.hasError('isKana')">
                Katakana only
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form__form__field">
            <p class="form__label label--sex">Sex<app-required></app-required></p>
            <mat-radio-group formControlName="sex" #sexFormField (change)="sexChange($event)" required>
              <mat-radio-button value="male">Male</mat-radio-button>
              <mat-radio-button value="female">Female</mat-radio-button>
            </mat-radio-group>
            <mat-error class="mat-error-sex" *ngIf="(sex.touched || sex.dirty) && sex.hasError('required')">Select one</mat-error>
          </div>

          <div class="form__form__field">
            <p class="form__label">Date of birth<app-required></app-required></p>
            <mat-form-field appearance="standard" class="form--field calendar-form-field" (click)="picker.open()" #birthDayFormField>
              <input matInput placeholder="Select from calendar only"
              (dateChange)="birthDayChange($event)" name="bday" id="bday"
              [matDatepicker]="picker" formControlName="birth_date" required readonly autocomplete="bday" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker [startAt]="startDate" disabled="false"></mat-datepicker>
              <mat-error *ngIf="this.birth_date.hasError('required')">Required*</mat-error>
            </mat-form-field>
          </div>

          <div class="form__form__field">
            <p class="form__label">Current cellphone number<app-required></app-required></p>
            <mat-form-field appearance="standard" class="form--field" #currentPhoneFormField>
              <input matInput formControlName="current_phone_number"
              (change)="currentPhoneChange($event)"
              (focus)="currentPhoneChange($event)"
              minlength="11" required autocomplete="tel-national"/>
              <mat-error *ngIf="
                 this.current_phone_number
                    .hasError('required')
                ">Required*</mat-error>
              <mat-error *ngIf="
                  this.current_phone_number
                    .hasError('minlength')
                ">Minimum 11 number</mat-error>
            </mat-form-field>
          </div>

          <div class="form__form__field">
            <p class="form__label">MNP reservation number<app-required></app-required></p>
            <mat-form-field appearance="standard" class="form--field" #reservationPhoneFormField>
              <input matInput placeholder="0000000000"
              (change)="reservationPhoneChange($event)"
              (focus)="reservationPhoneChange($event)"
              formControlName="mnp_reservation_number" maxlength="10" required/>
              <mat-error *ngIf="
              this.mnp_reservation_number.invalid
            ">{{getReservationErr()}}</mat-error>
            </mat-form-field>
          </div>

          <div class="form__form__field">
            <p class="form__label">MNP reservation expiry date<app-required></app-required></p>
            <mat-form-field appearance="standard" (click)="pickerExpireDate.open()" class="form--field calendar-form-field" #expiryDateFormField >
              <input matInput placeholder="Select from calendar only"
              (dateChange)="expiryDateChange($event)"
              [matDatepicker]="pickerExpireDate" [matDatepickerFilter]="myFilterDate"
                formControlName="mnp_reservation_expire_date" required readonly/>
              <mat-datepicker-toggle matSuffix [for]="pickerExpireDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerExpireDate></mat-datepicker>
              <mat-error *ngIf="
                 this.mnp_reservation_expire_date
                    .hasError('required')
                ">Required*</mat-error>
              <mat-hint class="form__hint">At least 8 days until expiry is required.</mat-hint>
            </mat-form-field>
          </div>
          <div class="form__form__field">
            <p class="form__label">Current provider</p>
            <mat-form-field appearance="standard">
              <mat-select formControlName="mnp_incoming_provider" >
                <mat-option *ngFor="let provider of providers" [value]="provider">
                  {{ provider }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="body__notes">
        <h1 class="title">NOTE</h1>
        <p class="info">
          ※ According to Japanese law,<span class="info__minor"> minors (under 18 years old) need a Parental
          Consent Form to sign up for a phone/WiFi contract.</span>
          <span class="info__bold__character"> If you are a minor,
          please check the box above and provide your parent’s email address.</span>
          We will send a link to submit an online Parental Consent Form to your parents via the email address provided.<br />
          <br />
          <span class="info__minor">※ If you use an old email address that has been registered to Sakura Mobile, the new order will be made using
          the old data.</span>
          Please insert an email address that belongs to you. It is not possible to use someone else’s
          email address.
          Also, on principle, all contact will be made via email. Therefore, please insert an email that you can
          frequently check.
        </p>
      </div>
      <button class="next--setep submitted" (click)="openFullNameDialog()">
        NEXT STEP
      </button>
    </section>
  </div>
</div>

