import { Component, Renderer2, Inject, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { LongOrderService } from "src/app/services/long-order.service";
import { MatSnackBar } from '@angular/material';
import { Constants } from 'src/app/constants/constants';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FinishComponent implements OnInit, AfterViewInit {
  private queryParams: any;
  public affi_contract_code: string;
  public email: string;
  public s3d: string;
  public done: boolean = false;
  constructor(
    @Inject('windowObject') private window: Window,
    private _activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private longOrderServices: LongOrderService,
    private snackbar: MatSnackBar,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(
      params => {
        this.queryParams = params;
        if (this.queryParams.contract_code) {
          // tslint:disable-next-line:no-string-literal
          this.affi_contract_code = this.queryParams['contract_code'];
        }
        if (this.queryParams.email) {
          // tslint:disable-next-line:no-string-literal
          this.email = this.queryParams['email'];
        }
        if (this.queryParams.hash) {
          // tslint:disable-next-line:no-string-literal
          this.s3d = this.queryParams['hash'];
        }
        if (this.queryParams.d && this.queryParams.d === '1') {
          this.done = true;
        }
      }
    );
  }
  ngAfterViewInit() {
    console.log("FinishComponent::ngAfterViewInit");
    if (this.done) {
      // automatically redirected to the survey page after 10 seconds
      if (this.email && this.affi_contract_code) {
        setTimeout(() => {
          // tslint:disable-next-line:max-line-length
          this.window.location.href = `https://docs.google.com/forms/d/e/1FAIpQLScZDpYctpHxWn1Keri3jWPjdwYrDs-f2eJjYANX1aHV2zDW6Q/viewform?entry.583804134=${this.email}&entry.2034490624=${this.affi_contract_code}`, '_blank';
        }, 10000);
      }
    }
    else {
      if (this.s3d) {
        this.completeConfirmation();
      }
      else {
        this.longOrderServices.Completed(this.affi_contract_code, this.email);
      }
    }
  }

  public completeConfirmation(): void {
    const data = {
      s3d : this.s3d
    };
    this.longOrderServices.SubmitDone(data).subscribe(
      res => {
        console.log('completeConfirmation res', res);
        this.done = true;
        console.log("FinishComponent::Success to complete the s3d process", res);
      },
      err => {
        console.log('completeConfirmation err', err);
        console.log("FinishComponent::Failed to complete the s3d process", err);
        if (err.error && err.error.data) {
          this.email = err.error.data.email;
        }
        let message = 'Failed to complete the payment process';
        if (err.error && err.error.data && err.error.data.error) {
          message = err.error.data.error;
        }
        this.snackbar.dismiss();
        this.snackbar.open(
          message,
          'OK',
          Constants.defaultSnackBarConfig('error')
        );
      }
    );
  }
}
