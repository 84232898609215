import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { OrderInformation } from 'src/app/types/order-information';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-product-ordered',
  templateUrl: './product-ordered.component.html',
  styleUrls: ['./product-ordered.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductOrderedComponent implements OnInit, AfterViewChecked {
  constructor(
    private breakpointObserver: BreakpointObserver, 
    private cdt: ChangeDetectorRef,
    private titleCasePipe: TitleCasePipe
  ) { }
  public isSmallScreen = false;
  @Input() orderInformation: OrderInformation;
  @Input() typeProduct: string;
  ngOnInit() {
    this.orderInformation.data.products.filter(products => {
      products.filter(product => {
        product.category_type = this.titleCasePipe.transform(product.category_type).replace('Sim', 'SIM');
      });
    });
  }
  ngAfterViewChecked() {
    this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 480px)');
    this.cdt.detectChanges();
  }
}
