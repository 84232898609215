import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VoiceDataSim } from 'src/app/types/voice-data';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';


@Component({
  selector: 'app-voice-data-sim-plan',
  templateUrl: './voice-data-sim-plan.component.html',
  styleUrls: ['./voice-data-sim-plan.component.scss']
})
export class VoiceDataSimPlanComponent implements OnInit {
  isPlans: string;
  isSelectVoiceSim: boolean;
  @Input() index = 0;
  @Input() quantity: number;
  @Input() voiceDataPlan: VoiceDataSim;
  // @Output() selectTitleVoiceDataSimEvent = new EventEmitter<string>();
  voiceDataSimFG: FormGroup;
  voiceDataSimFormArray: FormArray;
  constructor(private fb: FormBuilder) { }
  ngOnInit() {
    this.isSelectVoiceSim = false;
    this.createFormGroup();
    // this.voiceDataSimFG.valueChanges.subscribe(res => console.log(res));
  }
  selectVoiceDataSimPlan(value: string) {
    // this.selectTitleVoiceDataSimEvent.emit(value);
    console.log(`VoiceDataSimPlanComponent::selectVoiceDataSimPlan : value=${value}`);
    this.isSelectVoiceSim = true;
    this.isPlans = value;
    const found = this.voiceDataPlan.plans.find( item => item.plan_title === value);
    this.voiceDataSimFG.patchValue({
      category_type_id: this.voiceDataPlan.category_id,
      category_type: this.voiceDataPlan.category_title,
      product_type_id: found.product_type_id,
      product_type: found.product_type
    });
  }
  public get getCategoryTitle() {
    console.log(`VoiceDataSimPlanComponent::getCategoryTitle : category title=`, this.voiceDataPlan.category_title);
    if (this.voiceDataPlan) {
      return this.voiceDataPlan.category_title;
    }
    return null;
  }
  private createFormGroup() {
    this.voiceDataSimFG = this.fb.group({
      category_type_id: [null, Validators.required],
      category_type: [null, Validators.required],
      product_type_id: [null, Validators.required],
      product_type: [null, Validators.required],
      options: this.fb.array([])
    });
    this.voiceDataSimFormArray = this.fb.array([this.voiceDataSimFG]);
  }
}
