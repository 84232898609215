import { Component } from '@angular/core';
import {  NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // public loading: boolean;
  constructor(private readonly route: Router) {
    // this.loading = false;
    // this.route.events.subscribe(
    //   (event: RouterEvent) => {
    //    if (event instanceof NavigationStart) {
    //      this.loading = true;
    //    } else if (event instanceof NavigationEnd) {
    //      this.loading  = false;
    //    }
    //   }
    // );
  }
}
