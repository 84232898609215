import { DecimalPipe, TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/types/product';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {

  @Input() flatArray: Product[];
  @Output() depositTotalEvent = new EventEmitter();
  deposit: Array<{ product_name: string; price: number }> = [];
  depositTotal: number;
  constructor(
    private titleCasePipe: TitleCasePipe,
    private decimePipe: DecimalPipe
  ) { }

  ngOnInit() {
    this.flatArray.forEach(item => {
      const fee = Number(item.monthly_fee);
      const insuranceFee = Number(item.options.monthly_fee);
      console.log('item.category_type', item);
      if (item.category_type.toUpperCase() === 'VOICE + DATA SIM CARD') {
        this.deposit.push({
          product_name: this.titleCasePipe.transform(item.category_type).replace('Sim', 'SIM'),
          price: 20000
        }); // item.category_type.toUpperCase() === 'VOICE + DATA Esim') {
      } else if (item.category_type.toUpperCase() === 'VOICE + DATA ESIM') {
        this.deposit.push({
          product_name: this.titleCasePipe.transform(item.category_type).replace('Esim', 'eSIM'),
          price: 20000
        }); //  {
      } else {
        this.deposit.push({
          // tslint:disable-next-line:max-line-length
          product_name: `${this.titleCasePipe.transform(item.category_type).replace('Sim', 'SIM')} (¥${this.decimePipe.transform(item.monthly_fee)} x 2 month + VAT)`,
          price: ((fee * 2) + (fee * 2) * 0.1)
        });
        if (item.options.monthly_fee) {
          this.deposit.push({
            // tslint:disable-next-line:max-line-length
            product_name: `${this.titleCasePipe.transform(item.options.category_type)} (¥${this.decimePipe.transform(item.options.monthly_fee)} x 2 month + VAT)`,
            price: ((insuranceFee * 2) + (insuranceFee * 2) * 0.1)
          });
        }
      }
    });
    this.depositTotal = this.deposit.reduce((acc, curr) => acc + curr.price, 0);
    this.depositTotalEvent.emit(this.depositTotal);
  }
}
