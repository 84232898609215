<div class="confirmation__payment">
  <header>
    <app-header [step]="4"></app-header>
  </header>
  <div class="confirmation__payment__contents">
    <h1 class="header">confirm registered information</h1>
    <!-- Registered information component -->
    <app-registered-information (titleCouponCode)="titleCouponCode($event)"
      (pickupMethodEvent)="pickupMethodEvent($event)" (pickupInformation)="pickupInformation($event)">
    </app-registered-information>
    <!-- End registered information component -->
    <h1 class="header">Submit payment information</h1>
    <div class="card__information">
      <!-----Register new card component-->
      <ng-container *ngIf="paymentMethod === 'CREDIT CARD'">
        <app-register-new-card></app-register-new-card>
      </ng-container>
      <!-----End Register new card component-->
    </div>
    <div class="add__referral__code">
      <h3 class="add__referral__code__title">
        add referral code for “Give 3000 Get 3000” campaign
      </h3>
      <div class="add__referral__code__form">
        <mat-form-field class="form__field">
          <input matInput class="input__field" [value]="refferralCodeField.value" [formControl]="refferralCodeField"
            [readonly]="avaibleReferralCode">
          <mat-error *ngIf="refferralCodeField.hasError('maxlength')">Max length 8 number</mat-error>
          <mat-error *ngIf="refferralCodeField.hasError('pattern')">Must only numbers</mat-error>
        </mat-form-field>
        <button class="confirm__button" [class.apply]="validField" [disabled]="!validField || avaibleReferralCode"
          (click)="refferralDiscountConfirm()">Confirm</button>
      </div>
    </div>
    <div class="message__refferal__code valid__code" *ngIf="refferralCodeValid">
      <p>Referral code applied</p>
    </div>
    <div class="message__refferal__code invalid__code" *ngIf="refferralCodeInvalid">
      <p>Invalid referral code</p>
    </div>
    <div class="campaign">
      <mat-accordion>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header class="campaign-help-accordion">
            <mat-panel-title>
              <mat-icon class="help-icon">help</mat-icon>
            </mat-panel-title>
            <mat-panel-description class="give-3000-campaign">
              Notice about the “Give 3000 Get 3000” campaign
            </mat-panel-description>
            <div class="drop-down">
              <mat-icon class="drop-down-icon">arrow_drop_down</mat-icon>
            </div>
          </mat-expansion-panel-header>
          <div class="body__expansion--body">
            <p class="expansion--title">
              <p class="expansion--title"> ※ This campaign may end without prior notice.</p>
              <p class="expansion--title"> ※ You and your friend will only receive the points once the new contract has
                been completed and after the invited person pays the first bill.</p>
              <p class="expansion--title"> ※ You cannot invite yourself.</p>
              <p class="expansion--title"> ※ The same 2 customers cannot invite each other.</p>
              <p class="expansion--title example"> Example: 1) Person A invites Person B</p>
              <p class="expansion--title person__b">2) Person B invites Person A</p>
              <p class="expansion--title example">In this case, only the first invitation will be recognized.</p>
              <p class="expansion--title"> ※ The points you earned can be used to purchase products from Sakura Mobile
                or exchanged for gift cards.
              </p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="checkbox__confirm">
      <mat-checkbox (change)="checkboxChange($event.checked)" #checkbox></mat-checkbox>
      <p [ngClass]="{'error-not-checked': isCheckboxErr}" class="checkbox__confirm__label">I confirm the registered
        information is correct and agree that by submitting this form, I will pay for the listed initial fee.</p>
    </div>
    <div *ngIf="isCheckboxErr" class="checkbox__error"><span>Please check</span></div>
    <button class="submit__button submitted" [class.disabled]="loading$ |async" (click)="handleSubmitButton()">submit
      <app-spinner [loading]="loading$ |async"></app-spinner></button>
  </div>
</div>
