<ng-container *ngIf="voiceDataPlan">
  <h3 class="monthly--data-title">SELECT YOUR MONTHLY {{ getCategoryTitle }} PLAN (Set {{ index + 1 }})</h3>
  <div class="data--monthly">
    <div
      class="data"
      *ngFor="let plan of voiceDataPlan.plans"
      (click)="selectVoiceDataSimPlan(plan.plan_title)"
      [ngClass]="{ select__product: isPlans === plan.plan_title }"
    >
      <p class="data__amount--data">{{ plan.plan_title }}</p>
      <p class="data__price">{{ plan.monthly_fee | number }} JPY/MO</p>
      <p class="data__price__taxincl">({{ plan.monthly_fee * 1.1 | number }} JPY/MO, tax incl.)</p>
      <p class="data__notice">{{ plan.plan_description }}</p>
    </div>
  </div>
  <p class="data--monthly__des">※The first month’s data amount, and base monthly fee are prorated.</p>
  <hr class="separator-line" *ngIf="quantity > 1" />
</ng-container>
