import { Component, Input, OnInit } from '@angular/core';
import { LongOrderService } from 'src/app/services/long-order.service';

@Component({
  selector: 'app-upload-spinner',
  templateUrl: './upload-spinner.component.html',
  styleUrls: ['./upload-spinner.component.scss']
})
export class UploadSpinnerComponent implements OnInit {
  loading$ = this.longOrderService.loading$;
  constructor( private longOrderService: LongOrderService) {}
  ngOnInit() {}
}
