import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LongOrderService } from 'src/app/services/long-order.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-card-blocked',
  templateUrl: './card-blocked.component.html',
  styleUrls: ['./card-blocked.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardBlockedComponent implements OnInit {
  private sub = new SubSink();
  contract_hash: string;
  request_box: FormControl;
  requestFG: FormGroup;
  constructor(
    private router: Router,
    private orderService: LongOrderService
  ) { }

  ngOnInit() {
    this.request_box = new FormControl('', Validators.required);
    this.requestFG = new FormGroup({
      request_box: this.request_box
    });
    this.sub.sink = this.orderService.contractHash$.subscribe(hash => this.contract_hash = hash);
  }
  onSubmit() {
    if (this.requestFG.invalid) {
      this.request_box.markAsTouched();
      return;
    }
    const body = {
      contract_hash: this.contract_hash,
      request_box: this.request_box.value
    };
    this.orderService.unlockCardRequest(body);
  }
  goBackStep2() {
    this.router.navigate(['/step2'], { queryParams: { order: this.contract_hash }});
  }

}
