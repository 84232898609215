<table>
  <tr>
    <th [rowSpan]="deposit.length + 1" class="deposit">deposit</th>
  </tr>
  <tr *ngFor="let item of deposit">
    <td>{{ item.product_name }}</td>
    <td class="price__amount">¥{{ item.price | number }}</td>
  </tr>
  <!-- <tr *ngFor="let item of insurances">
    <td>{{ item.insurance_name }}</td>
    <td class="price__amount">¥{{ item.insurance_fee | number }}</td>
  </tr> -->
  <tr>
    <td class="tr--line"></td>
    <td colspan="2"><hr class="horizontal--line tr--line" /></td>
  </tr>
  <tr></tr>
  <tr>
    <td></td>
    <th>DEPOSIT TOTAL</th>
    <td class="price__amount">¥{{ depositTotal | number }}</td>
  </tr>
</table>
