import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit {
  date: Date;
  titleCalendar: string | Date;
  filterDate: Date;
  constructor(
    private _datePipe: DatePipe
  ) { }
  @Output() selectDate = new EventEmitter();
  ngOnInit() {
    this.titleCalendar = 'Please choose from calendar';
    this.filterDate  = new Date();
  }
  selectedChange(event: Date) {
    console.log(event);
    this.selectDate.emit(event);
    this.date = event;
    this.titleCalendar = this._datePipe.transform(event, 'mediumDate');
  }
}
