<ng-container *ngIf="homeWifiPlan">
  <h3 class="home-wifi-plan-title">SELECT YOUR {{ getCategoryTitle }} PLAN </h3>
    <div
    class="home-wifi-plan-button"
      *ngFor="let plan of homeWifiPlan.plans"
      (click)="selectHomeWifiPlan(plan.plan_title)"
      [ngClass]="{ select__product: isPlans === plan.plan_title }"
    >
      <!-- <p class="home-wifi-plan-speed">{{ plan.plan_title }}</p> -->
      <p class="home-wifi-plan-speed">{{ plan.plan_title.trim().toUpperCase() === 'HOME WIFI' ? 'Unlimited' : plan.plan_title }}</p>
      <p class="home-wifi-plan-price">{{ plan.monthly_fee | number }} JPY/MO</p>
      <p class="home-wifi-plan-tax">({{ plan.monthly_fee * 1.1 | number }} JPY/MO, tax incl.)</p>
      <!-- <p class="home-wifi-plan-tax">{{ plan.plan_description }}</p> -->
    </div>

  <!-- <p class="data--monthly__des">※The first month’s data amount, and base monthly fee are prorated.</p> -->
  <p class="home-wifi-plan-message">※If the signal coverage is poor in your area, we will contact you within 24 hours to provide alternative services such as our Fiber Internet.</p>
  <!-- <hr class="separator-line" *ngIf="quantity > 1" /> -->
</ng-container>
