import { Component, OnInit, Input } from '@angular/core';
import { HomeWifi } from 'src/app/types/home-wifi';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-home-wifi-plan',
  templateUrl: './home-wifi-plan.component.html',
  styleUrls: ['./home-wifi-plan.component.scss']
})
export class HomeWifiPlanComponent implements OnInit {
  isPlans: string;
  isSelectHomeWifi: boolean;
  @Input() index = 0;
  @Input() quantity: number;
  @Input() homeWifiPlan: HomeWifi;
  homeWifiFG: FormGroup;
  homeWifiFormArray: FormArray;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.isSelectHomeWifi = false;
    this.createFormGroup();
  }
  selectHomeWifiPlan(value: string) {
    console.log(`HomeWifiPlanComponent::selectHomeWifiPlan : value=${value}`);
    this.isSelectHomeWifi = true;
    this.isPlans = value;
    const found = this.homeWifiPlan.plans.find( item => item.plan_title === value);
    this.homeWifiFG.patchValue({
      category_type_id: this.homeWifiPlan.category_id,
      category_type: this.homeWifiPlan.category_title,
      product_type_id: found.product_type_id,
      product_type: found.product_type,
      options: [],
    });
  }
  public get getCategoryTitle() {
    console.log(`HomeWifiPlanComponent::getCategoryTitle : category title=`, this.homeWifiPlan.category_title);
    if (this.homeWifiPlan) {
      return this.homeWifiPlan.category_title;
    }
    return null;
  }
  private createFormGroup() {
    this.homeWifiFG = this.fb.group({
      category_type_id: [null, Validators.required],
      category_type: [null, Validators.required],
      product_type_id: [null, Validators.required],
      product_type: [null, Validators.required],
      options: this.fb.array([])
    });
    this.homeWifiFormArray = this.fb.array([this.homeWifiFG]);
  }
}
