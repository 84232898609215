import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, finalize, retry } from 'rxjs/operators';
import { Constants } from '../constants/constants';
import { ProductInformation } from '../types/product-information';
import { Router } from '@angular/router';
import { OrderInformation } from '../types/order-information';
import { ActivePaymentMethod } from '../types/active-payment-method';
import { EkycInformation } from '../types/ekyc-information';
import { AirportInformation } from '../types/airport-information';
import { OfficeInformation } from '../types/office-information';
import { CouponDiscount } from '../types/coupon-discount';
import { RefferralDiscount } from '../types/refferral-discount';
import { MatDialog } from '@angular/material';
import { DialogMessageComponent } from '../components/shared/dialog-message/dialog-message.component';
import { CouponInformation } from '../types/coupon-information';
import { CreditCardAvailable } from '../types/credit_card_available';
import { environment } from 'src/environments/environment';

type ActiveMethod = 'NONE' | 'CREDIT CARD' | 'SMARTPIT';

// Event snippet for Long TERM 新フォーム申し込み conversion page
declare function gtag_report_conversion(url: string): boolean;

@Injectable({
  providedIn: 'root'
})
export class LongOrderService {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private contractHash: string;
  // public couponCodeInformation$: Observable<CouponInformation>;

  private orderInformationSubject = new BehaviorSubject<OrderInformation>(null);
  public orderInformation$ = this.orderInformationSubject.asObservable();

  private contractHashSubject = new BehaviorSubject<string>('');
  public contractHash$ = this.contractHashSubject.asObservable();

  private subcriptionType = new BehaviorSubject<string>(''); // voice_wifi || wifi || voice || data
  public subcriptionType$ = this.subcriptionType.asObservable();

  private paymentMEthodSubject = new BehaviorSubject<string>('');
  public paymentMEthod$ = this.paymentMEthodSubject.asObservable();

  private checkDelivery = new BehaviorSubject<boolean>(false);
  public isDelivery$ = this.checkDelivery.asObservable();

  // Pickup method BehaviorSubject
  private pickupMethod = new BehaviorSubject<string>('');
  public pickupMethod$ = this.pickupMethod.asObservable(); // AIRPORT || OFFICE || DELIVERY
  private ekycInformationSubject = new BehaviorSubject<EkycInformation>(null); 
  public ekycInformation$ = this.ekycInformationSubject.asObservable();
  // Airport information subject
  private airportInformationSubject = new BehaviorSubject<AirportInformation>(null);
  public airportInformation$ = this.airportInformationSubject.asObservable();

  // Office information subject
  private officeInformationSubject = new BehaviorSubject<OfficeInformation>(null);
  public officeInformation$ = this.officeInformationSubject.asObservable();
  // Delivery information subject
  private deliveryInformationSubject = new BehaviorSubject<any>(null);
  public deliveryInformation$ = this.deliveryInformationSubject.asObservable();
  // Coupon code
  private couponCodeSubject = new BehaviorSubject<string>(null);
  public couponCode$ = this.couponCodeSubject.asObservable();
  // Referral code
  private referralCodeSubject = new BehaviorSubject<string>(null);
  public referralCode$ = this.referralCodeSubject.asObservable();

  // check is student
  private isStudentSubject = new BehaviorSubject<boolean>(false);
  public isStudentCheck$ = this.isStudentSubject.asObservable();
  // payment method
  private activePaymentMethodSubject = new BehaviorSubject<string>('');
  public activePayMentMethod$ = this.activePaymentMethodSubject.asObservable();

  private activeMethodInformationSubject = new BehaviorSubject<any>(null);
  public activeMethodInformation$ = this.activeMethodInformationSubject.asObservable();
  backImage$: Observable<boolean> = combineLatest(this.subcriptionType$, this.isDelivery$).pipe(
    map(data => {
      const [sub, isDe] = data;
      if ((sub === 'voice' || sub === 'voice_wifi') || isDe === true) {
        return true;
      } else {
        return false;
      }
    })
  );
  constructor(
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    public dialog: MatDialog
  ) { }
  // Set Loading Subject
  set setLoadingEvent(event: boolean) {
    this.loadingSubject.next(event);
  }
  set setOrderInformation(order: OrderInformation) {
    this.orderInformationSubject.next(order);
  }
  set setPaymentMethod(method: string) {
    this.paymentMEthodSubject.next(method);
  }
  // set contract hash
  set setContractHash(data: string) {
    this.contractHash = data;
    this.contractHashSubject.next(data);
  }
  // set subcription type
  set setSubcriptiontype(data: string) {
    this.subcriptionType.next(data);
  }
  // set delivery check ?
  set setIsDelivery(data: boolean) {
    this.checkDelivery.next(data);
  }
  // set pickup method
  set setPickupMethod(pickup: string) {
    this.pickupMethod.next(pickup);
  }
  // store airport information
  set setEkycInformation(information: EkycInformation) {
    // console.log('Ekyc information setted', information);
    this.ekycInformationSubject.next(information);
  }
  // store airport information
  set setAirportInformation(information: AirportInformation) {
    // console.log('Airport information setted', information);
    this.airportInformationSubject.next(information);
  }
   // store office information
   set setOfficeInformation(information: OfficeInformation) {
    // console.log('Office information setted', information);
    this.officeInformationSubject.next(information);
  }
  // store delivery information
  set setDeliveryInformation(information: OfficeInformation) {
    // console.log('Delivery information setted', information);
    this.deliveryInformationSubject.next(information);
  }
  // set is student check
  set checkStudentCheck(value: boolean) {
    this.isStudentSubject.next(value);
  }
  set setCouponCode(coupon: string) {
    console.log('coupon code is setted', coupon);
    this.couponCodeSubject.next(coupon);
    // this.couponCodeInformation$ = this.CouponCodeInformation(coupon);
  }
  set setReferralCode(code: string) {
    console.log('referral code is setted');
    this.referralCodeSubject.next(code);
  }
  // set active payment method
  set setActivePaymentMethod(value: string) { // NONE | CREDIT CARD | SMARTPIT
    this.activePaymentMethodSubject.next(value);
  }
  set setActiveMethodInformation(info: any) {
    this.activeMethodInformationSubject.next(info);
  }

  public getloadingValue(): boolean {
    return this.loadingSubject.value;
  }

  // Get plans information
  public getPlansInformation(value: string): Observable<any> {
    const params = new HttpParams().set('products', value);
    return this.http.get<ProductInformation>(Constants.GET_PLAN_INFORMATION, { params }).pipe(
      map(result => result.data),
      shareReplay(),
      catchError(this.handleError)
    );
  }

  // Handle basic information in step 1
  public submmitBasicInformation(data: any) {
    return this.http.post(Constants.API_BASIC_INFORMATION, { new_order_data: data });
  }

  // Get order information
  public getOrderInformation(contracthash: string): Observable<[OrderInformation, ActivePaymentMethod]> {
    const params = new HttpParams().set('contract_hash', contracthash);
    const orderInformation$ = this.http.get<OrderInformation>(Constants.API_GET_ORDER_INFORMATION, { params });
    const activePaymentMethod$ = this.http.get<ActivePaymentMethod>(Constants.API_GET_ACTIVE_PAYMENT_METHOD, { params });
    return combineLatest(orderInformation$, activePaymentMethod$).pipe(
      shareReplay(),
      catchError(this.handleError)
    );
  }
  // Check credit card availablity
  public creditCardAvailablity(email: string): Observable<boolean> {
    const params = new HttpParams().append('email', email);
    return this.http.get<CreditCardAvailable>(Constants.API_CREDIT_CARD_AVAILABLE, { params }).pipe(
      map(data => data.data.available),
      catchError(this.handleError)
    );
  }
  public unlockCardRequest(body: { contract_hash: string, request_box: string}) {
    this.http.post(Constants.API_UNLOCK_CARD_REQUEST, body).pipe(
      catchError(this.handleError)
    ).subscribe(
      data => {
        this.router.navigate(['/step2'], { queryParams: { order: body.contract_hash }});
      }
    );
  }

  // Upload documents
  public uploadFile(dataUpload): Observable<any> {
    const formdata = new FormData();
    formdata.append('contract_hash', dataUpload.contract_hash);
    dataUpload.documents.forEach(element => {
      formdata.append('documents[][data]', element.data);
      formdata.append('documents[][file_number]', element.file_number);
    });
    return this.http.post(Constants.API_UPLOAD_FILES , formdata)
    .pipe(
      catchError(this.handleError)
    );
  }

  // Get coupon discount
  public GetCouponDiscount(search: string, contracthash: string): Observable<CouponDiscount> {
    let params = new HttpParams();
    params = params.append('code', search);
    params = params.append('contract_hash', contracthash);
    return this.http.get<CouponDiscount>(Constants.API_COUPON_DISCOUNT, { params })
      .pipe(
        retry(1),
        catchError(err => {
          return of(null);
        }),
      );
  }
  // GET: Coupon information
  public CouponCodeInformation(code: string): Observable<CouponInformation> {
    const params = new HttpParams().append('coupon_code', code);
    return this.http.get<CouponInformation>(Constants.API_COUPON_INFO, {params});
  }

  // Get refferral code
  public RefferalCodeDiscount(code: string): Observable<RefferralDiscount> {
    console.log('Private contract hash:: LongorderServices', this.contractHash);
    let params = new HttpParams();
    params = params.append('code', code);
    return this.http.get<RefferralDiscount>(Constants.API_REFFERRAL_CODE, { params });
  }
  // tslint:disable-next-line:variable-name
  public Completed(contract_code: string, email: string) {
    if (contract_code && email) {
      if (environment.environment == 'production') {
        gtag_report_conversion(`https://longterm-order.sakuramobile.jp/completed?contract_code=${contract_code}&email=${email}&d=1`);
      }
      else if (environment.environment == 'staging') {
        gtag_report_conversion(`https://stg-longterm-order.sakuramobile.jp/completed?contract_code=${contract_code}&email=${email}&d=1`);
      }
      else {
        gtag_report_conversion(`http://localhost:4201/completed?contract_code=${contract_code}&email=${email}&d=1`);
      }
    } else {
      window.location.href = '/completed?d=1';  // d=1 : force complete
    }
  }
  // Submitted Order Informations
  public SubmittedOrder(data: any, email: string): void {
    this.http.post(Constants.API_SUBMIT_ORDER, data)
      .subscribe(res => {
        const raw_res = <any>res;
        this.zone.run(() => {
          this.loadingSubject.next(false);
          if (raw_res.data && raw_res.data.contract && raw_res.data.contract.s3d) {
            window.location.href = raw_res.data.contract.s3d;
          }
          else {
            // smartpit
            this.Completed(data.contract_code, email);
          }
        })
      }, err => {
        this.zone.run(() => {
          const param = {
            data: { data: err.error }
          };
          this.dialog.open(DialogMessageComponent, param);
          this.loadingSubject.next(false);
        });
      });
  }

  // Submit Complete Confirmation
  public SubmitDone(data: any): Observable<any> {
    return this.http.post(Constants.API_SUBMIT_ORDER_DONE, data);
  }
  
  // Handle Error
  private handleError(err) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else if (err.status && err.body ){
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    } else {
      console.log('Unhundled error occured', err);
      errorMessage = 'Unhundled error occured';
    }
    return throwError(errorMessage);
  }
}
