<div class="office">
  <h4 class="office__notes">Notes:</h4>
  <p class="office__information">
    ※ You can visit our Shinjuku Office to pick up your order any time during our operating hours.
  </p>
  <p class="office__information">
    ※ For pickups at any location other than Shinjuku Office, we will check our stock and contact you within 24 hours.
  </p>

  <div class="office__container">
    <form class="office__container__form" [formGroup]="office_information">
      <div class="form--item pickup__location">
        <label class="label">pick up location
          <app-required></app-required>
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard" #pickupLocationFF>
            <mat-select disableRipple [placeholder]="'Select'"
              formControlName="pick_up_location"
              (selectionChange)="selectionChange($event.value)">
              <mat-option *ngFor="let office of officeList" [disabled]="dataJson.office_candidates.shinjuku && !dataJson.office_candidates.other ?
              (office.title !== 'SHINJUKU OFFICE (Near Shinjuku Station)')
              : false" [value]="office.title">{{formatOfficeTitle(office)}}</mat-option>
            </mat-select>
            <mat-error *ngIf="pick_up_location.hasError('required')">Select one</mat-error>
          </mat-form-field>
        </div>
        <ng-container *ngIf="officeKey === 'shinjuku'" [ngTemplateOutlet]="shinjuku">
        </ng-container>
        <ng-container *ngIf="officeKey === 'yokosuka'"
          [ngTemplateOutlet]="yokosuka"></ng-container>
        <ng-container *ngIf="officeKey === 'yokota'" [ngTemplateOutlet]="yokota">
        </ng-container>
        <!-- <ng-container *ngIf="officeKey === 'zama'"
          [ngTemplateOutlet]="zama"></ng-container> -->
        <ng-container *ngIf="officeKey === 'iwakuni'"
          [ngTemplateOutlet]="iwakuni"></ng-container>
        <ng-container *ngIf="officeKey === 'sasebo'" [ngTemplateOutlet]="sasebo">
        </ng-container>
        <!-- <ng-container *ngIf="officeKey === 'okinawa'" [ngTemplateOutlet]="okinawa">
        </ng-container> -->
        <ng-container *ngIf="officeKey === 'shibuya'" [ngTemplateOutlet]="shibuya">
        </ng-container>
        <ng-container *ngIf="officeKey === 'afstokyo'" [ngTemplateOutlet]="afstokyo">
        </ng-container>
        <ng-container *ngIf="officeKey === 'mansfield'" [ngTemplateOutlet]="mansfield">
        </ng-container>
      </div>

      <div class="form--item pickup__date">
        <label class="label">
          PICK UP DATE (JAPAN TIME)
          <app-required></app-required>
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard" class="form--field" #pickupDateFF (click)="picker.open()">
            <input matInput placeholder="Select date"
              formControlName="pick_up_date" [matDatepicker]="picker"
              [min]="minDate" required readonly/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker (closed)="checkDatePickerEvent(picker)"></mat-datepicker>
            <mat-error *ngIf="pick_up_date.hasError('required')">Required *</mat-error>
          </mat-form-field>
        </div>
        <span class="after_submission">{{officeNotice}}</span>
      </div>
      <div class="form--item estimate__time__arrival">
        <label class="label">Estimated time of arrival
          <app-required></app-required>
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard" #etaFF>
            <mat-select disableRipple [placeholder]="'ETA to your pick up place'"
              (selectionChange)="etaSelectedChange($event)"
              formControlName="estimated_time_of_arrival">
              <ng-container *ngFor="let time of estimateTimeArrival">
                <mat-option *ngIf="isOfficeHours(time)" [value]="time.term">
                  {{time.term}}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="estimated_time_of_arrival.hasError('required')">Select one</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form--item note">
        <label class="label">
          note
        </label>
        <div class="form__name">
          <mat-form-field appearance="standard">
            <textarea matInput
              placeholder="Please use this box if you have any question regarding your pick up process."
              formControlName="note" maxlength="250"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>

    <app-upload-document
      [subscription_type]="subscription_type"
      [payment_type]="payment_type"
      [pick_up_method]="pick_up_method"
    ></app-upload-document>
    <button mat-button class="submit__button submitted" (click)="submitOffice()"
      >NEXT STEP</button>
  </div>
</div>
<ng-template #shinjuku>
  <div class="form__name office--name">
    <p class="office--name__title">SHINJUKU OFFICE</p>
    <hr>
    <p class="office--name__address">Office hours:
      <span class="time">Mon-Fri 10:00 – 17:00　Sat, Sun, and National Holidays: Closed</span><br />
      <span class="after_submission">Same-Day Pickup Available</span><br/>
      <span class="message">If you would like to visit our Shinjuku Office outside business hours, please write in the
        [NOTE] portion of the application the date and time you wish to visit our office. We will process your order and
        contact you to confirm the date you can come to the office.</span>
    </p>
    <!-- <button mat-flat-button class="office--name__button">DIRECTIONS</button> -->
    <button mat-flat-button class="office--name__button">
      <a class="link"
        href="https://www.google.com/maps/place/Sakura+Mobile+(Og+inc.)/@35.693334,139.69799,17z/data=!4m6!3m5!1s0x6018f32d2c7f9779:0x825925c94a88f095!8m2!3d35.6933339!4d139.6979901!16s%2Fg%2F11bwlqvv33?hl=en"
        target="_blank">
        GOOGLE MAP
      </a>
    </button>
  </div>
</ng-template>
<ng-template #yokosuka>
  <div class="form__name office--name">
    <p class="office--name__title">YOKOSUKA PICKUP COUNTER</p>
    <hr>
    <p class="office--name__address">Office hours: <span class="time">Mon,Tue,Thu,Fri 10:00 – 12:00 or 16:00 – 18:00/
        Sat 10:00 – 12:00 / Wed, Sun, National Holiday(Japan) Close</span></p>
        <span class="pickup_note">Your order will be available to pick up <span class="after_submission" >3 Days After the Order Submission.</span><br/>We will inform the pick-up date after we confirm your order and uploaded documents.</span><br/>
    <!-- <button mat-flat-button class="office--name__button">DIRECTIONS</button> -->
    <button mat-flat-button class="office--name__button">
      <a class="link" href="https://goo.gl/maps/NHJRNtb6pBQni4Bx5" target="_blank">
        GOOGLE MAP
      </a>
    </button>
  </div>
</ng-template>
<ng-template #yokota>
  <div class="form__name office--name">
    <p class="office--name__title">YOKOTA PICKUP COUNTER</p>
    <hr>
    <p class="office--name__address">Office hours: <span class="time">Open 7 days a week 9:00 – 21:00</span></p>
    <span class="pickup_note after_submission">Same-Day Pickup Available</span><br/>
    <!-- <button mat-flat-button class="office--name__button">DIRECTIONS</button> -->
    <button mat-flat-button class="office--name__button">
      <a class="link" href="https://goo.gl/maps/xi7sYx6aUBz" target="_blank">
        GOOGLE MAP
      </a>
    </button>
  </div>
</ng-template>
<!-- <ng-template #zama>
  <div class="form__name office--name">
    <p class="office--name__title">ZAMA / ATSUGI PICKUP COUNTER</p>
    <hr>
    <p class="office--name__address">Office hours: <span class="time">Weekday Only 10:00 – 18:00</span></p>
    <button mat-flat-button class="office--name__button">
      <a class="link"
        href="https://www.sakuramobile.jp/wp-content/themes/FoundationPress/dist/assets/images/How_to_get_to_VillageInc.pdf"
        target="_blank">
        DIRECTIONS
      </a>
    </button>
    <button mat-flat-button class="office--name__button">
      <a class="link" href="https://goo.gl/maps/MaF6KhbHXkM2" target="_blank">
        GOOGLE MAP
      </a>
    </button>
  </div>
</ng-template> -->
<ng-template #iwakuni>
  <div class="form__name office--name">
    <p class="office--name__title">IWAKUNI PICKUP COUNTER</p>
    <hr>
    <p class="office--name__address">Office hours: <span class="time">Mon,Wed,Thu,Fri 10:00 – 17:30 / Sat 10:00 – 16:00
        / Sun,Tue,National Holiday(Japan) Close</span></p>
        <span class="pickup_note">Your order will be available to pick up <span class="after_submission" >3-5 Days After the Order Submission.</span>.<br/>We will inform the pick-up date after we confirm your order and uploaded documents.</span><br/>
    <!-- <button mat-flat-button class="office--name__button">DIRECTIONS</button> -->
    <button mat-flat-button class="office--name__button">
      <a class="link"
        href="https://www.google.com/maps/place/〒740-0026+山口県岩国市車町３丁目６%E2%88%92９/@34.1526269,132.2209056,19z/data=!4m8!1m2!2m1!1zNzQwMS0wMDI2IOWxseWPo-ecjOWyqeWbveW4gui7iueUujMtNi05!3m4!1s0x354535eb42212f61:0x98bcdf126f14d2ad!8m2!3d34.1524216!4d132.2204814"
        target="_blank">
        GOOGLE MAP
      </a>
    </button>
  </div>
</ng-template>
<ng-template #sasebo>
  <div class="form__name office--name">
    <p class="office--name__title">SASEBO PICKUP COUNTER</p>
    <hr>
    <p class="office--name__address">Office hours: <span class="time">Weekdays 9:00 – 17:00 / Sat, Sun, American
        Holidays Close</span></p>
        <span class="pickup_note">Your order will be available to pick up <span class="after_submission" >3-5 Days After the Order Submission.</span>.<br/>We will inform the pick-up date after we confirm your order and uploaded documents.</span><br/>
    <!-- <button mat-flat-button class="office--name__button">DIRECTIONS</button> -->
    <button mat-flat-button class="office--name__button">
      <a class="link" href="https://goo.gl/maps/ckDKbjDUPCEHahJy8" target="_blank">
        GOOGLE MAP
      </a>
    </button>
  </div>
</ng-template>
<ng-template #okinawa>
  <div class="form__name office--name">
    <p class="office--name__title">OKINAWA OFFICE</p>
    <hr>
    <p class="office--name__address">Office hours: <span class="time">Open 7 days a week 10:00 – 18:00</span></p>
    <!-- <button mat-flat-button class="office--name__button">DIRECTIONS</button> -->
    <button mat-flat-button class="office--name__button">
      <a class="link" href="https://goo.gl/maps/xzayjxmosrLn9Njh7" target="_blank">
        GOOGLE MAP
      </a>
    </button>
  </div>
</ng-template>

<ng-template #shibuya>
  <div class="form__name office--name">
    <p class="office--name__title">WANDER COMPASS SHIBUYA</p>
    <hr>
    <p class="office--name__address">Office hours:
      <span class="time">Office hours: Mon – Sun 10:00-19:00</span><br />
    </p>
    <span class="pickup_note">Your order will be available to pick up <span class="after_submission" >3 Days After the Order Submission.</span>.<br/>We will inform the pick-up date after we confirm your order and uploaded documents.</span><br/>
    <button mat-flat-button class="office--name__button">
      <a class="link" href="https://goo.gl/maps/U2V4RhoMzex" target="_blank">
        GOOGLE MAP
      </a>
    </button>
  </div>
</ng-template>

<ng-template #afstokyo>
  <div class="form__name office--name">
    <p class="office--name__title">AFS Tokyo Office</p>
    <hr>
    <p class="office--name__address">Office hours:
      <span class="message">New incoming AFS students will be able to receive their orders at the arriving orientation
        from AFS Japan staff.</span>
    </p>
  </div>
</ng-template>

<ng-template #mansfield>
  <div class="form__name office--name">
    <p class="office--name__title">Mansfield Foundation Tokyo Office</p>
    <hr>
    <p class="office--name__address">Office hours:
      <span class="message">New incoming fellows from Mansfield Program will be able to receive their orders from Mansfield Foundation Tokyo Office staff.</span>
    </p>
  </div>
</ng-template>
