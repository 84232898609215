import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BodyComponent } from "./components/basic-information/body.component";
import { CompleteFirstStepComponent } from "./components/basic-information/complete-first-step/complete-first-step.component";
import { CardBlockedComponent } from "./components/confirmation-and-payment/card-blocked/card-blocked.component";
import { ConfirmationAndPaymentComponent } from "./components/confirmation-and-payment/confirmation-and-payment.component";
import { FinishComponent } from "./components/confirmation-and-payment/finish/finish.component";
import { PaymentMethodComponent } from "./components/payment-method-document/payment-method/payment-method.component";
import { TermsConditionsComponent } from "./components/terms-and-conditions/terms-conditions/terms-conditions.component";

const routes: Routes = [
  { path: "step1", component: BodyComponent },
  { path: "complete-step", component: CompleteFirstStepComponent },
  { path: "step2", component: PaymentMethodComponent },
  { path: "step3", component: TermsConditionsComponent },
  { path: "step4", component: ConfirmationAndPaymentComponent },
  { path: "completed", component: FinishComponent },
  { path: "card-blocked", component: CardBlockedComponent },
  {
    path: "top-up-ticket",
    loadChildren: () =>
      import("./components/top-up-campaign/top-up-campaign.module").then(
        (m) => m.TopUpCampaignModule
      ),
  },
  { path: "", redirectTo: "step1", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
