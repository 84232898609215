
import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { countryListAllIsoData } from 'src/app/country-list';
import { Add_Remove_Css, set_Background_Snackbar_NewCard, ShowSnackBar } from 'src/app/Utils/utils';
import { CustomValicators } from 'src/app/validators/custom.validators';

@Component({
  selector: 'app-register-new-card',
  templateUrl: './register-new-card.component.html',
  styleUrls: ['./register-new-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterNewCardComponent implements OnInit {
  newCardInformation: FormGroup;
  card_holder_name: FormControl;
  card_number: FormControl;
  // expire_month: FormControl;
  // expire_year: FormControl;
  card_cvv: FormControl;
  payment_method: FormControl;
  country: FormControl;
  expire_date: FormControl;
  maskedCVV: string = '';
  countryListAllIsoData: {
    code: string;
    code3: string;
    name: string;
    number: string;
  }[];
  cardNumber = '';
  cardName = '';
  // expireMonth = '';
  // expireYear = '';
  @ViewChild('cardHolderNameRef', { static: false, read: ElementRef }) cardHolderNameRef: ElementRef;
  @ViewChild('cardNumberRef', { static: false, read: ElementRef }) cardNumberRef: ElementRef;
  // @ViewChild('expireMonthRef', {static: false, read: ElementRef}) expireMonthRef: ElementRef;
  // @ViewChild('expireYearRef', {static: false, read: ElementRef}) expireYearRef: ElementRef;
  @ViewChild('expireCombined', { static: false, read: ElementRef }) expireCombinedRef: ElementRef
  @ViewChild('cvvRef', { static: false, read: ElementRef }) cvvRef: ElementRef;
  @ViewChild('cardTypeRef', { static: false, read: ElementRef }) cardTypeRef: ElementRef;
  @ViewChild('issuingCountryRef', { static: false, read: ElementRef }) issuingCountryRef: ElementRef;
  @ViewChild('cvvInput', { static: false }) cvvInput!: ElementRef;

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.createFormControl();
    this.createNewCardForm();
    this.countryListAllIsoData = countryListAllIsoData;
    this.newCardInformation = this.fb.group({
      expire_combined: [''],
      card_number: ['', [Validators.required, Validators.pattern(/^[0-9 ]+$/), Validators.minLength(15)]],
      card_holder_name: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]+$/)]],
      card_cvv: ['', [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.minLength(3)]]
    });
    this.card_holder_name = this.newCardInformation.get('card_holder_name') as FormControl;
    this.card_cvv = this.newCardInformation.get('card_cvv') as FormControl;
  }

  get expire_combined() {
    return this.newCardInformation.get('expire_combined');
  }

  cardHolderNameChange(event: any) {
    set_Background_Snackbar_NewCard('remove', this.cardHolderNameRef, this.renderer);
  }
  makeCapital(event: any) {
    this.cardName = event.target.value.toUpperCase();
  }
  cardNumberChange(event: any) {
    set_Background_Snackbar_NewCard('remove', this.cardNumberRef, this.renderer);
    this.cardNumber = event.target.value.replace(/\s+/g, '');
    this.card_number.patchValue(this.cardNumber);
  }

  // expireMonthChange(event: any) {
  //   set_Background_Snackbar_NewCard('remove', this.expireMonthRef, this.renderer);
  // }
  // expireYearChange(event: any) {
  //   set_Background_Snackbar_NewCard('remove', this.expireYearRef, this.renderer);
  // }

  expireCombinedChange(event: any) {
    set_Background_Snackbar_NewCard('remove', this.expireCombinedRef, this.renderer);
  }

  cvvChange(event: any) {
    set_Background_Snackbar_NewCard('remove', this.cvvRef, this.renderer);
    this.showPassword();
  }
  // cardTyepChange(event: any) {
  //   this.renderer.removeClass(this.cardTypeRef.nativeElement, 'card-type-invalid');
  // }
  // issuingCountrySelectChange(event: any) {
  //   set_Background_Snackbar_NewCard('remove', this.issuingCountryRef, this.renderer);
  // }

  showPassword() {
    if (this.cvvInput) {
      this.cvvInput.nativeElement.type = 'text';
      // this.cvvInput.nativeElement.value = this.cvvInput.nativeElement.value;
    }
  }
  hidePassword() {
    if (this.cvvInput) {
      this.cvvInput.nativeElement.type = 'password';
    }
  }

  // onBlurExpireMonth(event: any) {
  //   if (event.target.value.length === 1) {
  //     this.expireMonth = `0${event.target.value}`;
  //     this.expire_month.patchValue(this.expireMonth);
  //   }
  //   if (!this.newCardInformation.hasError('cardExpired')) {
  //     set_Background_Snackbar_NewCard('remove', this.expireYearRef, this.renderer);
  //   }
  // }
  // onBlurExpireYear(event: any) {
  //   if (event.target.value.length === 1) {
  //     this.expireYear = `0${event.target.value}`;
  //     this.expire_year.patchValue(this.expireYear);
  //   }
  //   if (!this.newCardInformation.hasError('cardExpired')) {
  //     set_Background_Snackbar_NewCard('remove', this.expireMonthRef, this.renderer);
  //   }
  // }

  onBlurExpireMonth(event: any) {
    const value = event.target.value.replace(/\D/g, '');
    let formattedMonth = '';
    let formattedYear = '';

    if (value.length >= 2) {
      formattedMonth = value.slice(0, 2);
    }
    if (value.length >= 4) {
      formattedYear = value.slice(2, 4);
    }
    const formattedValue = `${formattedMonth}/${formattedYear}`;
    const expireCombinedControl = this.newCardInformation.get('expire_combined');
    console.log('MM/YY>>>>>>>>>>', expireCombinedControl)
    if (expireCombinedControl) {
      expireCombinedControl.patchValue(formattedValue, { emitEvent: false });
    }
    if (formattedValue === '/' || formattedValue.length < 5) {
      expireCombinedControl.setErrors({ required: true });
    } else {
      expireCombinedControl.updateValueAndValidity();
    }
  }

  showErrorFormField() {
    if (this.card_holder_name.invalid) {
      this.setbackGround_SnackBar(this.card_holder_name, this.cardHolderNameRef);
    }
    if (this.card_number.invalid) {
      this.setbackGround_SnackBar(this.card_number, this.cardNumberRef);
    }

    const expireCombinedControl = this.newCardInformation.get('expire_combined') as FormControl;
    if (expireCombinedControl.invalid) {
      this.setbackGround_SnackBar(expireCombinedControl, this.expireCombinedRef);
    }

    // if (this.expire_month.invalid) {
    //   this.setbackGround_SnackBar(this.expire_month, this.expireMonthRef);
    // }
    // if (this.expire_year.invalid) {
    //   this.setbackGround_SnackBar(this.expire_year, this.expireYearRef);
    // }
    if (this.card_cvv.invalid) {
      this.setbackGround_SnackBar(this.card_cvv, this.cvvRef);
    }
    // if (this.payment_method.invalid) {
    //   this.setbackGround_SnackBar(this.payment_method, this.cardTypeRef);
    //   this.renderer.addClass(this.cardTypeRef.nativeElement, 'card-type-invalid');
    // }
    // if (this.country.invalid) {
    //   this.setbackGround_SnackBar(this.country, this.issuingCountryRef);
    // }
  }
  private setbackGround_SnackBar(control: FormControl, el: ElementRef) {
    control.markAllAsTouched();
    set_Background_Snackbar_NewCard('add', el, this.renderer);
    ShowSnackBar(this.snackbar);
  }
  createNewCardForm() {
    this.newCardInformation = this.fb.group({
      card_holder_name: this.card_holder_name,
      card_number: this.card_number,
      // expire_month: this.expire_month,
      // expire_year: this.expire_year,
      card_cvv: this.card_cvv,
      // payment_method: this.payment_method,
      // country: this.country,
    }, {
      // validators: CustomValicators.checkCardExpired()
    });
  }
  createFormControl() {
    this.card_holder_name = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(/^[A-Za-z0-9 ]+$/),
      ])
    );
    this.card_number = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(/^[0-9 ]+$/),
        Validators.minLength(15)
      ])
    );
    // this.expire_month = this.fb.control(
    //   '',
    //   Validators.compose([
    //     Validators.required,
    //     Validators.pattern(/^[0-9 ]+$/),
    //     Validators.minLength(2),
    //   ])
    // );
    // this.expire_year = this.fb.control(
    //   '',
    //   Validators.compose([
    //     Validators.required,
    //     Validators.pattern(/^[0-9 ]+$/),
    //     Validators.minLength(2),
    //   ])
    // );
    this.card_cvv = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(/^[0-9]+$/),
        Validators.minLength(3)
      ])
    );
    // this.payment_method = this.fb.control('', Validators.required);
    // this.country = this.fb.control('', Validators.required);
  }

}
