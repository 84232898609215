import { Component, OnInit, Output, ViewEncapsulation,
  EventEmitter, AfterContentChecked, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Add_Remove_Css } from 'src/app/Utils/utils';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OtherComponent implements OnInit, AfterContentChecked {
  note: FormControl;
  preffered_date_of_pick_up: FormControl;
  estimated_time_of_arrival: FormControl;
  shipping_address: FormControl;
  recipient: FormControl;
  otherFG: FormGroup;
  @Input() minDate: Date;
  @Output() otherFormValid = new EventEmitter<boolean>();

  @ViewChild('otherAddressRef', {static: false, read: ElementRef}) otherAddressRef: ElementRef;
  @ViewChild('otherPreferrDateRef', {static: false, read: ElementRef}) otherPreferrDateRef: ElementRef;
  @ViewChild('otherRecipientRef', {static: false, read: ElementRef}) otherRecipientRef: ElementRef;
  estimateTimeArrival = ['~ 6:00', '6:00 ~ 7:00', '7:00 ~ 8:00', '8:00 ~ 9:00', '9:00 ~ 10:00', '10:00 ~ 11:00', '11:00 ~ 12:00',
    '12:00 ~ 13:00', '13:00 ~ 14:00', '14:00 ~ 15:00', '15:00 ~ 16:00', '16:00 ~ 17:00', '17:00 ~ 18:00', '18:00 ~ 19:00',
    '19:00 ~ 20:00', '20:00 ~ 21:00', '21:00 ~ 22:00', '22:00 ~ 23:00', '23:00 ~ 0:00'];
  constructor(private fb: FormBuilder, private renderer: Renderer2) { }

  ngOnInit() {
    this.createFormControl();
    this.otherFormGroup();
  }
  otherAddressChange(event: any) {
    Add_Remove_Css('remove', this.otherAddressRef, this.renderer);
  }
  otherPreferrDateClose(event: any) {
    Add_Remove_Css('remove', this.otherPreferrDateRef, this.renderer);
  }
  otherRecipientChange(event: any) {
    Add_Remove_Css('remove', this.otherRecipientRef, this.renderer);
  }
  ngAfterContentChecked() {
    if (this.otherFG.valid) {
      this.otherFormValid.emit(true);
    } else {
      this.otherFormValid.emit(false);
    }
  }
  private createFormControl() {
    this.shipping_address = this.fb.control('', Validators.required);
    this.note = this.fb.control('');
    this.preffered_date_of_pick_up = this.fb.control('', Validators.required);
    this.estimated_time_of_arrival = this.fb.control('');
    this.recipient = this.fb.control('', Validators.required);
  }
  private otherFormGroup() {
    this.otherFG = this.fb.group({
      shipping_address: this.shipping_address,
      note: this.note,
      preffered_date_of_pick_up: this.preffered_date_of_pick_up,
      estimated_time_of_arrival: this.estimated_time_of_arrival,
      recipient: this.recipient
    });
  }
}
