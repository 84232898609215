import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLinear = false;
  step1 = false;
  step2 = false;
  step3 = false;
  step4 = false;
  isMobile: boolean;
  @Input() step: number;
  constructor(
    public breakpoint: BreakpointObserver
  ) { }

  ngOnInit() {
    this.breakpoint.observe(['(max-width: 600px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        console.log('Layout matched min-width 480px');
        this.isMobile = true;
      } else {
        console.log('Not matched:::');
        this.isMobile = false;
      }
    });
    this.setStep(this.step);
  }
  setStep(step: number) {
    if (step === 1) {
     return this.step1 = true;
    } else if (step === 2) {
      return this.step2 = true;
    } else if (step === 3) {
      return this.step3 = true;
    } else if (step === 4) {
      return this.step4 = true;
    }
    }
}
